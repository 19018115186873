import React, { useState } from 'react';
import { PageLayout } from './deprecated/PageLayout';
import {
  useACHCompanyIDLookupGet,
  useACHTransferLookupGet,
  useCheckTransferLookupGet,
  useInitialStateGet,
} from 'src/hooks/reactQuery';
import { TextInput } from './deprecated/TextInput';
import { PropertyList } from 'shared/components/PropertyList';
import { humanize } from 'shared/lib/formatting';

const CheckTransferSearchResults = (props: { checkNumber: string }) => {
  const { data: checkTransfer } = useCheckTransferLookupGet({
    check_number: props.checkNumber,
  });
  if (!checkTransfer) {
    return <>No check transfer found with check number {props.checkNumber}</>;
  }
  const items = Object.entries(checkTransfer).map(([key, value]) => ({
    label: humanize(key),
    value: value ? value.toString() : '',
  }));

  return <PropertyList title="Check Transfer Details" items={items} />;
};

const SearchByCheckNumber = () => {
  const [value, setValue] = useState('');
  const [checkNumber, setCheckNumber] = useState('');

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setCheckNumber(value);
      }}
    >
      <div>
        <label className="text-sm text-subtle">Check number</label>
        <TextInput
          placeholder="123456789"
          value={value}
          onChange={(s) => setValue(s)}
          className="mb-3"
        />
      </div>
      {checkNumber && (
        <>
          <CheckTransferSearchResults
            data-testid="checkNumberResults"
            checkNumber={checkNumber}
          />
        </>
      )}
    </form>
  );
};

const ACHCompanyIDSearchResults = (props: { achCompanyID: string }) => {
  const { data: group } = useACHCompanyIDLookupGet({
    ach_company_id: props.achCompanyID,
  });
  const { data } = useInitialStateGet({});

  if (!group) {
    if (props.achCompanyID === data?.constants.default_ach_company_id) {
      return (
        <>
          The ACH company ID 9806373283 is used by multiple Groups. Please look
          up a specific transfer.
        </>
      );
    }
    return <>No Group found with ACH company ID: {props.achCompanyID}</>;
  }
  const items = Object.entries(group).map(([key, value]) => ({
    label: humanize(key),
    value: value ? value.toString() : '',
  }));

  return <PropertyList title="Company ID Details" items={items} />;
};

const SearchByACHCompanyID = () => {
  const [value, setValue] = useState('');
  const [achCompanyID, setACHCompanyID] = useState('');

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setACHCompanyID(value);
      }}
    >
      <div>
        <label className="text-sm text-subtle">ACH Company ID</label>
        <TextInput
          placeholder="9686938000"
          value={value}
          onChange={(s) => setValue(s)}
          className="mb-3"
        />
      </div>
      {achCompanyID && (
        <>
          <ACHCompanyIDSearchResults
            data-testid="achCompanyIDResults"
            achCompanyID={achCompanyID}
          />
        </>
      )}
    </form>
  );
};

const ACHTransferSearchResults = (props: {
  traceNumber: string;
  amount: string;
}) => {
  const { data: achTransfer } = useACHTransferLookupGet({
    trace_number: props.traceNumber,
    amount: parseInt(props.amount),
  });

  if (!achTransfer) {
    return <>No ACH transfer found.</>;
  }
  const items = Object.entries(achTransfer).map(([key, value]) => ({
    label: humanize(key),
    value: value ? value.toString() : '',
  }));

  return <PropertyList title="ACH Transfer Details" items={items} />;
};

const SearchByACHTransfer = () => {
  const [traceNumber, setTraceNumber] = useState('');
  const [amount, setAmount] = useState('');

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div>
        <label className="text-sm text-subtle">Trace number</label>
        <TextInput
          placeholder="123456789"
          value={traceNumber}
          onChange={(s) => setTraceNumber(s)}
          className="mb-3"
        />
        <label className="text-sm text-subtle">Amount in cents</label>
        <TextInput
          placeholder="10034"
          type="number"
          value={amount.toString()}
          onChange={(s) => setAmount(s)}
          className="mb-3"
        />
      </div>
      {traceNumber && amount && (
        <>
          <ACHTransferSearchResults
            data-testid="achTransferResults"
            traceNumber={traceNumber}
            amount={amount}
          />
        </>
      )}
    </form>
  );
};

export const TransferLookup = () => {
  return (
    <PageLayout headline="Transfer Lookup">
      <div className="max-w-xl space-y-8">
        <div className="text-lg font-medium">Check Transfers</div>
        <SearchByCheckNumber />
      </div>
      <div className="max-w-xl space-y-8">
        <div className="text-lg font-medium">ACH Company ID</div>
        <SearchByACHCompanyID />
      </div>
      <div className="max-w-xl space-y-8">
        <div className="text-lg font-medium">ACH Transfers</div>
        <SearchByACHTransfer />
      </div>
    </PageLayout>
  );
};
