import React from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useConsolidatedScreeningListEntryGet } from 'src/hooks/reactQuery';
import { Loading } from 'shared/components/Loading';
import { PropertyList } from 'shared/components/PropertyList';

import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';

export const ConsolidatedScreeningListEntryPage = () => {
  const { entryId } = useTypedParams(ROUTES.SANCTIONS_SCREENING_ENTRY_DETAILS);
  const { data } = useConsolidatedScreeningListEntryGet(entryId);

  if (!data) {
    return <Loading />;
  }

  const alternativeNames = data.alternative_names.map((value) => ({
    label: 'Alternative name',
    value,
  }));

  return (
    <PageLayout headline={data.name}>
      <PropertyList
        title="Details"
        items={[{ label: 'Name', value: data.name }]
          .concat(alternativeNames)
          .concat([
            { label: 'Type', value: data.type || 'unknown' },
            { label: 'ID', value: data.external_id },
            { label: 'Source', value: data.source },
          ])}
      />
    </PageLayout>
  );
};
