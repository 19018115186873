import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useResultStatusList, useResultSummaryGet } from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { makeEnumFilter, makeStatusFilter } from 'src/lib/tableFilterHelpers';
import { TableFilter } from 'shared/components/Table/filters';
import { ResultsTable } from '../tables/ResultsTable';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { Caption } from 'shared/components/Text';
import { Loading } from 'shared/components/Loading';
import { formatInteger } from 'shared/lib/formatting';

const resultStatuses = {
  succeeding: 'Succeeding',
  failing: 'Failing',
  retired: 'Retired',
} as const;

const suppressionStatuses = {
  true: 'true',
  false: 'false',
} as const;

const Summary = () => {
  const { data } = useResultSummaryGet({});

  if (!data) {
    return <Loading />;
  }

  return (
    <div>
      <Caption>Succeeding: {formatInteger(data.succeeding)}</Caption>
      <Caption>Failing: {formatInteger(data.failing)}</Caption>
      <Caption>Suppressed: {formatInteger(data.suppressed)}</Caption>
    </div>
  );
};

export const ResultListPage = () => {
  const [statuses, setStatuses] = useSearchParamsState(
    ROUTES.RESULTS_V2,
    'statuses'
  );

  const [suppressionStatus, setSuppressionStatus] = useSearchParamsState(
    ROUTES.RESULTS_V2,
    'suppression_status'
  );

  const filters: TableFilter[] = useMemo(
    () => [
      makeStatusFilter(statuses, setStatuses, resultStatuses),
      makeEnumFilter(
        suppressionStatus,
        setSuppressionStatus,
        'Suppressed',
        suppressionStatuses,
        'calendar'
      ),
    ],
    [setStatuses, statuses, suppressionStatus, setSuppressionStatus]
  );

  const listResult = useResultStatusList({
    statuses: statuses.length > 0 ? statuses : undefined,
    suppressed:
      suppressionStatus.length > 0
        ? suppressionStatus[0] === 'true'
        : undefined,
  });

  return (
    <PageLayout headline={'Results'} action={<Summary />}>
      <TableStateWrapper
        table={ResultsTable}
        style="primary"
        {...listResult}
        emptyTitle="No Results"
        emptySubtitle="There are no results to display"
        filters={filters}
      />
    </PageLayout>
  );
};
