import { compact } from 'lodash';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import { FedwireInboundRequestForReversalGetResponse } from 'src/build/operations';
import { splitOnFedwireTags } from 'src/lib/fedwire-utility';

export type FedwireRequestForReversalPropertyListProps = {
  fedwireRequestForReversal: FedwireInboundRequestForReversalGetResponse;
} & Omit<PropertyListProps, 'items'>;

export const FedwireRequestForReversalPropertyList = (
  props: FedwireRequestForReversalPropertyListProps
) => {
  const { fedwireRequestForReversal, ...rest } = props;

  return (
    <PropertyList
      {...rest}
      layoutHorizontallyWhenPossible={false}
      items={compact<ListItem>([
        {
          label: 'ID',
          value: fedwireRequestForReversal.id,
          copyable: fedwireRequestForReversal.id,
        },
        {
          label: 'Body',
          valueNode: (
            <div className="flex bg-strong p-2">
              <pre className="grow overflow-scroll font-mono text-sm">
                {splitOnFedwireTags(fedwireRequestForReversal.message_body)}
              </pre>
            </div>
          ),
        },
        {
          label: 'Status',
          value: fedwireRequestForReversal.allocation_status,
        },
        {
          label: 'Received On',
          value: formatISO8601Timestamp(
            fedwireRequestForReversal.received_on,
            'medium'
          ),
        },
      ])}
    />
  );
};
