import { useTransactionAccountingEntriesForTraceList } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { buildPath, ROUTES } from 'src/lib/routes';
import { InternalOperatorOnly } from '../internal-operator-only';
import { TransactionAccountingEntryForTraceListResponseDataItem } from 'src/build/operations';
import _ from 'lodash';
import { Body, Caption, Heading } from 'shared/components/Text';
import { Box } from 'shared/components/Box';
import {
  DesktopColumn,
  MobileColumn,
  Table,
  TableColumns,
} from 'shared/components/Table';
import { StyledLink } from 'shared/components/StyledLink';
import { Icon } from 'shared/components/Icon';
import { formatAmount } from 'shared/lib/formatting';

const accountSortBy = (account: string): string => {
  if (account === 'user_transactions') {
    return 'a';
  } else if (account.includes('pending')) {
    return `b-${account}`;
  } else {
    return `c-${account}`;
  }
};

const accountsFor = (
  entries: TransactionAccountingEntryForTraceListResponseDataItem[]
) => {
  return _.sortBy(
    _.uniq(
      _.flatMap(entries, (entry) => [entry.credit_account, entry.debit_account])
    ),
    accountSortBy
  );
};

const formatSignedAmount = (amount: number): string => {
  if (amount < 0) {
    return `(${formatAmount(amount * -1, 'USD')})`;
  } else {
    return formatAmount(amount, 'USD');
  }
};

type Row = {
  id: string;
  source_id: string;
  source_type: string;
  date: string;
  bank: string;
  transaction_source_type: string | null;

  cells: string[];
};

const rowsFor = (
  entries: TransactionAccountingEntryForTraceListResponseDataItem[],
  allAccounts: string[]
): Row[] => {
  return entries.map((entry) => {
    const cells = allAccounts.map((account) => {
      if (entry.credit_account === entry.debit_account) {
        return formatSignedAmount(0);
      } else if (entry.credit_account === account) {
        return formatSignedAmount(entry.amount * -1);
      } else if (entry.debit_account === account) {
        return formatSignedAmount(entry.amount);
      } else {
        return '';
      }
    });

    return {
      id: entry.id,
      source_id: entry.source_id,
      source_type: entry.source_type,
      date: entry.date,
      bank: entry.bank,
      transaction_source_type: entry.transaction_source_type,
      cells,
    };
  });
};

const totalsRow = (
  entries: TransactionAccountingEntryForTraceListResponseDataItem[],
  allAccounts: string[]
): Row => {
  return {
    id: 'totals',
    source_id: '',
    source_type: '',
    date: '',
    bank: '',
    transaction_source_type: null,
    cells: allAccounts.map((account) =>
      formatSignedAmount(
        _.sum(
          entries.map((entry) => {
            if (entry.credit_account === entry.debit_account) {
              return 0;
            } else if (entry.credit_account === account) {
              return entry.amount * -1;
            } else if (entry.debit_account === account) {
              return entry.amount;
            } else {
              return 0;
            }
          })
        )
      )
    ),
  };
};

const SummaryCell = ({ datum: row }: { datum: Row }) => {
  if (row.id === 'totals') {
    return null;
  }

  return (
    <Box gap="2">
      <Box gap="0">
        <Body color="emphasis" weight="medium">
          {row.source_type}{' '}
          {row.transaction_source_type && <>({row.transaction_source_type})</>}
        </Body>
        <Body>
          <StyledLink
            style="underline"
            href={buildPath(ROUTES.OBJECT_VIEWER, { objectID: row.source_id })}
          >
            {row.source_id}
          </StyledLink>
        </Body>
      </Box>

      <Caption>
        <StyledLink
          style="underline"
          href={buildPath(ROUTES.OBJECT_VIEWER, { objectID: row.id })}
        >
          {row.id}
        </StyledLink>{' '}
        — {row.bank}
      </Caption>
    </Box>
  );
};

const renderHeaderForRow = (row: Row) => {
  if (row.id === 'totals') {
    return (
      <div className="flex h-10 items-center space-x-1 bg-info-subtle pl-2">
        <Icon name="plus_circle" className="-ml-1 text-strong" />
        <Body color="emphasis" weight="medium">
          Totals
        </Body>
      </div>
    );
  }
};

export const TransactionAccountingEntriesForTrace = () => {
  const { traceID } = useTypedParams(
    ROUTES.TRANSACTION_ACCOUNTING_ENTRIES_FOR_TRACE
  );

  const { data: response } = useTransactionAccountingEntriesForTraceList({
    trace_id: traceID,
  });

  if (!response) {
    return <h3>Loading</h3>;
  }

  const accounts = accountsFor(response.data);
  const rows = rowsFor(response.data, accounts);
  rows.push(totalsRow(response.data, accounts));

  const columns: TableColumns<Row> = [
    {
      header: 'Date',
      contents: (e) => ({
        text: e.date,
        textWeight: 'bold',
        textColor: 'emphasis',
      }),
    },
    {
      header: 'Event',
      CellComponent: SummaryCell,
    },
    ...accounts.map((account, i): DesktopColumn<Row> & MobileColumn<Row> => {
      return {
        header: account,
        contents: (e) => ({
          text: e.cells[i],
        }),
      };
    }),
    false,
  ];

  return (
    <InternalOperatorOnly>
      <PageLayout headline={'Transaction Accounting Entries'}>
        <Box>
          <Heading>
            Trace ID:{' '}
            <StyledLink
              style="underline"
              href={buildPath(ROUTES.OBJECT_VIEWER, { objectID: traceID })}
            >
              {traceID}
            </StyledLink>
          </Heading>

          <Table
            columns={columns}
            data={rows}
            renderHeaderForRow={renderHeaderForRow}
          />
        </Box>
      </PageLayout>
    </InternalOperatorOnly>
  );
};
