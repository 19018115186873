import { compact } from 'lodash';
import { BadgeColor } from 'shared/components/Badge';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import {
  formatISO8601Timestamp,
  formatInteger,
  humanize,
} from 'shared/lib/formatting';
import {
  ControlConfigurationGetResponse,
  ControlConfigurationGetResponseResult,
} from 'src/build/operations';
import {
  useGroupPropertyRow,
  useProgramPropertyRow,
} from 'src/lib/propertyListHelpers';

export type ControlConfigurationPropertyListProps = {
  controlConfiguration: ControlConfigurationGetResponse;
} & Omit<PropertyListProps, 'items'>;

const resultBadgeColor: Record<
  ControlConfigurationGetResponseResult,
  BadgeColor
> = {
  success: 'green',
  failure: 'red',
};

export const ControlConfigurationPropertyList = (
  props: ControlConfigurationPropertyListProps
) => {
  const { controlConfiguration, ...rest } = props;
  const groupRow = useGroupPropertyRow(controlConfiguration.group_id);
  const programRow = useProgramPropertyRow(controlConfiguration.program_id);

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'ID',
          value: controlConfiguration.id,
          copyable: controlConfiguration.id,
        },
        groupRow,
        programRow,
        {
          label: 'Visibility',
          value: controlConfiguration.user_visible
            ? 'Visible to user'
            : 'Increase-only',
          badgeColor: controlConfiguration.user_visible ? 'gray' : 'yellow',
        },
        {
          label: 'Category',
          value: humanize(controlConfiguration.category),
        },
        {
          label: 'Latest result',
          value: humanize(controlConfiguration.result),
          badgeColor: resultBadgeColor[controlConfiguration.result],
        },
        {
          label: 'Number of successes',
          value: formatInteger(controlConfiguration.number_of_successes),
        },
        {
          label: 'Number of failures',
          value: formatInteger(controlConfiguration.number_of_failures),
        },
        {
          label: 'Created',
          value: formatISO8601Timestamp(
            controlConfiguration.created_at,
            'medium'
          ),
        },
        controlConfiguration.deleted_at && {
          label: 'Deleted at',
          badgeColor: 'yellow',
          value: formatISO8601Timestamp(
            controlConfiguration.deleted_at,
            'medium'
          ),
        },
      ])}
    />
  );
};
