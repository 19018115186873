import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useCardDisputeListInfinite } from 'src/hooks/reactQuery';
import { CardDisputesTable } from '../tables/CardDisputesTable';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { makeStatusFilter, makeCardFilter } from 'src/lib/tableFilterHelpers';
import { compact } from 'lodash';
import { TableFilter } from 'shared/components/Table/filters';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '../../../shared/hooks/useTypedSearchParamsState';

export const CardDisputeListPage = () => {
  const [statuses, setStatuses] = useSearchParamsState(
    ROUTES.CARD_DISPUTES_LIST,
    'statuses'
  );

  const [cardFilter, setCardFilter] = useSearchParamsState(
    ROUTES.CARD_DISPUTES_LIST,
    'card'
  );

  const listResult = useCardDisputeListInfinite({
    statuses: statuses.length > 0 ? statuses : undefined,
    card_ids: cardFilter && cardFilter.length > 0 ? cardFilter : undefined,
  });

  const filters: TableFilter[] = useMemo(
    () =>
      compact([
        makeStatusFilter(statuses, setStatuses, {
          accepted: 'Accepted',
          pending_reviewing: 'Pending Reviewing',
          rejected: 'Rejected',
          lost: 'Lost',
          won: 'Won',
        }),
        makeCardFilter(cardFilter, setCardFilter),
      ]),
    [statuses, setStatuses, cardFilter, setCardFilter]
  );

  return (
    <PageLayout headline={'Card disputes'}>
      <TableStateWrapper
        table={CardDisputesTable}
        style="primary"
        {...listResult}
        emptyTitle="No card disputes"
        emptySubtitle="There are no card disputes to display"
        filters={filters}
      />
    </PageLayout>
  );
};
