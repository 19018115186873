import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { usePlatformComplianceMetricsSubmissionListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import {
  ComplianceSubmissionTable,
  ComplianceSubmissionTableProps,
  Item,
} from '../tables/ComplianceSubmissionTable';
import { TableFilter } from 'shared/components/Table/filters';
import { makeProgramFilter } from 'src/lib/tableFilterHelpers';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';

export const PlatformComplianceMetricsSubmissionListPage = () => {
  const [programFilter, setProgramFilter] = useSearchParamsState(
    ROUTES.PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_LIST,
    'program'
  );

  const filters: TableFilter[] = useMemo(
    () => [makeProgramFilter(programFilter, setProgramFilter)],
    [programFilter, setProgramFilter]
  );

  const listResult = usePlatformComplianceMetricsSubmissionListInfinite({
    program_ids: programFilter.length > 0 ? programFilter : undefined,
  });

  return (
    <PageLayout headline={'Platform compliance metrics submissions'}>
      <TableStateWrapper<Item, ComplianceSubmissionTableProps>
        {...listResult}
        table={ComplianceSubmissionTable}
        style="primary"
        emptyTitle="No details"
        emptySubtitle="There are no details to display"
        filters={filters}
        hrefPrefix="platform_compliance_metrics_submissions/"
      />
    </PageLayout>
  );
};
