import { useFedwireInboundServiceMessagesGet } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { Box } from 'shared/components/Box';
import { Heading } from 'shared/components/Text';
import { OperatorNotes } from '../operator-notes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { FedwireServiceMessagePropertyList } from '../property-lists/FedwireServiceMessagePropertyList';

export const FedwireInboundServiceMessageDetailPage = () => {
  const { serviceMessageId } = useTypedParams(
    ROUTES.FEDWIRE_INBOUND_SERVICE_MESSAGES_DETAIL
  );

  const { data: serviceMessage } =
    useFedwireInboundServiceMessagesGet(serviceMessageId);

  if (!serviceMessage) {
    return <h3>Loading</h3>;
  }

  return (
    <PageLayout headline={'Fedwire Service Message'}>
      <FedwireServiceMessagePropertyList
        fedwireServiceMessage={serviceMessage}
      />
      <Box>
        <Heading>Operator Notes</Heading>
        <OperatorNotes modelId={serviceMessage.id} />
      </Box>
    </PageLayout>
  );
};
