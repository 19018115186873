import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useEntityReferenceListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { EntitiesTable } from '../tables/EntitiesTable';
import { compact } from 'lodash';
import { TableFilter } from 'shared/components/Table/filters';
import {
  makeEnumFilter,
  makeGroupFilter,
  makeKeywordFilter,
  makeStatusFilter,
  makeTimeRangeFilter,
} from 'src/lib/tableFilterHelpers';
import {
  EntityGetResponseReviewStatus,
  EntityGetResponseStatus,
  EntityGetResponseStructure,
} from 'src/build/operations';
import { DateTime } from 'luxon';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { useTypedCreatedAtRangeParams } from 'src/hooks/timeSearchParameters';

export const EntityListPage = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(
    ROUTES.ENTITIES_LIST,
    'group'
  );
  const [statusFilter, setStatusFilter] = useSearchParamsState(
    ROUTES.ENTITIES_LIST,
    'status'
  );
  const [structureFilter, setStructureFilter] = useSearchParamsState(
    ROUTES.ENTITIES_LIST,
    'structure'
  );
  const [reviewStatusFilter, setReviewStatusFilter] = useSearchParamsState(
    ROUTES.ENTITIES_LIST,
    'review_status'
  );
  const [keywordFilter, setKeywordFilter] = useSearchParamsState(
    ROUTES.ENTITIES_LIST,
    'keyword'
  );
  const [{ startAt, endAt }, setTimeRange] = useTypedCreatedAtRangeParams(
    ROUTES.ENTITIES_LIST
  );

  const startAtDateTime = startAt
    ? DateTime.fromISO(startAt, { zone: 'utc' })
    : undefined;
  const endAtDateTime = endAt
    ? DateTime.fromISO(endAt, { zone: 'utc' })
    : undefined;

  const filters: TableFilter[] = useMemo(
    () =>
      compact([
        makeGroupFilter(groupFilter, setGroupFilter),
        makeStatusFilter(
          statusFilter,
          setStatusFilter,
          EntityGetResponseStatus
        ),
        makeEnumFilter(
          reviewStatusFilter,
          setReviewStatusFilter,
          'Review Status',
          EntityGetResponseReviewStatus,
          'alert'
        ),
        makeKeywordFilter(keywordFilter, setKeywordFilter),
        makeTimeRangeFilter({ startAt, endAt }, setTimeRange, 'Created'),
        makeEnumFilter(
          structureFilter,
          setStructureFilter,
          'Structure',
          EntityGetResponseStructure,
          'building'
        ),
      ]),
    [
      endAt,
      groupFilter,
      keywordFilter,
      reviewStatusFilter,
      setGroupFilter,
      setKeywordFilter,
      setReviewStatusFilter,
      setStatusFilter,
      setStructureFilter,
      setTimeRange,
      startAt,
      statusFilter,
      structureFilter,
    ]
  );
  const listResult = useEntityReferenceListInfinite({
    groups: groupFilter.length > 0 ? groupFilter : undefined,
    statuses: statusFilter.length > 0 ? statusFilter : undefined,
    review_statuses:
      reviewStatusFilter.length > 0 ? reviewStatusFilter : undefined,
    keyword: keywordFilter ? keywordFilter : undefined,
    created_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    created_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined,
    structures: structureFilter.length > 0 ? structureFilter : undefined,
  });

  return (
    <PageLayout headline={'Entities'}>
      <TableStateWrapper
        table={EntitiesTable}
        style="primary"
        {...listResult}
        emptyTitle="No Entity"
        emptySubtitle="There are no entities to display"
        filters={filters}
        showGroup
      />
    </PageLayout>
  );
};
