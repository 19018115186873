import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { formatInteger, humanize } from 'shared/lib/formatting';
import { useMemo } from 'react';
import { ControlConfigurationGetResponse } from 'src/build/operations';
import { ROUTES, buildPath } from 'src/lib/routes';

type Props = PrestyledTableProps<ControlConfigurationGetResponse>;

export const ControlConfigurationsTable = (props: Props) => {
  const { getRowProps, ...rest } = props;
  const columns = useMemo<TableColumns<ControlConfigurationGetResponse>>(() => {
    return [
      {
        header: 'Category',
        expand: 1,
        contents: (control) => ({
          text: humanize(control.category),
          textWeight: 'medium',
          textColor: 'emphasis',
          statusIndicator:
            control.number_of_failures === 0 ? 'green' : 'yellow',
        }),
      },
      {
        header: 'Group',
        contents: (control) => ({
          text: control.group_name || control.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, { groupId: control.group_id }),
        }),
      },
      {
        header: 'Program',
        contents: (control) => ({
          text: control.program_name,
          href: buildPath(ROUTES.PROGRAMS_DETAIL, {
            programId: control.program_id,
          }),
        }),
      },
      {
        header: 'Number of successes',
        contents: (control) => ({
          text: formatInteger(control.number_of_successes),
        }),
      },
      {
        header: 'Number of failures',
        contents: (control) => ({
          text: formatInteger(control.number_of_failures),
        }),
      },
      {
        header: 'User visible?',
        contents: (control) => ({
          text: control.user_visible ? 'Yes' : 'No',
        }),
      },
    ];
  }, []);

  const defaultRowProps = (datum: ControlConfigurationGetResponse) => ({
    href: buildPath(ROUTES.CONTROLS_DETAIL, { id: datum.id }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <Table<ControlConfigurationGetResponse>
      columns={columns}
      getRowProps={getRowProps ?? defaultRowProps}
      {...rest}
    />
  );
};
