import React, { useMemo } from 'react';
import { useFedwireInboundRequestForReversalsListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { ROUTES } from 'src/lib/routes';
import { FedwireInboundRequestForReversalsTable } from '../tables/FedwireInboundRequestForReversalsTable';
import { TableFilter } from '../../../shared/components/Table/filters';
import { compact } from 'lodash';
import { makeStatusFilter } from '../../lib/tableFilterHelpers';
import { useSearchParamsState } from '../../../shared/hooks/useTypedSearchParamsState';

export const FedwireInboundRequestForReversalListTab = () => {
  const [statuses, setStatuses] = useSearchParamsState(
    ROUTES.FEDWIRE_INBOUND.REQUEST_FOR_REVERSALS,
    'statuses'
  );

  const listResult = useFedwireInboundRequestForReversalsListInfinite({
    statuses: statuses.length > 0 ? statuses : undefined,
  });

  const filters: TableFilter[] = useMemo(
    () =>
      compact([
        makeStatusFilter(statuses, setStatuses, {
          flagged_by_operator: 'Flagged by operator',
          pending_allocating: 'Pending allocating',
          abandoned: 'Abandoned',
          requires_attention: 'Requires attention',
          allocated: 'Allocated',
        }),
      ]),
    [statuses, setStatuses]
  );

  return (
    <TableStateWrapper
      table={FedwireInboundRequestForReversalsTable}
      style="primary"
      {...listResult}
      emptyTitle="No Fedwire request for reversals"
      emptySubtitle="There are no Fedwire request for reversals to display"
      filters={filters}
    />
  );
};
