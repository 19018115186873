import { useFeeStatementList, useGroupGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { Table } from './deprecated/Table';
import {
  formatAmount,
  formatISO8601TimestampAsISODate,
} from 'shared/lib/formatting';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';

export const GroupFeeStatementsList = () => {
  const { groupID } = useTypedParams(ROUTES.GROUP_FEE_STATEMENTS);

  const { data: group } = useGroupGet(groupID);
  const { data: feeStatementList } = useFeeStatementList({ group_id: groupID });

  if (!group || !feeStatementList) {
    return <></>;
  }

  const groupName = group.name || group.friendly_nickname || group.id;

  return (
    <PageLayout headline={`Fee Statements for ${groupName}`}>
      <Table
        data={feeStatementList.data}
        columns={[
          {
            key: 'period_start',
            label: 'Start',
            render: (fp) => formatISO8601TimestampAsISODate(fp.period_start),
          },
          {
            key: 'period_end',
            label: 'End',
            render: (fp) => formatISO8601TimestampAsISODate(fp.period_end),
          },
          {
            key: 'status',
            label: 'Status',
            render: (fp) => fp.status,
          },
          {
            key: 'fees_amount',
            label: 'Fees',
            render: (fp) =>
              fp.fees_amount && formatAmount(fp.fees_amount, 'USD'),
          },
          {
            key: 'earned_credits_amount',
            label: 'Credits',
            render: (fp) =>
              fp.earned_credits_amount &&
              formatAmount(fp.earned_credits_amount, 'USD'),
          },
          {
            key: 'payments_amount',
            label: 'Payments',
            render: (fp) =>
              fp.payments_amount && formatAmount(fp.payments_amount, 'USD'),
          },
        ]}
        href={(fs) => buildPath(ROUTES.OBJECT_VIEWER, { objectID: fs.id })}
      />
    </PageLayout>
  );
};
