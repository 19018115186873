import React from 'react';
import {
  useSanctionsScreeningReviewListInfinite,
  useTransferGet,
} from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import {
  CompletedTransactionListResponseDataItem,
  SanctionsScreeningReviewListResponseDataItem,
  TransferGetResponse,
  TransferGetResponseTransferType,
} from 'src/build/operations';
import { absurd } from 'src/lib/absurd';
import { SideBySide } from 'shared/components/SideBySide';
import { TransferPropertyList } from '../property-lists/TransferPropertyList';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import {
  CompletedTransactionsTable,
  CompletedTransactionsTableProps,
} from '../tables/CompletedTransactionTable';
import { Note } from 'shared/components/Note';
import { Body } from 'shared/components/Text';
import { Icon } from 'shared/components/Icon';
import { Box } from 'shared/components/Box';
import { ListItem, PropertyList } from 'shared/components/PropertyList';
import {
  formatAmount,
  formatISO8601Timestamp,
  humanize,
} from 'shared/lib/formatting';
import { compact } from 'lodash';
import { buildPath, ROUTES } from 'src/lib/routes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { OperatorNotes } from '../operator-notes';
import { SanctionsScreeningReviewsTable } from '../tables/SanctionScreeningReviewsTable';
import { AssociatedManualTasks } from '../associated-manual-tasks';

const SKIP_SOURCE_KEYS = [
  'id',
  'created_at',
  'updated_at',
  'amount',
  'account_id',
  'created_by_id',
  'created_by_type',
  'description',
];

function extractInstructionFields(
  object: object,
  prefix = ''
): { label: string; value: string }[] {
  return Object.entries(object)
    .filter(([key, value]) => !SKIP_SOURCE_KEYS.includes(key) && value != null)
    .map(([key, value]) => {
      if (value && typeof value == 'object') {
        return extractInstructionFields(value, prefix + key + '.');
      } else {
        return [{ label: humanize(`${prefix}${key}`), value: '' + value }];
      }
    })
    .reduce((a, b) => a.concat(b), []);
}

const SanctionsHitList = ({ transferId }: { transferId: string }) => {
  const sanctionsListResult = useSanctionsScreeningReviewListInfinite({
    record_ids: [transferId],
    limit: 10,
  });

  const getRowProps = (
    datum: SanctionsScreeningReviewListResponseDataItem
  ) => ({
    href: buildPath(ROUTES.SANCTIONS_SCREENING_REVIEW_DETAIL, {
      reviewID: datum.id,
    }),
    className: 'hover:bg-main-hover cursor-pointer transition-all group',
  });

  return (
    <TableStateWrapper
      {...sanctionsListResult}
      table={SanctionsScreeningReviewsTable}
      style="detail"
      title="Sanctions screening reviews"
      emptyTitle="No sanctions screening manual review"
      emptySubtitle="No manual review required. Did not raise a hit."
      viewMoreHref={buildPath(
        ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST,
        {},
        { records: [transferId] }
      )}
      showRecordId={false}
      getRowProps={getRowProps}
    />
  );
};

const isSanctionsScreenedTransfer = (transfer: TransferGetResponse): boolean =>
  transfer.transfer_type === 'wire_transfer' ||
  transfer.transfer_type === 'inbound_wire_transfer' ||
  (transfer.transfer_type === 'inbound_ach_transfer' &&
    (transfer.transfer_instruction as { standard_entry_class_code: string })
      .standard_entry_class_code === 'international_ach_transaction');

const summarize = (
  type: TransferGetResponseTransferType,
  amount: number
): string => {
  switch (type) {
    case 'account_transfer':
      return 'A transfer between two Increase accounts.';
    case 'check_deposit':
      return 'A remote check deposit. The Increase User uploaded a photo of a check.';
    case 'check_transfer':
      return 'A printed and mailed check sent from an Increase User to their counterparty.';
    case 'ach_transfer':
      return amount > 0
        ? 'An ACH credit originated by Increase. Funds are leaving Increase.'
        : 'An ACH debit originated by Increase. Funds are entering Increase.';
    case 'inbound_ach_transfer':
      return amount > 0
        ? 'An ACH credit originated by another bank, received at Increase. Funds are entering Increase.'
        : 'An ACH debit originated by another bank, received at Increase. Funds are leaving Increase';
    case 'real_time_payments_transfer':
      return 'A Real Time Payments transfer originated by Increase.';
    case 'inbound_real_time_payments_transfer':
      return 'An Real Time Payments transfer originated by another bank, received at Increase. Funds are entering Increase.';
    case 'inbound_wire_transfer':
      return 'An Wire Transfer originated by another bank, received at Increase. Funds are entering Increase.';
    case 'wire_transfer':
      return 'A Wire Transfer originated by Increase.';
    default:
      absurd(type);
  }
};

export const TransferDetailPage = () => {
  const { transferID } = useTypedParams(ROUTES.TRANSFER_DETAIL);

  const { data: transfer } = useTransferGet(transferID);

  if (!transfer) {
    return <>Loading...</>;
  }

  return (
    <PageLayout id="application.transferDetails" headline={transferID}>
      <SideBySide
        right={
          <>
            <TransferPropertyList
              title="Details"
              id={transferID}
              transfer={transfer}
              layoutHorizontallyWhenPossible={false}
            />
            <AssociatedManualTasks objectId={transferID} />
          </>
        }
        left={
          <>
            <Note>
              <Box flex center gap="2">
                <Icon name="info" />
                <Body>
                  {summarize(transfer.transfer_type, transfer.amount)}
                </Body>
              </Box>
            </Note>

            <PropertyList
              title="Transfer instruction"
              items={extractInstructionFields(
                transfer.transfer_instruction as Record<string, string>
              )}
            />

            {transfer.inbound_funds_hold && (
              <PropertyList
                title="Inbound funds hold"
                items={compact<ListItem>([
                  {
                    label: 'Id',
                    value: transfer.inbound_funds_hold.id,
                  },
                  {
                    label: 'Status',
                    value: humanize(transfer.inbound_funds_hold.status),
                  },
                  {
                    label: 'Held amount',
                    value: formatAmount(
                      transfer.inbound_funds_hold.amount,
                      'USD'
                    ),
                  },
                  {
                    label: 'Automatically releases at',
                    value: formatISO8601Timestamp(
                      transfer.inbound_funds_hold.automatically_releases_at,
                      'full'
                    ),
                  },
                  transfer.inbound_funds_hold.released_at && {
                    label: 'Released at',
                    value: formatISO8601Timestamp(
                      transfer.inbound_funds_hold.released_at,
                      'full'
                    ),
                  },
                ])}
              />
            )}

            {transfer.transfer_approval && (
              <PropertyList
                title="Transfer approval by User"
                items={[
                  {
                    label: 'Approved by',
                    value:
                      transfer.transfer_approval.approved_by_email ||
                      transfer.transfer_approval.approved_by_id,
                    href:
                      transfer.transfer_approval.approved_by_email &&
                      transfer.transfer_approval.approved_by_id
                        ? buildPath(ROUTES.USERS_DETAIL, {
                            userId: transfer.transfer_approval.approved_by_id,
                          })
                        : undefined,
                  },
                  {
                    label: 'Approved at',
                    value: formatISO8601Timestamp(
                      transfer.transfer_approval.created_at,
                      'full'
                    ),
                  },
                ]}
              />
            )}

            {transfer.transfer_cancellation && (
              <PropertyList
                title="Transfer cancellation by User"
                items={[
                  {
                    label: 'Canceled by',
                    value:
                      transfer.transfer_cancellation.canceled_by_email ||
                      transfer.transfer_cancellation.canceled_by_id ||
                      '-',
                    href:
                      transfer.transfer_cancellation.canceled_by_email &&
                      transfer.transfer_cancellation.canceled_by_id
                        ? buildPath(ROUTES.USERS_DETAIL, {
                            userId:
                              transfer.transfer_cancellation.canceled_by_id,
                          })
                        : undefined,
                  },
                  {
                    label: 'Canceled at',
                    value: formatISO8601Timestamp(
                      transfer.transfer_cancellation.created_at,
                      'full'
                    ),
                  },
                ]}
              />
            )}

            {transfer.transfer_review && (
              <PropertyList
                title={`Transfer review by ${humanize(
                  transfer.transfer_review.reviewer
                )}`}
                items={compact<ListItem>([
                  {
                    label: 'Review reason',
                    value: humanize(transfer.transfer_review.reason),
                  },
                  {
                    label: 'Held for review at',
                    value: formatISO8601Timestamp(
                      transfer.transfer_review.created_at,
                      'full'
                    ),
                  },
                  {
                    label: 'Outcome',
                    value: transfer.transfer_reviewing_manual_approval
                      ? 'Approved'
                      : transfer.transfer_reviewing_manual_denial
                        ? 'Denied'
                        : 'Pending',
                    badgeColor: transfer.transfer_reviewing_manual_approval
                      ? 'green'
                      : transfer.transfer_reviewing_manual_denial
                        ? 'red'
                        : 'yellow',
                  },
                  transfer.transfer_reviewing_manual_approval && {
                    label: 'Approved by',
                    value:
                      transfer.transfer_reviewing_manual_approval
                        .operator_email,
                  },
                  transfer.transfer_reviewing_manual_approval && {
                    label: 'Approved at',
                    value: formatISO8601Timestamp(
                      transfer.transfer_reviewing_manual_approval.created_at,
                      'full'
                    ),
                  },
                  transfer.transfer_reviewing_manual_denial && {
                    label: 'Denied by',
                    value:
                      transfer.transfer_reviewing_manual_denial.operator_email,
                  },
                  transfer.transfer_reviewing_manual_denial && {
                    label: 'Denied at',
                    value: formatISO8601Timestamp(
                      transfer.transfer_reviewing_manual_denial.created_at,
                      'full'
                    ),
                  },
                ])}
              />
            )}

            <TableStateWrapper<
              CompletedTransactionListResponseDataItem,
              CompletedTransactionsTableProps
            >
              table={CompletedTransactionsTable}
              error={null}
              viewMoreHref={''}
              style="detail"
              title="Transactions"
              emptyTitle="No transactions"
              emptySubtitle="No money has moved."
              data={transfer.account_transactions}
              showAccount={transfer.transfer_type === 'account_transfer'}
            />

            {isSanctionsScreenedTransfer(transfer) && (
              <SanctionsHitList transferId={transferID} />
            )}

            <OperatorNotes modelId={transferID} />
          </>
        }
        mode="rightDrawer"
      />
    </PageLayout>
  );
};
