import {
  useBankFeeListInfinite,
  useBankFeeStatementGet,
  useBankProgramCurrentFeePlanGet,
  useBankProgramGet,
} from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from 'shared/components/SideBySide';
import { Loading } from 'shared/components/Loading';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { PropertyList } from 'shared/components/PropertyList';
import {
  formatAmount,
  formatISO8601Timestamp,
  humanize,
} from 'shared/lib/formatting';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { BankFeesTable } from '../tables/BankFeesTable';
import { compact } from 'lodash';

export const BankFeeStatementDetailPage = () => {
  const { bankFeeStatementId } = useTypedParams(
    ROUTES.BANK_FEE_STATEMENTS_DETAIL
  );

  const { data: bankFeeStatement } = useBankFeeStatementGet(bankFeeStatementId);
  const { data: bankProgram } = useBankProgramGet(
    bankFeeStatement?.bank_program_id ?? '',
    {
      enabled: !!bankFeeStatement,
    }
  );

  const { data: feePlan } = useBankProgramCurrentFeePlanGet(
    bankFeeStatement?.bank_program_id ?? '',
    {
      enabled: !!bankFeeStatement,
    }
  );

  const bankFeeListResult = useBankFeeListInfinite({
    bank_fee_statement_id: bankFeeStatementId,
  });

  if (!bankFeeStatement || !feePlan || !bankProgram) {
    return <Loading />;
  }

  const title =
    bankProgram.partner_name +
    ' ' +
    formatISO8601Timestamp(bankFeeStatement.period_start, 'month-year', 'UTC');

  const totalFees = bankFeeStatement.cached_bank_fee_statement_summaries.reduce(
    (acc, summary) => acc + summary.amount,
    0
  );

  return (
    <PageLayout headline={title}>
      <SideBySide
        mode="rightDrawer"
        right={
          <PropertyList
            title="Summary"
            layoutHorizontallyWhenPossible={false}
            items={compact([
              {
                label: 'Total Bank Fees (not including Service Fee)',
                value: formatAmount(totalFees, 'USD'),
              },
              ...bankFeeStatement.cached_bank_fee_statement_summaries.map(
                (summary) => ({
                  label: humanize(summary.fee_type),
                  value: `${formatAmount(summary.amount, 'USD')} (${
                    summary.count
                  })`,
                })
              ),
              bankFeeStatement.cached_service_fee_total !== null && {
                label: 'Service Fee',
                value: formatAmount(
                  bankFeeStatement.cached_service_fee_total,
                  'USD'
                ),
              },
            ])}
          />
        }
        left={
          <TableStateWrapper
            {...bankFeeListResult}
            style="primary"
            table={BankFeesTable}
            emptyTitle="No Fees"
            emptySubtitle="There are no fees to display"
            filters={[]}
          />
        }
      />
    </PageLayout>
  );
};
