import {
  useOperatorManualQueueItemListInfinite,
  useOperatorManualQueueItemPatch,
} from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { useMemo } from 'react';
import { PrestyledTableProps, Table } from 'shared/components/Table';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import {
  OperatorManualQueueItemListResponseDataItem,
  OperatorManualQueueItemListStatusItem,
} from 'src/build/operations';
import { makeStatusFilter } from 'src/lib/tableFilterHelpers';

const QueueTable = (
  props: PrestyledTableProps<OperatorManualQueueItemListResponseDataItem>
) => {
  const operatorManualQueueItemPatch = useOperatorManualQueueItemPatch();

  return (
    <Table
      {...props}
      getRowProps={() => ({
        className: 'hover:bg-main-hover transition-all group',
      })}
      columns={[
        {
          header: 'ID',
          contents: (r) => ({
            text: r.id,
            href: r.link || undefined,
          }),
        },
        {
          header: 'Cause',
          contents: (r) => ({
            text: r.cause || 'Unknown',
          }),
        },
        {
          header: 'Description',
          contents: (r) => ({
            text: r.description,
          }),
        },
        {
          header: 'Status',
          contents: (r) => ({
            text: r.status,
          }),
        },
        {
          header: 'Created at',
          contents: (r) => ({
            text: formatISO8601Timestamp(r.created_at, 'full'),
          }),
        },
        {
          header: 'Actions',
          contents: (r) => ({
            menuItems: [
              {
                key: 'close',
                icon: 'close_circle',
                title: 'Mark actioned',
                onClick: () => {
                  operatorManualQueueItemPatch.mutateAsync([
                    r.id,
                    {
                      status: 'actioned',
                    },
                  ]);
                },
              },
            ],
          }),
        },
      ]}
    />
  );
};

export const OperatorManualQueueItems = () => {
  const [statusFilter, setStatusFilter] = useSearchParamsState(
    ROUTES.QUEUES,
    'status'
  );

  const filters = useMemo(
    () => [
      makeStatusFilter(
        statusFilter,
        setStatusFilter,
        OperatorManualQueueItemListStatusItem
      ),
    ],
    [statusFilter, setStatusFilter]
  );

  const listResponse = useOperatorManualQueueItemListInfinite({
    queue: ['transaction_review'],
    status: statusFilter.length > 0 ? statusFilter : undefined,
  });

  return (
    <PageLayout
      id="application.operatorManualQueueItems"
      headline="Operator manual queue items"
    >
      <TableStateWrapper
        {...listResponse}
        table={QueueTable}
        emptyTitle="No reviews"
        emptySubtitle="There are no reviews to display"
        style="primary"
        filters={filters}
      />
    </PageLayout>
  );
};
