import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useOperatorGet, useProgramListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import {
  makeBankFilter,
  makeBankProgramFilter,
  makeEnumFilter,
  makeGroupFilter,
  makeTimeRangeFilter,
} from 'src/lib/tableFilterHelpers';
import { compact } from 'lodash';
import { TableFilter } from 'shared/components/Table/filters';
import { ProgramsTable } from '../tables/ProgramsTable';
import { DateTime } from 'luxon';
import { ProgramGetResponseIncreaseComplianceRole } from 'src/build/operations';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { useTypedCreatedAtRangeParams } from 'src/hooks/timeSearchParameters';

export const ProgramListPage = () => {
  const { data: operator } = useOperatorGet({});
  const [groupFilter, setGroupFilter] = useSearchParamsState(
    ROUTES.PROGRAMS_LIST,
    'group'
  );

  const [bankProgramFilter, setBankProgramFilter] = useSearchParamsState(
    ROUTES.PROGRAMS_LIST,
    'bank_program'
  );
  const [{ startAt, endAt }, setTimeRange] = useTypedCreatedAtRangeParams(
    ROUTES.PROGRAMS_LIST
  );
  const [bankFilter, setBankFilter] = useSearchParamsState(
    ROUTES.PROGRAMS_LIST,
    'bank'
  );

  const [complianceRoleFilter, setComplianceRoleFilter] = useSearchParamsState(
    ROUTES.PROGRAMS_LIST,
    'compliance_role'
  );

  const startAtDateTime = startAt
    ? DateTime.fromISO(startAt, { zone: 'utc' })
    : undefined;
  const endAtDateTime = endAt
    ? DateTime.fromISO(endAt, { zone: 'utc' })
    : undefined;

  const filters: TableFilter[] = useMemo(
    () =>
      compact([
        makeGroupFilter(groupFilter, setGroupFilter),
        makeBankProgramFilter(bankProgramFilter, setBankProgramFilter),
        makeTimeRangeFilter({ startAt, endAt }, setTimeRange, 'Created'),
        operator?.role === 'internal' &&
          makeBankFilter(bankFilter, setBankFilter),
        makeEnumFilter(
          complianceRoleFilter,
          setComplianceRoleFilter,
          'Compliance role',
          ProgramGetResponseIncreaseComplianceRole,
          'network'
        ),
      ]),
    [
      groupFilter,
      setGroupFilter,
      bankProgramFilter,
      setBankProgramFilter,
      startAt,
      endAt,
      setTimeRange,
      operator?.role,
      bankFilter,
      setBankFilter,
      complianceRoleFilter,
      setComplianceRoleFilter,
    ]
  );
  const listResult = useProgramListInfinite({
    groups: groupFilter.length > 0 ? groupFilter : undefined,
    bank_programs: bankProgramFilter.length > 0 ? bankProgramFilter : undefined,
    created_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    created_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined,
    banks: bankFilter.length > 0 ? bankFilter : undefined,
    compliance_roles:
      complianceRoleFilter.length > 0 ? complianceRoleFilter : undefined,
  });

  return (
    <PageLayout headline={'Programs'}>
      <TableStateWrapper
        table={ProgramsTable}
        style="primary"
        {...listResult}
        emptyTitle="No programs"
        emptySubtitle="There are no programs to display"
        filters={filters}
        showGroup
      />
    </PageLayout>
  );
};
