import React from 'react';
import {
  useControlEvaluationListInfinite,
  useControlRecordGet,
} from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from 'shared/components/SideBySide';
import { humanize } from 'shared/lib/formatting';
import { ListItem, PropertyList } from 'shared/components/PropertyList';
import { AssociatedManualTasks } from '../associated-manual-tasks';
import { compact } from 'lodash';
import { ControlRecordPropertyList } from '../property-lists/ControlRecordPropertyList';
import { ControlConfigurationGetResponseResult } from 'src/build/operations';
import { BadgeColor } from 'shared/components/Badge';
import { OperatorNotes } from '../operator-notes';
import { controlRecordLink } from '../tables/ControlRecordsTable';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { ControlEvaluationsTable } from '../tables/ControlEvaluationsTable';
import { Heading } from 'shared/components/Text';
import { Box } from 'shared/components/Box';

const resultBadgeColor: Record<
  ControlConfigurationGetResponseResult,
  BadgeColor
> = {
  success: 'green',
  failure: 'red',
};

export const ControlRecordDetailPage = () => {
  const { id: controlRecordId } = useTypedParams(
    ROUTES.CONTROL_RECORDS_DETAILS
  );

  const { data: controlRecord } = useControlRecordGet(controlRecordId);

  const evaluations = useControlEvaluationListInfinite({
    control_record_id: controlRecordId,
  });

  if (!controlRecord) {
    return <>Loading...</>;
  }

  return (
    <PageLayout
      headline={`${humanize(controlRecord.category)} - ${
        controlRecord.record_id
      }`}
    >
      <SideBySide
        right={
          <ControlRecordPropertyList
            title="Details"
            controlRecord={controlRecord}
            layoutHorizontallyWhenPossible={false}
          />
        }
        left={
          <>
            <PropertyList
              items={compact<ListItem>([
                {
                  label: 'Associated object',
                  value: controlRecord.record_id,
                  href: controlRecordLink(controlRecord),
                },
                controlRecord.failure_message && {
                  label: 'Failure message',
                  value: controlRecord.failure_message,
                },
                controlRecord.control_exception && {
                  label: 'Exception override result',
                  value: humanize(
                    controlRecord.control_exception.exception_result
                  ),
                  badgeColor:
                    resultBadgeColor[
                      controlRecord.control_exception.exception_result
                    ],
                },
                controlRecord.control_exception && {
                  label: 'Exception set by',
                  value: controlRecord.control_exception.created_by_email,
                },
              ])}
            />
            <AssociatedManualTasks objectId={controlRecordId} />
            <OperatorNotes modelId={controlRecordId} />

            <Box gap="0">
              <Heading>Evaluations</Heading>
              <TableStateWrapper
                {...evaluations}
                table={ControlEvaluationsTable}
                style="primary"
                filters={[]}
                emptyTitle="No evaluations"
                emptySubtitle="There are no evaluations for this control. Evaluations are run daily."
              />
            </Box>
          </>
        }
        mode="rightDrawer"
      />
    </PageLayout>
  );
};
