import { SideBySide } from 'shared/components/SideBySide';
import {
  useAccountListInfinite,
  useCompletedTransactionListInfinite,
  useEntityReferenceListInfinite,
  useGroupGet,
  useOperatorGet,
  useProgramListInfinite,
  useRoleListInfinite,
} from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { GroupPropertyList } from '../property-lists/GroupPropertyList';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { RolesTable } from '../tables/RolesTable';
import { AccountsTable } from '../tables/AccountsTable';
import { ProgramsTable } from '../tables/ProgramsTable';
import { EntitiesTable } from '../tables/EntitiesTable';
import { AlertList, AlertListProps } from 'shared/components/AlertList';
import {
  GroupGetResponseStatus,
  userImpersonatePost,
} from 'src/build/operations';
import { StackOfLinks } from 'shared/components/StackOfLinks';
import { GroupAPILimitsPropertyList } from '../property-lists/GroupAPILimitsPropertyList';
import { InternalOperatorOnly } from '../internal-operator-only';
import { sortBy } from 'lodash';
import { OperatorFiles } from '../operator-files';
import { Box } from 'shared/components/Box';
import { Body, Heading } from 'shared/components/Text';
import { OperatorNotes } from '../operator-notes';
import { AssociatedManualTasks } from '../associated-manual-tasks';
import { Button } from 'shared/components/Button';
import { useServices } from 'src/hooks/use-services';
import { CompletedTransactionsTable } from '../tables/CompletedTransactionTable';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { OperatorTags } from '../operator-tags';

const alertListTaskByGroupStatus: Record<
  GroupGetResponseStatus,
  AlertListProps['tasks'][number] | null
> = {
  pending_activating: {
    title: 'This Group is pending activation.',
    body: 'They have not yet completed onboarding.',
    style: 'warning',
    icon: 'alert',
    key: 'group-not-active',
  },
  active: null,
  offboarded: {
    title: 'This Group has been offboarded.',
    body: 'They now have read-only access to the Increase platform. They cannot initiate money movement.',
    style: 'warning',
    icon: 'alert',
    key: 'group-not-active',
  },
  rejected: {
    title: 'This Group has been rejected.',
    body: 'They were not allowed access to the Increase platform.',
    style: 'warning',
    icon: 'alert',
    key: 'group-not-active',
  },
};

export const GroupDetailPage = () => {
  const { groupId } = useTypedParams(ROUTES.GROUPS_DETAIL);
  const { data: group } = useGroupGet(groupId);
  const { data: operator } = useOperatorGet({});
  const services = useServices();

  const roleListResult = useRoleListInfinite(
    {
      group_ids: [groupId],
      limit: 3,
      statuses: ['active'],
    },
    {
      enabled: operator?.role === 'internal',
    }
  );

  const entityListResult = useEntityReferenceListInfinite({
    groups: [groupId],
    limit: 3,
  });

  const programList = useProgramListInfinite({
    groups: [groupId],
  });

  const accountListResult = useAccountListInfinite({
    groups: [groupId],
    limit: 3,
    statuses: ['open'],
  });

  const { data: owners } = useRoleListInfinite({
    group_ids: [groupId],
    titles: ['owner'],
    statuses: ['active'],
  });

  const completedTransactionListResult = useCompletedTransactionListInfinite({
    groups: [groupId],
    limit: 3,
  });

  const owner = owners ? owners.pages.flatMap((page) => page.data)[0] : null;

  if (!group || !operator) {
    return null;
  }

  const isInternalOperator = operator?.role === 'internal';
  const statusAlertForBank = alertListTaskByGroupStatus[group.status];

  const allOperatorLinks = [
    [buildPath(ROUTES.ACCOUNTS_LIST, {}, { group: [groupId] }), 'Accounts'],
    [
      buildPath(ROUTES.TRANSACTIONS_LIST, {}, { group: [groupId] }),
      'Transactions',
    ],
    [
      buildPath(ROUTES.PENDING_TRANSACTIONS_LIST, {}, { group: [groupId] }),
      'Pending Transactions',
    ],
    [buildPath(ROUTES.CARDS_LIST, {}, { group: [groupId] }), 'Cards'],
    [
      buildPath(ROUTES.ACCOUNT_NUMBERS_LIST, {}, { group: [groupId] }),
      'Account Numbers',
    ],
    [buildPath(ROUTES.ENTITIES_LIST, {}, { group: [groupId] }), 'Entities'],
    [
      buildPath(ROUTES.LATE_RETURN_REQUESTS_LIST, {}, { group: [groupId] }),
      'Late Return Requests',
    ],
    [
      buildPath(ROUTES.DOCUMENT_REQUESTS_LIST, {}, { group: [groupId] }),
      'Document Requests',
    ],
    [
      buildPath(ROUTES.PLATFORM_VENDORS, {}, { group: [groupId] }),
      'Vendors List',
    ],
    [
      buildPath(
        ROUTES.COMPLAINTS.PLATFORM_COMPLAINTS,
        {},
        { group: [groupId] }
      ),
      'Complaints',
    ],
    [
      buildPath(
        ROUTES.UNUSUAL_ACTIVITY_REPORTS.PLATFORM,
        {},
        { group: [groupId] }
      ),
      'Unusual Activity Reports',
    ],
  ];

  const internalOnlyLinks = [
    [
      buildPath(ROUTES.GROUP_GATES, {
        groupID: groupId,
      }),
      'Gates',
    ],
    [
      buildPath(
        ROUTES.CONTROLS_LIST,
        {},
        {
          group: [groupId],
        }
      ),
      'Controls',
    ],
    [
      buildPath(ROUTES.GROUP_FEE_PLANS, {
        groupID: groupId,
      }),
      'Fee Plans',
    ],
    [
      buildPath(
        ROUTES.OAUTH_APPLICATION_LIST,
        {},
        {
          group: [groupId],
        }
      ),
      'OAuth Applications',
    ],
    [
      buildPath(
        ROUTES.OAUTH_CONNECTION_LIST,
        {},
        {
          connected_group: [groupId],
        }
      ),
      'OAuth Connections',
    ],
  ];

  const links = (
    isInternalOperator
      ? allOperatorLinks.concat(internalOnlyLinks)
      : allOperatorLinks
  ).map(([href, text]) => ({ href, text }));

  return (
    <PageLayout
      headline={`${group.name || group.id} (${group.friendly_nickname})`}
    >
      <SideBySide
        mode="rightDrawer"
        right={
          <>
            <GroupPropertyList
              layoutHorizontallyWhenPossible={false}
              group={group}
              title="Details"
              showBanks={isInternalOperator}
            />
            <StackOfLinks links={sortBy(links, (l) => l.text)} />
            <AssociatedManualTasks objectId={groupId} />
            <InternalOperatorOnly>
              <GroupAPILimitsPropertyList
                layoutHorizontallyWhenPossible={false}
                groupId={groupId}
                title="API Limits"
              />
              <Box>
                <div className="flex items-center justify-between space-x-3">
                  <div className="grow">
                    <Heading contents={'Operator Tags'} />
                  </div>
                </div>

                <div className="border-b-0 border-t border-main py-2.5">
                  <OperatorTags modelId={groupId} idPrefix="group" />
                </div>
              </Box>
            </InternalOperatorOnly>
          </>
        }
        left={
          <>
            {statusAlertForBank && <AlertList tasks={[statusAlertForBank]} />}
            <InternalOperatorOnly>
              <TableStateWrapper
                table={RolesTable}
                {...roleListResult}
                style="detail"
                title={roleListResult.hasNextPage ? 'Sample Users' : 'Users'}
                emptyTitle="No Roles"
                emptySubtitle="There are no roles to display"
                action={
                  owner && (
                    <Button
                      style="secondary"
                      size="small"
                      keyboardShortcut="⌘I"
                      text="Impersonate owner"
                      onClick={() =>
                        userImpersonatePost(owner.user_id).then(() =>
                          window.open(services.dashboard, '_blank')
                        )
                      }
                    />
                  )
                }
                showGroup={false}
                showActions={operator.role === 'internal'}
                viewMoreHref={buildPath(
                  ROUTES.ROLES_LIST,
                  {},
                  { group: [groupId], status: ['active'] }
                )}
              />
            </InternalOperatorOnly>
            <TableStateWrapper
              table={EntitiesTable}
              {...entityListResult}
              style="detail"
              title={
                entityListResult.hasNextPage ? 'Sample Entities' : 'Entities'
              }
              emptyTitle="No Entities"
              emptySubtitle="There are no entities to display"
              showGroup={false}
              viewMoreHref={buildPath(
                ROUTES.ENTITIES_LIST,
                {},
                { group: [groupId] }
              )}
            />
            <TableStateWrapper
              table={ProgramsTable}
              {...programList}
              style="detail"
              title={programList.hasNextPage ? 'Sample Programs' : 'Programs'}
              emptyTitle="No Programs"
              emptySubtitle="There are no programs to display"
              viewMoreHref={buildPath(
                ROUTES.PROGRAMS_LIST,
                {},
                { group: [groupId] }
              )}
            />
            <TableStateWrapper
              table={AccountsTable}
              {...accountListResult}
              style="detail"
              title={
                accountListResult.hasNextPage ? 'Sample Accounts' : 'Accounts'
              }
              emptyTitle="No Accounts"
              emptySubtitle="There are no accounts to display"
              showGroup={false}
              showBank
              viewMoreHref={buildPath(
                ROUTES.ACCOUNTS_LIST,
                {},
                { group: [groupId] }
              )}
              showProgram
            />
            <TableStateWrapper
              table={CompletedTransactionsTable}
              {...completedTransactionListResult}
              style="detail"
              title={'Recent Transactions'}
              emptyTitle="No Transactions"
              emptySubtitle="There are no transactions to display"
              showGroup={false}
              viewMoreHref={buildPath(
                ROUTES.TRANSACTIONS_LIST,
                {},
                { group: [groupId] }
              )}
            />
            <Box>
              <Heading>Operator Files</Heading>
              <OperatorFiles modelId={group.id} />
            </Box>
            <InternalOperatorOnly>
              <Box>
                <Heading>User submitted company details</Heading>
                <Body>{group.submitted_company_details}</Body>
              </Box>
            </InternalOperatorOnly>
            <Box>
              <Heading>Operator Notes</Heading>
              <OperatorNotes modelId={group.id} />
            </Box>
          </>
        }
      />
    </PageLayout>
  );
};
