import React from 'react';
import { PageLayout } from './deprecated/PageLayout';
import { useRoutingNumberListInfinite } from 'src/hooks/reactQuery';
import { Table } from './deprecated/Table';
import { StyledLink } from './deprecated/StyledLink';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { Toggle } from './deprecated/Toggle';
import { IntersectionObserver } from './deprecated/IntersectionObserver';
import { useSearchParams } from 'react-router-dom';

export const RoutingNumberList = () => {
  const [queryParams] = useSearchParams();

  const [showOnlyNeedsWebsite, setShowOnlyNeedsWebsite] = React.useState(false);
  const [showOnlyNeedsIcon, setShowOnlyNeedsIcon] = React.useState(false);
  const [filterByRoutingNumber, setFilterByRoutingNumber] = React.useState(
    queryParams.get('routing_number') ?? ''
  );

  const { data: routingNumberResponse, fetchNextPage } =
    useRoutingNumberListInfinite({
      needs_website: showOnlyNeedsWebsite,
      needs_icon: showOnlyNeedsIcon,
      // ignore empty strings
      routing_number: filterByRoutingNumber ? filterByRoutingNumber : undefined,
    });

  if (!routingNumberResponse) {
    return <></>;
  }

  const routingNumbers = routingNumberResponse.pages.map((p) => p.data).flat();

  return (
    <PageLayout headline="Routing Numbers">
      <div className="space-y-1">
        <div className="font-medium">Filters</div>
        <div className="grid grid-cols-4 gap-4">
          <div>
            <input
              key="routing_number"
              type="text"
              className="w-full rounded border border-strong"
              placeholder="Routing number"
              value={filterByRoutingNumber}
              onChange={(e) => setFilterByRoutingNumber(e.target.value)}
            />
          </div>
          <div className="flex items-center space-x-2">
            <Toggle
              enabled={showOnlyNeedsWebsite}
              onChange={setShowOnlyNeedsWebsite}
            />
            <div>Needs website</div>
          </div>
          <div className="flex items-center space-x-2">
            <Toggle
              enabled={showOnlyNeedsIcon}
              onChange={setShowOnlyNeedsIcon}
            />
            <div>Needs icon</div>
          </div>
        </div>
      </div>
      <Table
        data={routingNumbers}
        columns={[
          {
            key: 'routing_number',
            label: 'Routing Number',
          },
          {
            key: 'financial_institution_icon_url',
            label: 'Icon',
            render: (record) => {
              if (!record.financial_institution_icon_url) {
                return null;
              }

              return (
                <img
                  src={record.financial_institution_icon_url}
                  className="h-4 w-4"
                  alt="icon"
                />
              );
            },
          },
          {
            key: 'name',
            label: 'Name',
          },
          {
            key: 'financial_institution_website',
            label: 'Website',
            render: (record) => {
              if (!record.financial_institution_website) {
                return null;
              }
              return (
                <StyledLink
                  to={record.financial_institution_website}
                  target="_blank"
                >
                  {record.financial_institution_website}
                </StyledLink>
              );
            },
          },
          {
            key: 'fedach_enabled',
            label: 'ACH',
            render: (record) => {
              return record.fedach_enabled ? (
                <CheckIcon className="h-4 w-4" />
              ) : (
                <XMarkIcon className="h-4 w-4" />
              );
            },
          },
          {
            key: 'fedwire_enabled',
            label: 'Wire',
            render: (record) => {
              return record.fedwire_enabled ? (
                <CheckIcon className="h-4 w-4" />
              ) : (
                <XMarkIcon className="h-4 w-4" />
              );
            },
          },
          {
            key: 'real_time_payments_enabled',
            label: 'RTP',
            render: (record) => {
              return record.real_time_payments_enabled ? (
                <CheckIcon className="h-4 w-4" />
              ) : (
                <XMarkIcon className="h-4 w-4" />
              );
            },
          },
        ]}
      />
      <IntersectionObserver onVisible={fetchNextPage} />
    </PageLayout>
  );
};
