import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Outlet, useNavigate } from 'react-router';
import { useOperatorGet, useSessionDelete } from 'src/hooks/reactQuery';
import {
  OperatorGetResponseRole,
  ProgramListBanksItem,
} from 'src/build/operations';
import { SegmentedControl } from 'shared/components/SegmentedControl';
import {
  HrefSegment,
  SidebarSegmentedControl,
} from 'shared/components/SidebarSegmentedControl';
import { IconType } from 'shared/components/Icon';
import { MenuLayout } from 'shared/components/MenuLayout';
import { Menu } from 'shared/components/Menu';
import { TextButton } from 'shared/components/TextButton';
import { getBankIconString } from 'src/lib/getBankIconString';
import { InternalOperatorOnly } from './internal-operator-only';
import { Button } from 'shared/components/Button';
import { ROUTES, buildPath } from 'src/lib/routes';
import { ModalContainer } from 'shared/components/Modal';
import { uniq } from 'lodash';

type Section = 'Metrics' | 'Data' | 'Queues' | 'Operations';

type Viewer =
  | 'internal'
  | 'partner'
  | 'check_reviewer'
  | 'transaction_monitor'
  | 'card_printer';

const roleToViewer = (role: OperatorGetResponseRole): Viewer =>
  (
    ({
      internal: 'internal',
      check_reviewer: 'check_reviewer',
      transaction_monitor: 'transaction_monitor',
      card_printer: 'card_printer',
      partner: 'partner',
      partner_read_write: 'partner',
      partner_read_only: 'partner',
      partner_administrator: 'partner',
      limit_approver: 'partner',
    }) as const
  )[role];

const SECTIONS: readonly { title: Section }[] = [
  { title: 'Metrics' },
  { title: 'Data' },
  { title: 'Queues' },
  { title: 'Operations' },
] as const;

type Route = {
  name: string;
  href: string;
  additionalRoutes?: string[];
  iconType: IconType;
  roles: Viewer[];
  sectionBreak?: boolean;
  bank?: ProgramListBanksItem; // restrict the visibility of this route to a specific bank
};

const METRICS: Route[] = [
  {
    name: 'Metrics Overview',
    href: buildPath(ROUTES.MANAGEMENT_INFORMATION_SYSTEM.OVERVIEW, {}),
    additionalRoutes: [buildPath(ROUTES.MANAGEMENT_INFORMATION_SYSTEM, {})],
    iconType: 'home',
    roles: ['internal', 'partner'],
  },
  {
    name: 'ACH return rates',
    href: buildPath(ROUTES.ACH_RETURN_RATES_LIST, {}),
    iconType: 'table',
    roles: ['internal', 'partner'],
  },
];

const DATA: Route[] = [
  {
    name: 'Bank programs',
    href: buildPath(ROUTES.BANK_PROGRAMS_LIST, {}),
    iconType: 'bank',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Groups',
    href: buildPath(ROUTES.GROUPS_LIST, {}, { statuses: ['active'] }),
    iconType: 'cube',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Accounts',
    href: buildPath(ROUTES.ACCOUNTS_LIST, {}, { statuses: ['open'] }),
    iconType: 'account',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Programs',
    href: buildPath(ROUTES.PROGRAMS_LIST, {}),
    additionalRoutes: ['/document_request_schedule'],
    iconType: 'folder',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Transactions',
    href: buildPath(ROUTES.TRANSACTIONS_LIST, {}),
    additionalRoutes: ['/pending_transactions', '/transfers', '/card_payments'],
    iconType: 'transaction',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Account numbers',
    href: buildPath(
      ROUTES.ACCOUNT_NUMBERS_LIST,
      {},
      { statuses: ['active', 'disabled'] }
    ),
    iconType: 'route',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Cards',
    href: buildPath(
      ROUTES.CARDS_LIST,
      {},
      { statuses: ['active', 'disabled'] }
    ),
    iconType: 'card',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Entities',
    href: buildPath(ROUTES.ENTITIES_LIST, {}),
    additionalRoutes: ['/beneficial_owners', '/entity_clusters'],
    iconType: 'user',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Entity clusters',
    href: buildPath(ROUTES.ENTITY_CLUSTERS_LIST, {}),
    iconType: 'users',
    roles: ['internal', 'transaction_monitor'],

    sectionBreak: true,
  },
  {
    name: 'Late return requests',
    href: buildPath(ROUTES.LATE_RETURN_REQUESTS_LIST, {}),
    additionalRoutes: ['/late_return_request_create'],
    iconType: 'arrow_reverse',
    roles: ['internal', 'partner', 'check_reviewer'],
  },
  {
    name: 'Transfer lookup',
    href: buildPath(ROUTES.TRANSFER_LOOKUP, {}),
    iconType: 'search',
    roles: ['internal'],
  },
  {
    name: 'Document requests',
    href: buildPath(ROUTES.DOCUMENT_REQUESTS_LIST, {}),
    iconType: 'page',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Bookkeeping accounts',
    href: buildPath(ROUTES.BOOKKEEPING_ACCOUNTS_LIST, {}),
    iconType: 'table',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Compliance submissions',
    href: buildPath(ROUTES.PLATFORM_COMPLIANCE_SUBMISSIONS, {}),
    additionalRoutes: [
      buildPath(ROUTES.PLATFORM_VENDOR_LIST_SUBMISSIONS_LIST, {}),
      buildPath(ROUTES.PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_LIST, {}),
      buildPath(ROUTES.PLATFORM_COMPLAINT_LIST_SUBMISSIONS_LIST, {}),
    ],
    iconType: 'file_check',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Complaints',
    href: buildPath(ROUTES.COMPLAINTS, {}),
    additionalRoutes: ['/platform_complaints'],
    iconType: 'message_alert',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Vendors',
    href: buildPath(ROUTES.PLATFORM_VENDORS, {}),
    additionalRoutes: ['/platform_vendors'],
    iconType: 'building',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Onboarding surveys',
    href: buildPath(ROUTES.ONBOARDING_SURVEYS_INDEX, {}),
    iconType: 'move_money',
    roles: ['internal', 'partner'],

    bank: 'first_internet_bank',
    additionalRoutes: [
      ROUTES.RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_LIST.path,
      ROUTES.CONSUMER_ONBOARDING_SURVEYS_LIST.path,
      ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_LIST.path,
    ],
  },
  {
    name: 'Controls',
    href: buildPath(ROUTES.CONTROLS_LIST, {}),
    iconType: 'lock',
    additionalRoutes: ['/control_records'],
    roles: ['internal'],
  },
  {
    name: 'Unusual activity reports',
    href: buildPath(ROUTES.UNUSUAL_ACTIVITY_REPORTS, {}),
    additionalRoutes: ['/platform_unusual_activity_reports'],
    iconType: 'shield',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Customer Information Program Testing',
    href: '/customer_identification_program_testing_batches',
    additionalRoutes: ['/customer_identification_program_testing_entries'],
    iconType: 'user_circle',
    roles: ['internal', 'partner'],

    sectionBreak: true,
  },
  {
    name: 'User sessions',
    href: buildPath(ROUTES.USER_SESSIONS_LIST, {}),
    iconType: 'activity',
    roles: ['internal'],
  },
  {
    name: 'Partner operators',
    href: buildPath(ROUTES.PARTNERS, {}),
    iconType: 'user_circle',
    roles: ['internal', 'partner'],
  },
  {
    name: 'OAuth applications',
    href: buildPath(ROUTES.OAUTH_APPLICATION_LIST, {}),
    iconType: 'application',
    roles: ['internal'],
  },
  {
    name: 'OAuth connections',
    href: buildPath(ROUTES.OAUTH_CONNECTION_LIST, {}),
    iconType: 'key',
    roles: ['internal'],
  },
  {
    name: 'Roles',
    href: buildPath(ROUTES.ROLES_LIST, {}, { status: ['active'] }),
    iconType: 'users',
    roles: ['internal'],
  },
  {
    name: 'Users',
    href: buildPath(ROUTES.USERS_LIST, {}),
    iconType: 'user',
    roles: ['internal'],
  },
];

const QUEUES: Route[] = [
  {
    name: 'Operator manual queue items',
    href: buildPath(ROUTES.QUEUES, {}, { status: ['pending_actioning'] }),
    iconType: 'transaction',
    roles: ['internal'],
  },
  {
    name: 'Group review queue',
    href: buildPath(ROUTES.GROUP_QUEUE, {}, { status: ['pending_actioning'] }),
    iconType: 'cube',
    roles: ['internal'],
  },
  {
    name: 'Check reviewing',
    href: buildPath(ROUTES.CHECK_DEPOSIT_REVIEW_LIST, {}),
    iconType: 'check_deposit',
    roles: ['internal', 'check_reviewer'],
  },
  {
    name: 'Sanction list reviewing',
    href: buildPath(
      ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST,
      {},
      { statuses: ['pending_reviewing'], reviewer: ['increase'] }
    ),
    additionalRoutes: ['sanctions_screening_reviews'],
    iconType: 'users',
    roles: ['internal', 'check_reviewer', 'transaction_monitor'],
  },
  {
    name: 'Address reviewing',
    href: buildPath(ROUTES.ADDRESS_REVIEWING, {}),
    iconType: 'home',
    roles: ['internal', 'transaction_monitor'],
  },
  {
    name: 'Inbound mail items',
    href: buildPath(ROUTES.INBOUND_MAIL_ITEM_REVIEW_LIST, {}),
    iconType: 'checks',
    roles: ['internal', 'check_reviewer'],
  },
  {
    name: 'Identity documents',
    href: buildPath(ROUTES.IDENTITY_DOCUMENTS_LIST, {}),
    iconType: 'users',
    roles: ['internal', 'check_reviewer', 'transaction_monitor'],
  },
  {
    name: 'Transfer reviewing',
    href: buildPath(ROUTES.TRANSFER_REVIEWING, {}, { reviewer: ['increase'] }),
    additionalRoutes: [buildPath(ROUTES.TRANSFER_REVIEWING, {})],
    iconType: 'transfer',
    roles: ['internal'],
    bank: 'grasshopper_bank',
  },
  {
    name: 'Transfer reviewing',
    href: buildPath(ROUTES.TRANSFER_REVIEWING, {}),
    iconType: 'transfer',
    roles: ['partner'],
    bank: 'grasshopper_bank',
  },
  {
    name: 'Inbound check allocating',
    href: buildPath(ROUTES.CHECK_ITEM_ALLOCATING_LIST, {}),
    iconType: 'checks',
    roles: ['internal', 'check_reviewer'],
  },
  {
    name: 'Entity OFAC reviews',
    href: buildPath(
      ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST,
      {},
      { statuses: ['pending_reviewing'], record_type: ['entity'] }
    ),
    additionalRoutes: ['sanctions_screening_reviews'],
    iconType: 'users',
    roles: ['partner'],
    bank: 'grasshopper_bank',
  },
  {
    name: 'Transfer OFAC reviews',
    href: buildPath(
      ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST,
      {},
      { statuses: ['pending_reviewing'], record_type: ['transfer'] }
    ),
    iconType: 'users',
    roles: ['partner'],
    bank: 'grasshopper_bank',
  },
  {
    name: 'Sanctions tool config',
    href: buildPath(ROUTES.SANCTIONS_SCREENING_TRIGGER_WORDS_LIST, {}),
    iconType: 'settings',
    roles: ['partner', 'internal'],

    bank: 'grasshopper_bank',
  },
];

const OPERATIONS: Route[] = [
  {
    name: 'Operations',
    href: buildPath(ROUTES.OPERATIONS, {}),
    iconType: 'folder',
    roles: ['internal'],
  },
  {
    name: 'Results',
    href: buildPath(ROUTES.RESULTS, {}),
    iconType: 'copy',
    roles: ['internal'],
  },
  {
    name: 'Results V2',
    href: buildPath(
      ROUTES.RESULTS_V2,
      {},
      { suppression_status: ['false'], statuses: ['failing'] }
    ),
    iconType: 'copy',
    roles: ['internal'],
  },
  {
    name: 'Outbound ACH files',
    href: buildPath(ROUTES.OUTBOUND_ACH_FILES_LIST, {}),
    iconType: 'arrow_circle_out_right',
    roles: ['internal'],
  },
  {
    name: 'Cash reconciliation',
    href: buildPath(ROUTES.CASH_RECONCILIATION, {}),
    iconType: 'arrow_circle_out_left',
    roles: ['internal'],
  },
  {
    name: 'Manual tasks',
    href: buildPath(ROUTES.MANUAL_TASKS_LIST, {}),
    additionalRoutes: ['/manual_task_runs'],
    iconType: 'tick_circle',
    roles: ['internal'],
  },
  {
    name: 'ACH origination volume',
    href: buildPath(ROUTES.ACH_ORIGINATION_VOLUME_MONITORING, {}),
    iconType: 'table',
    roles: ['internal'],
  },
  {
    name: 'Card disputes',
    href: buildPath(ROUTES.CARD_DISPUTES_LIST, {}),
    iconType: 'card',
    roles: ['internal'],
  },
  {
    name: 'Card profile images',
    href: buildPath(ROUTES.CARD_PROFILE_IMAGES, {}),
    iconType: 'card',
    roles: ['internal'],
  },
  {
    name: 'Physical card images',
    href: buildPath(ROUTES.CARD_PRINTER_CARD_PROFILE_IMAGES, {}),
    iconType: 'card',
    roles: ['internal', 'card_printer'],
  },
  {
    name: 'Real Time Payments Prefunded Position',
    href: buildPath(ROUTES.REAL_TIME_PAYMENTS_PREFUNDED_POSITION, {}),
    iconType: 'table',
    roles: ['internal', 'partner'],
  },
  {
    name: 'All Federal Reserve limits',
    href: buildPath(ROUTES.FEDERAL_RESERVE_LIMITS_LIST, {}),
    additionalRoutes: [ROUTES.FEDERAL_RESERVE_LIMITS_LIST.path],
    iconType: 'path',
    roles: ['internal'],
  },
  {
    name: 'Fedwire messages',
    href: buildPath(ROUTES.FEDWIRE_INBOUND, {}),
    iconType: 'table',
    roles: ['internal'],
  },
  {
    name: 'Federal Reserve limits',
    href: buildPath(ROUTES.FEDERAL_RESERVE_LIMITS_LIST, {}),
    iconType: 'path',
    roles: ['partner'],

    bank: 'grasshopper_bank',
  },
  {
    name: 'Flags',
    href: buildPath(ROUTES.FLAGS_LIST, {}),
    iconType: 'fingerprint',
    roles: ['internal'],
  },
  {
    name: 'Compliance documents',
    href: buildPath(ROUTES.COMPLIANCE_DOCUMENTS, {}),
    iconType: 'folder',
    roles: ['internal', 'partner'],
  },
  {
    name: 'Obligation documents',
    href: buildPath(ROUTES.OBLIGATION_DOCUMENTS, {}),
    iconType: 'folder',
    roles: ['internal'],
  },
  {
    name: 'Attestations',
    href: buildPath(ROUTES.ATTESTATIONS_LIST, {}),
    iconType: 'percentage',
    roles: ['internal'],
  },
];

const withSectionName = (
  routes: Route[],
  section: Section
): (Route & { section: Section })[] =>
  routes.map((route) => ({ ...route, section }));

const ALL_ROUTES = [
  ...withSectionName(METRICS, 'Metrics'),
  ...withSectionName(DATA, 'Data'),
  ...withSectionName(QUEUES, 'Queues'),
  ...withSectionName(OPERATIONS, 'Operations'),
];

const ToastContext = createContext({
  toastContents: null as React.ReactNode,
  setToastContents: (_child: React.ReactNode) => {},
  toastOpen: false as boolean,
  setToastOpen: (_open: boolean) => {},
});

export const useToast = () => {
  const { toastOpen, setToastOpen, setToastContents } =
    useContext(ToastContext);
  const showToast = (child: ReactNode) => {
    setToastOpen(true);
    setToastContents(child);
  };
  const closeToast = () => {
    setToastOpen(false);
    setToastContents(null);
  };
  return { toastOpen, showToast, closeToast };
};

const ViewRawButton = () => {
  const navigate = useNavigate();
  const lastPathSegment = location.href.split('/').pop();
  const objectIdRegex = /^((([a-z]+)_)+)([a-zA-Z0-9]{20})$/;
  const objectId =
    lastPathSegment && objectIdRegex.test(lastPathSegment)
      ? lastPathSegment
      : null;

  if (!objectId) {
    return <div />;
  }

  return (
    <InternalOperatorOnly>
      {objectId && (
        <Button
          icon="code"
          style="secondary"
          size="small"
          text={`Raw`}
          onClick={() =>
            navigate(
              buildPath(
                ROUTES.OBJECT_VIEWER,
                { objectID: objectId },
                { raw: 'true' }
              )
            )
          }
          keyboardShortcut="⌘J"
        />
      )}
    </InternalOperatorOnly>
  );
};

export function Layout() {
  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const [toastContents, setToastContents] = useState<ReactNode>(null);

  const { data: operator } = useOperatorGet({});

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navigate = useNavigate();

  const sessionDelete = useSessionDelete();
  const logOut = useCallback(() => {
    sessionDelete.mutateAsync([{}]).then(() => window.location.assign('/'));
  }, [sessionDelete]);

  if (!operator) {
    return <div>Loading...</div>;
  }

  const routesVisibleToOperator = ALL_ROUTES.map((route) => ({
    ...route,
    current: location.pathname.startsWith(route.href),
  })).filter((route) => {
    const roleCanView = route.roles.includes(roleToViewer(operator.role));
    const bankCanView =
      !operator.bank || !route.bank || route.bank === operator.bank;
    return roleCanView && bankCanView;
  });

  const visibleSectionNames = uniq(
    routesVisibleToOperator.map((route) => route.section)
  );
  const visibleSections = SECTIONS.filter((section) =>
    visibleSectionNames.includes(section.title)
  );

  // Find the selected segment and route
  let selectedSegmentIdx = 0;
  let selectedRouteIdx = 0;
  ALL_ROUTES.forEach((route) => {
    const a = route.additionalRoutes
      ? [route.href, ...route.additionalRoutes]
      : [route.href];
    const allRoutesWithoutQueries = a.map((r) => r.split('?')[0]);
    const anyRouteMatches = allRoutesWithoutQueries.some((r) =>
      location.pathname.startsWith(r)
    );
    if (anyRouteMatches) {
      selectedSegmentIdx = visibleSections.findIndex(
        (segment) => segment.title === route.section
      );
      const routesInThisSegment = routesVisibleToOperator.filter(
        (r) => r.section === visibleSections[selectedSegmentIdx].title
      );
      selectedRouteIdx = routesInThisSegment.findIndex(
        (r) => r.name === route.name
      );
    }
  });

  const routesInCurrentSegment = routesVisibleToOperator.filter(
    (route) => route.section === visibleSections[selectedSegmentIdx].title
  );

  const routeSegments: HrefSegment[] = routesInCurrentSegment.map((route) => ({
    href: route.href,
    label: route.name,
    icon: route.iconType,
    sectionBreak: route.sectionBreak,
  }));

  const cleanSegments = visibleSections.map((s, i) => {
    const firstRouteInThisSection = routesVisibleToOperator.find(
      (r) => r.section === s.title
    );
    return {
      title: s.title,
      href: firstRouteInThisSection?.href || '/',
      selected: i === selectedSegmentIdx,
    };
  });

  const bankIconString = getBankIconString(operator.bank);
  return (
    <ModalContainer searchOpen={false} setSearchOpen={() => {}}>
      <ToastContext.Provider
        value={{
          toastContents,
          toastOpen,
          setToastOpen,
          setToastContents,
        }}
      >
        <div className="h-screen overflow-hidden bg-main">
          <div className="flex w-full items-center space-x-4 border-b border-main bg-subtle px-5 py-4">
            <Menu
              button={
                <div className="h-7 w-7 shrink-0 cursor-pointer overflow-hidden rounded">
                  <img
                    className="h-auto w-full"
                    alt="Bank Icon"
                    src={bankIconString}
                  />
                </div>
              }
              align="left"
            >
              <MenuLayout
                items={[
                  {
                    key: 'identity',
                    icon: 'user',
                    textColor: 'secondary',
                    title: operator.email,
                  },
                  {
                    key: 'signout',
                    icon: 'signout',
                    textColor: 'primary',
                    title: 'Sign out',
                    onClick: logOut,
                  },
                ]}
              />
            </Menu>
            <div className="hidden lg:block">
              <SegmentedControl segments={cleanSegments} style="ghost" />
            </div>
            <div className="grow" />
            <ViewRawButton />
            <TextButton
              icon="search"
              text="Search"
              onClick={() => navigate('/search')}
            />

            <TextButton
              icon={sidebarOpen ? 'close' : 'menu'}
              className={classNames('lg:hidden', 'transition-all')}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            />
          </div>

          <div className="flex h-full">
            {/* Desktop menu, hidden on mobile */}
            <div className="hidden h-full w-[260px] shrink-0 space-y-8 overflow-y-scroll border-r border-main px-4 pb-16 pt-8 lg:block">
              <SidebarSegmentedControl
                segments={routeSegments}
                selectedIndex={selectedRouteIdx}
              />
            </div>

            {/* Mobile menu, hidden on desktop */}
            <div
              className={classNames(
                'absolute inset-y-0 z-30 h-full w-[calc(100%-3rem)] max-w-xs space-y-6 overflow-y-scroll border-r border-main bg-main px-4 pb-16 pt-4 shadow-lg transition-all lg:hidden',
                sidebarOpen ? '' : '-translate-x-full'
              )}
            >
              <SegmentedControl segments={cleanSegments} />
              <SidebarSegmentedControl
                segments={routeSegments}
                selectedIndex={selectedRouteIdx}
              />
            </div>

            {/* Backdrop behind mobile menu */}
            <Transition
              show={sidebarOpen}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className={classNames('fixed inset-0 z-20 bg-canvas/50')}
                onClick={() => {
                  if (sidebarOpen) {
                    setSidebarOpen(false);
                  }
                }}
              />
            </Transition>

            <div className="relative h-[calc(100vh-57px)] grow overflow-scroll">
              <Outlet />
              {toastOpen && (
                <div className="absolute inset-x-0 bottom-0 z-50 border-t border-main bg-subtle px-8 pb-12 pt-8">
                  {toastContents}
                </div>
              )}
            </div>
          </div>
        </div>
      </ToastContext.Provider>
    </ModalContainer>
  );
}
