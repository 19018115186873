import React from 'react';
import { useCardPaymentGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';

import { entries } from 'lodash';
import { formatAmount, formatISO8601Timestamp } from 'shared/lib/formatting';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { PropertyList } from 'shared/components/PropertyList';

export const GroupCardPaymentDetail = () => {
  const { cardPaymentID } = useTypedParams(ROUTES.CARD_PAYMENT);
  const { data: cardPayment } = useCardPaymentGet(cardPaymentID);

  if (!cardPayment) {
    return <div>Loading...</div>;
  }

  return (
    <PageLayout headline={cardPayment.id}>
      <Section header="Details">
        <PropertyList
          items={[
            {
              label: 'Account',
              value: cardPayment.account_id,
              href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
                accountId: cardPayment.account_id,
              }),
            },
            {
              label: 'Card',
              value: cardPayment.card_id,
              href: buildPath(ROUTES.CARD, {
                cardID: cardPayment.card_id,
              }),
            },
            {
              label: 'Created at',
              value: formatISO8601Timestamp(cardPayment.created_at, 'full'),
            },
          ]}
        />
      </Section>
      <Section header="State">
        <PropertyList
          items={entries(cardPayment.state).map(([key, amount]) => ({
            value: formatAmount(amount, 'USD'),
            label: key,
          }))}
        />
      </Section>
    </PageLayout>
  );
};
