import React from 'react';
import { PageLayout } from './deprecated/PageLayout';
import { RealTimePaymentsPrefundedPositionOverviewGetBank } from 'src/build/operations';
import {
  useOperatorGet,
  useRealTimePaymentsPrefundedPositionOverviewGet,
} from 'src/hooks/reactQuery';
import { Table } from './deprecated/Table';

import { PropertyList, PropertyListItem } from './deprecated/PropertyList';
import { Section } from './deprecated/Section';
import { formatAmount } from 'shared/lib/formatting';

import { StyledLink } from './deprecated/StyledLink';
import { buildPath, ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { Select, SelectSection } from 'shared/components/Select';

const DEFAULT_BANK = 'first_internet_bank';

export const RealTimePaymentsPrefundedPosition = () => {
  const [bank, setBank] = useSearchParamsState(
    ROUTES.REAL_TIME_PAYMENTS_PREFUNDED_POSITION,
    'bank'
  );
  const { data: operator } = useOperatorGet({});

  const bankToSearch = bank || operator?.bank || DEFAULT_BANK;
  const { data } = useRealTimePaymentsPrefundedPositionOverviewGet({
    bank: bankToSearch,
  });

  if (!data || !operator) {
    return <div>Loading</div>;
  }

  const showBankPicker = !operator.bank;

  const sections: SelectSection<RealTimePaymentsPrefundedPositionOverviewGetBank>[] =
    [
      {
        options: Object.values(
          RealTimePaymentsPrefundedPositionOverviewGetBank
        ).map((b) => ({
          label: b,
          value: b,
        })),
      },
    ];

  return (
    <PageLayout headline="Real-Time Payments prefunded position">
      {showBankPicker ? (
        <div>
          <b>Bank:</b>
          <Select
            value={bankToSearch}
            disabled={operator.bank !== null}
            onUpdate={(value) => {
              setBank(value);
            }}
            sections={sections}
          />
        </div>
      ) : (
        <div>{bank}</div>
      )}

      <PropertyList>
        <PropertyListItem label="Participant ID">
          {data.participant_id}
        </PropertyListItem>
        <PropertyListItem label="Prefunded position">
          {formatAmount(data.prefunded_position.current, 'USD')}
        </PropertyListItem>
        <PropertyListItem label="Target">
          {formatAmount(data.prefunded_position.target, 'USD')}
        </PropertyListItem>
        {data.prefunded_position.latest_baseline && (
          <>
            <PropertyListItem label="Latest reported position">
              {formatAmount(
                data.prefunded_position.latest_baseline.amount,
                'USD'
              )}{' '}
              (<span> </span>
              {data.prefunded_position.latest_baseline.effective_at}{' '}
              <span className="ml-1">
                <StyledLink
                  to={buildPath(ROUTES.OBJECT_VIEWER, {
                    objectID: data.prefunded_position.latest_baseline.source_id,
                  })}
                >
                  {data.prefunded_position.latest_baseline.source_id}
                </StyledLink>
              </span>
              )
            </PropertyListItem>
            <PropertyListItem label="Transfers since then">
              <ul>
                <li>
                  Sent:{' '}
                  {
                    data.prefunded_position.latest_baseline.summary_since_then
                      .count_sent
                  }{' '}
                  for{' '}
                  {formatAmount(
                    data.prefunded_position.latest_baseline.summary_since_then
                      .amount_sent,
                    'USD'
                  )}{' '}
                </li>
                <li>
                  Received:{' '}
                  {
                    data.prefunded_position.latest_baseline.summary_since_then
                      .count_received
                  }{' '}
                  for{' '}
                  {formatAmount(
                    data.prefunded_position.latest_baseline.summary_since_then
                      .amount_received,
                    'USD'
                  )}{' '}
                </li>
              </ul>
            </PropertyListItem>
          </>
        )}
      </PropertyList>

      <Section header="Recent supplemental fundings">
        <Table
          data={data.supplemental_fundings}
          columns={[
            {
              key: 'id',
              label: 'Id',
            },
            {
              key: 'created_at',
              label: 'Created at',
              render: (funding) => funding.created_at,
            },
            {
              key: 'status',
              label: 'Status',
            },
            {
              key: 'amount',
              label: 'Amount',
              render: (funding) => formatAmount(funding.amount, 'USD'),
            },
            {
              key: 'wire_transfer_id',
              label: 'Wire Transfer',
            },
          ]}
        />
      </Section>
    </PageLayout>
  );
};
