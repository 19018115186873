import {
  useAccountListInfinite,
  useEntityGet,
  useGroupGet,
  useOperatorGet,
  useProgramCreditExposureGet,
  useProgramGet,
} from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from 'shared/components/SideBySide';
import { Loading } from 'shared/components/Loading';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { AccountsTable } from '../tables/AccountsTable';
import { ProgramPropertyList } from '../property-lists/ProgramPropertyList';
import { AlertList } from 'shared/components/AlertList';
import { ProgramTransferSettingsPropertyList } from '../property-lists/ProgramTransferSettingsPropertyList';
import { ProgramReportingConfigurationPropertyList } from '../property-lists/ProgramReportingConfigurationPropertyList';
import { formatAmount, formatInteger, humanize } from 'shared/lib/formatting';
import { InternalOperatorOnly } from '../internal-operator-only';
import { StackOfLinks } from 'shared/components/StackOfLinks';
import { AssociatedManualTasks } from '../associated-manual-tasks';
import { PropertyList } from 'shared/components/PropertyList';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { ProgramTransactionLimitsPropertyList } from '../property-lists/ProgramTransactionLimitsPropertyList';
import { ProgramGetResponse } from 'src/build/operations';
import { ProgramCardTransactionLimits } from '../property-lists/ProgramCardTransactionLimits';
import { FirstInternetBankProgramTransactionLimitsPropertyList } from '../property-lists/FirstInternetBankProgramTransactionLimitsPropertyList';
import { UnreleasedCreditExposureTransactionSummariesTable } from '../tables/UnreleasedCreditExposureTransactionSummariesTable';

const ForBenefitOfEntityDetails = (props: { entityId: string }) => {
  const { data } = useEntityGet(props.entityId);

  if (!data) {
    return <Loading />;
  }

  return (
    <AlertList
      tasks={[
        {
          icon: 'info',
          title: `${data.name} • For benefit of`,
          key: '0',
          body: "This is an FBO program. All of the funds associated with this program are owned by for the benefit of the platform's customers.",
          style: 'informational',
          link: buildPath(ROUTES.ENTITY, {
            entityId: props.entityId,
          }),
        },
      ]}
    />
  );
};

const CreditExposureDetails = (props: { program: ProgramGetResponse }) => {
  const { data: creditExposure } = useProgramCreditExposureGet(
    props.program.id
  );

  if (!creditExposure) {
    return <Loading />;
  }

  return (
    <>
      <PropertyList
        title="Credit exposure"
        items={[
          {
            label: 'Inbound funds hold policy',
            value: humanize(props.program.inbound_funds_hold_policy),
          },
          {
            label: '[A] Credit exposure limit',
            value: formatAmount(props.program.credit_exposure_limit, 'USD'),
          },
          {
            label: '[B] Current credit exposure',
            value: formatAmount(
              creditExposure.usd_current_credit_exposure,
              'USD'
            ),
          },
          {
            label: '[C] Current balance',
            value: formatAmount(creditExposure.usd_current_balance, 'USD'),
          },
          {
            label: '[D] Pending balance',
            value: formatAmount(creditExposure.usd_pending_balance, 'USD'),
          },
          {
            label: 'Equation',
            value: 'Available = min(A - B, C + D)',
          },
          {
            label: 'Available amount user can withdraw',
            value: formatAmount(
              creditExposure.usd_credit_exposure_balance,
              'USD'
            ),
          },
        ]}
      />
      <TableStateWrapper
        table={UnreleasedCreditExposureTransactionSummariesTable}
        data={creditExposure.unreleased_summaries.map((x) => ({
          ...x,
          id: `${x.source_type}-${x.releases_at}`,
        }))}
        hasNextPage={false}
        error={null}
        style="detail"
        title="Unreleased Credit Exposure"
        emptyTitle="No unreleased credit exposure transactions"
        emptySubtitle=""
        viewMoreHref=""
      />
    </>
  );
};

export const ProgramDetailPage = () => {
  const { programId } = useTypedParams(ROUTES.PROGRAMS_DETAIL);
  const { data: program } = useProgramGet(programId);
  const { data: operator } = useOperatorGet({});
  const { data: group } = useGroupGet(program?.group_id || '', {
    enabled: !!program,
  });

  const accountsList = useAccountListInfinite({
    program_ids: [programId],
    limit: 5,
  });

  if (!program || !group || !operator) {
    return <Loading />;
  }

  const operatorIsInternalOrGrasshopper =
    operator.role === 'internal' || operator.bank === 'grasshopper_bank';
  const operatorIsFirstInternetBank = operator.bank === 'first_internet_bank';

  return (
    <PageLayout headline={program.name}>
      <SideBySide
        mode="rightDrawer"
        right={
          <>
            <ProgramPropertyList
              program={program}
              layoutHorizontallyWhenPossible={false}
              title="Summary"
            />
            <InternalOperatorOnly>
              <StackOfLinks
                links={[
                  {
                    href: buildPath(ROUTES.GROUP_PROGRAM_FEE_PLANS, {
                      groupID: program.group_id,
                      programID: program.id,
                    }),
                    text: 'Fee plans',
                  },
                  {
                    href: buildPath(ROUTES.DOCUMENT_REQUEST_SCHEDULE, {
                      programId: program.id,
                    }),
                    text: 'Document request schedule',
                  },
                  {
                    href: buildPath(
                      ROUTES.CONTROLS_LIST,
                      {},
                      { program: [program.id] }
                    ),
                    text: 'Controls',
                  },
                ]}
              />
            </InternalOperatorOnly>
            <AssociatedManualTasks objectId={programId} />
          </>
        }
        left={
          <>
            {program.for_benefit_of_entity_id && (
              <ForBenefitOfEntityDetails
                entityId={program.for_benefit_of_entity_id}
              />
            )}
            <TableStateWrapper
              table={AccountsTable}
              {...accountsList}
              style="detail"
              title={`Accounts • ${formatAmount(program.sum_balances, 'USD')}`}
              emptyTitle="No accounts"
              emptySubtitle="There are no accounts to display"
              viewMoreHref={buildPath(
                ROUTES.ACCOUNTS_LIST,
                {},
                { program: [programId] }
              )}
              showBank={false}
              showProgram={false}
              showGroup={false}
            />
            <ProgramReportingConfigurationPropertyList
              title="Compliance reporting"
              program={program}
            />
            <ProgramTransferSettingsPropertyList
              title="Transfer settings"
              program={program}
            />
            <InternalOperatorOnly>
              <PropertyList
                title="Route count limits"
                items={[
                  {
                    label: 'Maximum account numbers',
                    value: program.maximum_account_number_count
                      ? formatInteger(program.maximum_account_number_count)
                      : 'Not set, using default value.',
                  },
                  {
                    label: 'Maximum cards',
                    value: program.maximum_card_count
                      ? formatInteger(program.maximum_card_count)
                      : 'Not set, using default value.',
                  },
                  {
                    label: 'Maximum physical cards',
                    value: program.maximum_physical_card_count
                      ? formatInteger(program.maximum_physical_card_count)
                      : 'Not set, using default value.',
                  },
                ]}
              />
            </InternalOperatorOnly>

            {operatorIsInternalOrGrasshopper && (
              <ProgramTransactionLimitsPropertyList program={program} />
            )}

            {operatorIsFirstInternetBank && (
              <FirstInternetBankProgramTransactionLimitsPropertyList
                program={program}
              />
            )}

            <InternalOperatorOnly>
              <ProgramCardTransactionLimits
                program={program}
                title="Card limits"
              />
            </InternalOperatorOnly>

            {operator.role === 'internal' ? (
              <CreditExposureDetails program={program} />
            ) : (
              <PropertyList
                title="Credit exposure"
                items={[
                  {
                    label: 'Inbound funds hold policy',
                    value: humanize(program.inbound_funds_hold_policy),
                  },
                ]}
              />
            )}
          </>
        }
      />
    </PageLayout>
  );
};
