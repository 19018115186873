import { compact } from 'lodash';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import {
  OperatorGetResponse,
  OperatorGetResponseRole,
  ProgramGetResponse,
} from 'src/build/operations';
import { useOperatorGet, useProgramLimitsPatch } from 'src/hooks/reactQuery';

export type ProgramTransactionLimitsPropertyListProps = {
  program: ProgramGetResponse;
} & Omit<PropertyListProps, 'items'>;

const CAN_CHANGE_LIMITS: OperatorGetResponseRole[] = [
  OperatorGetResponseRole.limit_approver,
];

const operatorCanEditLimits = (operator: OperatorGetResponse) => {
  if (operator.role === 'internal') {
    return true;
  }
  if (operator.bank === 'grasshopper_bank') {
    return CAN_CHANGE_LIMITS.includes(operator.role);
  }

  return false;
};

export const ProgramTransactionLimitsPropertyList = (
  props: ProgramTransactionLimitsPropertyListProps
) => {
  const { program, ...rest } = props;

  const { data: operator } = useOperatorGet({});
  const programLimitsPatch = useProgramLimitsPatch();

  const canEditLimits = operator && operatorCanEditLimits(operator);

  return (
    <PropertyList
      title="Transfer limits"
      {...rest}
      items={compact<ListItem>([
        {
          label: 'Combined Rolling 24-Hour Transfer Volume Limit',
          value: program.usd_combined_rolling_24_hour_transfer_volume_limit,
          currency: 'USD',
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                combined_transfers_automated_rolling_24_hour_volume_limit:
                  newValue,
              },
            ]);
          },
          disabled: !canEditLimits,
        },
        {
          label: 'ACH Debit Rolling 24-Hour Limit',
          value:
            program.usd_ach_debit_transfers_automated_rolling_24_hour_volume_limit,
          currency: 'USD',
          optional: true,
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                ach_debit_transfers_automated_rolling_24_hour_volume_limit:
                  newValue,
              },
            ]);
          },
          onRemove: async () => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                unset_ach_debit_transfers_automated_rolling_24_hour_volume_limit:
                  true,
              },
            ]);
          },
          disabled: !canEditLimits,
        },
        {
          label: 'ACH Credit Rolling 24-Hour Limit',
          value:
            program.usd_ach_credit_transfers_automated_rolling_24_hour_volume_limit,
          currency: 'USD',
          optional: true,
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                ach_credit_transfers_automated_rolling_24_hour_volume_limit:
                  newValue,
              },
            ]);
          },
          onRemove: async () => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                unset_ach_credit_transfers_automated_rolling_24_hour_volume_limit:
                  true,
              },
            ]);
          },
          disabled: !canEditLimits,
        },
        {
          label: 'Check Transfers Rolling 24-Hour Limit',
          value:
            program.usd_check_transfers_automated_rolling_24_hour_volume_limit,
          currency: 'USD',
          optional: true,
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                check_transfers_automated_rolling_24_hour_volume_limit:
                  newValue,
              },
            ]);
          },
          onRemove: async () => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                unset_check_transfers_automated_rolling_24_hour_volume_limit:
                  true,
              },
            ]);
          },
          disabled: !canEditLimits,
        },
        {
          label: 'Check Deposits Rolling 24-Hour Limit',
          value:
            program.usd_check_deposits_automated_rolling_24_hour_volume_limit,
          currency: 'USD',
          optional: true,
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                check_deposits_automated_rolling_24_hour_volume_limit: newValue,
              },
            ]);
          },
          onRemove: async () => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                unset_check_deposits_automated_rolling_24_hour_volume_limit:
                  true,
              },
            ]);
          },
          disabled: !canEditLimits,
        },

        {
          label: 'Real-Time Payments Transfers Rolling 24-Hour Limit',
          value:
            program.usd_real_time_payments_automated_rolling_24_hour_volume_limit,
          currency: 'USD',
          optional: true,
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                real_time_payments_automated_rolling_24_hour_volume_limit:
                  newValue,
              },
            ]);
          },
          onRemove: async () => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                unset_real_time_payments_automated_rolling_24_hour_volume_limit:
                  true,
              },
            ]);
          },
          disabled: !canEditLimits,
        },
        {
          label: 'Wire Transfers Rolling 24-Hour Limit',
          value:
            program.usd_wire_transfers_automated_rolling_24_hour_volume_limit,
          currency: 'USD',
          optional: true,
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                wire_transfers_automated_rolling_24_hour_volume_limit: newValue,
              },
            ]);
          },
          onRemove: async () => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                unset_wire_transfers_automated_rolling_24_hour_volume_limit:
                  true,
              },
            ]);
          },
          disabled: !canEditLimits,
        },
        {
          label: 'Per ACH Debit Transfer Limit',
          value: program.usd_per_ach_debit_transfer_limit,
          currency: 'USD',
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              { ach_debit_transfers_automated_limit_per_transfer: newValue },
            ]);
          },
          disabled: !canEditLimits,
        },
        {
          label: 'Per ACH Credit Transfer Limit',
          value: program.usd_per_ach_credit_transfer_limit,
          currency: 'USD',
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              { ach_credit_transfers_automated_limit_per_transfer: newValue },
            ]);
          },
          disabled: !canEditLimits,
        },
        {
          label: 'Per Check Transfer Limit',
          value: program.usd_per_check_transfer_limit,
          currency: 'USD',
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              { check_transfers_automated_limit_per_transfer: newValue },
            ]);
          },
          disabled: !canEditLimits,
        },
        {
          label: 'Per Check Deposit Limit',
          value: program.usd_per_check_deposit_limit,
          currency: 'USD',
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              { check_deposits_automated_limit_per_transfer: newValue },
            ]);
          },
          disabled: !canEditLimits,
        },
        {
          label: 'Per Real-Time Payments Transfer Limit',
          value: program.usd_per_real_time_payments_transfer_limit,
          currency: 'USD',
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                real_time_payments_transfers_automated_limit_per_transfer:
                  newValue,
              },
            ]);
          },
          disabled: !canEditLimits,
        },
        {
          label: 'Per Wire Transfer Limit',
          value: program.usd_per_wire_transfer_limit,
          currency: 'USD',
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              { wire_transfers_automated_limit_per_transfer: newValue },
            ]);
          },
          disabled: !canEditLimits,
        },
        {
          label: 'Inbound Transfers Rolling 24-Hour Volume Limit',
          value:
            program.usd_combined_inbound_automated_rolling_24_hour_volume_limit,
          optional: true,
          currency: 'USD',
          onEdit: async (newValue: number) => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                combined_inbound_automated_limit_rolling_24_hour_volume:
                  newValue,
              },
            ]);
          },
          onRemove: async () => {
            return programLimitsPatch.mutateAsync([
              program.id,
              {
                unset_combined_inbound_automated_limit_rolling_24_hour_volume:
                  true,
              },
            ]);
          },
          disabled: !canEditLimits,
        },
      ])}
    />
  );
};
