import React from 'react';
import { Dialog } from '@headlessui/react';
import { Section } from './deprecated/Section';
import { Badge } from './deprecated/Badge';
import { PageLayout } from './deprecated/PageLayout';
import { Table } from './deprecated/Table';
import { TextInput } from './deprecated/TextInput';
import { Button } from './deprecated/Button';
import { useState } from 'react';
import {
  useDigitalWalletImageFileList,
  usePhysicalCardImageFileList,
  useDigitalWalletImageFileApprove,
  usePhysicalCardImageFileApprove,
  useDigitalWalletImageFileReject,
  usePhysicalCardImageFileReject,
} from '../hooks/reactQuery';
import { useServices } from '../hooks/use-services';
import {
  DigitalWalletImageFileListResponseDataItemStatus,
  PhysicalCardImageFileListResponseDataItemStatus,
  DigitalWalletImageFileListResponseDataItemImageType,
} from '../build/operations/operations.schemas';
import { absurd } from 'src/lib/absurd';
import classNames from 'classnames';
import { AlertList } from 'shared/components/AlertList';

const LIMIT = 4;

const ObjectLink = ({ id }: { id: string }) => (
  <a href={`https://administration.increase.com/object/${id}`}>{id}</a>
);

const StoreVisaDetailsButton = ({
  digitalWalletImageFileId,
}: {
  digitalWalletImageFileId: string;
}) => {
  const [modalShowing, setModalShowing] = useState(false);
  const approveDigitalWalletImageFile = useDigitalWalletImageFileApprove();
  const [newVisaIdentifier, setNewVisaIdentifier] = useState('');
  const submit = () => {
    approveDigitalWalletImageFile
      .mutateAsync([
        digitalWalletImageFileId,
        { visa_identifier: newVisaIdentifier },
      ])
      .then(() => {
        setModalShowing(false);
      });
  };

  return (
    <>
      <Button
        data-testid="showModalButton"
        className="bg-success p-2"
        onClick={() => setModalShowing(true)}
      >
        Approve
      </Button>

      <Dialog
        open={modalShowing}
        onClose={() => setModalShowing(false)}
        className={'relative z-50'}
      >
        <div className="fixed inset-0 flex items-center justify-center bg-inverse/50 p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-main p-4">
            <Dialog.Title>Store Visa details</Dialog.Title>
            <p className="my-2 text-base text-subtle">
              Make sure the background image has a Visa logo in it, as described
              in the{' '}
              <a
                className="text-info"
                href="https://paper.dropbox.com/doc/Card-profile-image-reviewing--B9UtG~~3J96ZUSbjm~IdKlSoAg-FPZKUuzitz1QBygwHMSjh"
              >
                review instructions
              </a>
              .
            </p>
            <div>
              <TextInput
                id="application.cardProfileImages.visaIdentifier"
                name="visa_identifier"
                value={newVisaIdentifier}
                placeholder="Visa identifier"
                onChange={(s: string) => setNewVisaIdentifier(s)}
              />
              {approveDigitalWalletImageFile.error && (
                <AlertList
                  tasks={[
                    {
                      icon: 'info',
                      title: 'Error',
                      body: approveDigitalWalletImageFile.error.response?.data
                        .message,
                      key: '0',
                      style: 'error',
                    },
                  ]}
                />
              )}
            </div>
            <div className="mt-2 flex items-center space-x-2">
              <Button
                onClick={() => setModalShowing(false)}
                disabled={approveDigitalWalletImageFile.isLoading}
              >
                Cancel
              </Button>
              <Button
                onClick={submit}
                disabled={
                  approveDigitalWalletImageFile.isLoading || !newVisaIdentifier
                }
                className="bg-success"
              >
                Confirm
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

const ApprovePhysicalCardImageButton = ({
  physicalCardImageFileId,
}: {
  physicalCardImageFileId: string;
}) => {
  const [modalShowing, setModalShowing] = useState(false);
  const approvePhysicalCardImageFile = usePhysicalCardImageFileApprove();
  const submit = () => {
    approvePhysicalCardImageFile
      .mutateAsync([physicalCardImageFileId])
      .then(() => {
        setModalShowing(false);
      });
  };

  return (
    <>
      <Button
        data-testid="showModalButton"
        className="bg-success p-2"
        onClick={() => setModalShowing(true)}
      >
        Approve
      </Button>

      <Dialog
        open={modalShowing}
        onClose={() => setModalShowing(false)}
        className={'relative z-50'}
      >
        <div className="fixed inset-0 flex items-center justify-center bg-inverse/50 p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-main p-4">
            <Dialog.Title>Approve</Dialog.Title>
            <div>
              {approvePhysicalCardImageFile.error && (
                <AlertList
                  tasks={[
                    {
                      icon: 'info',
                      title: 'Error',
                      body: approvePhysicalCardImageFile.error.response?.data
                        .message,
                      key: '0',
                      style: 'error',
                    },
                  ]}
                />
              )}
            </div>
            <div className="mt-2 flex items-center space-x-2">
              <Button
                onClick={() => setModalShowing(false)}
                disabled={approvePhysicalCardImageFile.isLoading}
              >
                Cancel
              </Button>
              <Button
                onClick={submit}
                disabled={approvePhysicalCardImageFile.isLoading}
                className="bg-success"
              >
                Confirm
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

const RejectImageButton = ({
  rejecter,
  id,
}: {
  rejecter: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  id: string;
}) => {
  const [modalShowing, setModalShowing] = useState(false);
  const submit = () => {
    rejecter.mutateAsync([id]).then(() => {
      setModalShowing(false);
    });
  };

  return (
    <>
      <Button
        data-testid="showModalButton"
        className="mx-2 bg-danger p-2"
        onClick={() => setModalShowing(true)}
      >
        Reject
      </Button>

      <Dialog
        open={modalShowing}
        onClose={() => setModalShowing(false)}
        className={'relative z-50'}
      >
        <div className="fixed inset-0 flex items-center justify-center bg-inverse/50 p-4">
          <Dialog.Panel className="w-full max-w-sm rounded bg-main p-4">
            <Dialog.Title>Reject</Dialog.Title>
            <div>
              {rejecter.error && (
                <AlertList
                  tasks={[
                    {
                      icon: 'info',
                      title: 'Error',
                      body: rejecter.error.response?.data.message,
                      key: '0',
                      style: 'error',
                    },
                  ]}
                />
              )}
            </div>
            <div className="mt-2 flex items-center space-x-2">
              <Button
                onClick={() => setModalShowing(false)}
                disabled={rejecter.isLoading}
              >
                Cancel
              </Button>
              <Button
                onClick={submit}
                disabled={rejecter.isLoading}
                className="bg-danger"
              >
                Confirm
              </Button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

const DigitalWalletImages = () => {
  const [page, setPage] = useState(0);
  const { operations } = useServices();
  const rejectDigitalWalletImageFile = useDigitalWalletImageFileReject();
  const { data } = useDigitalWalletImageFileList({
    offset: page * LIMIT,
    limit: LIMIT,
  });

  if (!data) {
    return <h3>Loading</h3>;
  }

  return (
    <Section header="Digital wallet images">
      <Table
        data={data.data}
        columns={[
          {
            key: 'status',
            label: 'Status',
            render: (record) => {
              switch (record.status) {
                case DigitalWalletImageFileListResponseDataItemStatus.pending:
                  return (
                    <Badge className="mx-5" color="yellow">
                      Pending
                    </Badge>
                  );
                case DigitalWalletImageFileListResponseDataItemStatus.rejected:
                  return (
                    <Badge className="mx-5" color="red">
                      Rejected
                    </Badge>
                  );
                case DigitalWalletImageFileListResponseDataItemStatus.active:
                  return (
                    <Badge className="mx-2" color="green">
                      Active
                    </Badge>
                  );
                default:
                  absurd(record.status);
              }
            },
          },
          {
            key: 'id',
            label: 'ID',
            render: (record) => <ObjectLink id={record.id} />,
          },
          {
            key: 'image_type',
            label: 'Image type',
          },
          {
            key: 'card_profiles',
            label: 'Card profiles',
            render: (record) => (
              <ul>
                {record.card_profiles.map((cardProfile) => (
                  <li key={cardProfile.id} className="px-5">
                    <ObjectLink id={cardProfile.id} />{' '}
                    <em>({cardProfile.status})</em>
                  </li>
                ))}
              </ul>
            ),
          },
          {
            key: 'image',
            label: 'Image',
            render: (record) => {
              const url = `${operations}/api_files/${record.api_file_id}/view`;
              return (
                <a href={url}>
                  <img
                    alt={record.id}
                    className={classNames(
                      `border border-strong shadow-md`,
                      record.image_type ===
                        DigitalWalletImageFileListResponseDataItemImageType.background
                        ? `w-48`
                        : `w-8`
                    )}
                    src={url}
                  />
                </a>
              );
            },
          },
          {
            key: 'actions',
            label: 'Actions',
            render: (record) =>
              record.status === 'pending' ? (
                <>
                  <StoreVisaDetailsButton
                    digitalWalletImageFileId={record.id}
                  />
                  <RejectImageButton
                    rejecter={rejectDigitalWalletImageFile}
                    id={record.id}
                  />
                </>
              ) : (
                'No action'
              ),
          },
        ]}
      />
      <div className="my-5 flex flex-row gap-8">
        <div>Page {page + 1}</div>
        <div>
          <Button disabled={page === 0} onClick={() => setPage(page - 1)}>
            Previous Page
          </Button>
          <Button
            className="mx-2"
            disabled={!data || data.data.length < LIMIT}
            onClick={() => setPage(page + 1)}
          >
            Next Page
          </Button>
        </div>
      </div>
    </Section>
  );
};

const PhysicalCardImages = () => {
  const [page, setPage] = useState(0);
  const { operations } = useServices();
  const rejectPhysicalCardImageFile = usePhysicalCardImageFileReject();
  const { data } = usePhysicalCardImageFileList({
    offset: page * LIMIT,
    limit: LIMIT,
  });

  if (!data) {
    return <h3>Loading</h3>;
  }

  return (
    <Section header="Physical card images">
      <Table
        data={data.data}
        columns={[
          {
            key: 'status',
            label: 'Status',
            render: (record) => {
              switch (record.status) {
                case PhysicalCardImageFileListResponseDataItemStatus.pending_reviewing:
                  return (
                    <Badge className="mx-5" color="yellow">
                      Pending reviewing
                    </Badge>
                  );
                case PhysicalCardImageFileListResponseDataItemStatus.pending_submitting:
                  return (
                    <Badge className="mx-5" color="yellow">
                      Pending submitting
                    </Badge>
                  );
                case PhysicalCardImageFileListResponseDataItemStatus.rejected:
                  return (
                    <Badge className="mx-5" color="red">
                      Rejected
                    </Badge>
                  );
                case PhysicalCardImageFileListResponseDataItemStatus.submitted:
                  return (
                    <Badge className="mx-2" color="green">
                      Active
                    </Badge>
                  );
                default:
                  absurd(record.status);
              }
            },
          },
          {
            key: 'id',
            label: 'ID',
            render: (record) => <ObjectLink id={record.id} />,
          },
          {
            key: 'image_type',
            label: 'Image type',
          },
          {
            key: 'card_profiles',
            label: 'Card profiles',
            render: (record) => (
              <ul>
                {record.card_profiles.map((cardProfile) => (
                  <li key={cardProfile.id} className="px-5">
                    <ObjectLink id={cardProfile.id} />{' '}
                    <em>({cardProfile.status})</em>
                  </li>
                ))}
              </ul>
            ),
          },
          {
            key: 'image',
            label: 'Image',
            render: (record) => {
              const url = `${operations}/api_files/${record.transformed_api_file_id}/view`;
              return (
                <a href={url}>
                  <img
                    alt={record.id}
                    className="w-48 border border-strong shadow-md"
                    src={url}
                  />
                </a>
              );
            },
          },
          {
            key: 'actions',
            label: 'Actions',
            render: (record) =>
              record.status === 'pending_reviewing' ? (
                <>
                  <ApprovePhysicalCardImageButton
                    physicalCardImageFileId={record.id}
                  />
                  <RejectImageButton
                    rejecter={rejectPhysicalCardImageFile}
                    id={record.id}
                  />
                </>
              ) : (
                'No action'
              ),
          },
        ]}
      />
      <div className="my-5 flex flex-row gap-8">
        <div>Page {page + 1}</div>
        <div>
          <Button disabled={page === 0} onClick={() => setPage(page - 1)}>
            Previous Page
          </Button>
          <Button
            className="mx-2"
            disabled={!data || data.data.length < LIMIT}
            onClick={() => setPage(page + 1)}
          >
            Next Page
          </Button>
        </div>
      </div>
    </Section>
  );
};

export const CardProfileImages = () => {
  return (
    <PageLayout
      headline={
        <span>
          Card profile images{' '}
          <a
            className="text-subtle"
            href="https://paper.dropbox.com/doc/Card-profile-image-reviewing--B9UtG~~3J96ZUSbjm~IdKlSoAg-FPZKUuzitz1QBygwHMSjh"
          >
            (instructions)
          </a>
        </span>
      }
    >
      <>
        <DigitalWalletImages />
        <PhysicalCardImages />
      </>
    </PageLayout>
  );
};
