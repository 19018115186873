import { PageLayout } from '../deprecated/PageLayout';
import { SegmentedControl } from 'shared/components/SegmentedControl';
import { Outlet, useLocation } from 'react-router';
import { buildPath, ROUTES } from 'src/lib/routes';
import { useOperatorGet } from 'src/hooks/reactQuery';
import { compact } from 'lodash';
import { Box } from 'shared/components/Box';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { Select } from 'shared/components/Select';
import { DateInput } from 'shared/components/DateInput';
import { ManagementInformationSystemBalancesListPeriod } from 'src/build/operations';

const options = [
  {
    value: ManagementInformationSystemBalancesListPeriod.day,
    label: 'Day',
  },
  {
    value: ManagementInformationSystemBalancesListPeriod.week,
    label: 'Week',
  },
  {
    value: ManagementInformationSystemBalancesListPeriod.month,
    label: 'Month',
  },
  {
    value: ManagementInformationSystemBalancesListPeriod.quarter,
    label: 'Quarter',
  },
  {
    value: ManagementInformationSystemBalancesListPeriod.year,
    label: 'Year',
  },
];

export const ManagementInformationSystemWrapperPage = () => {
  const { data: operator } = useOperatorGet({});

  const segments = compact([
    {
      slug: buildPath(ROUTES.MANAGEMENT_INFORMATION_SYSTEM.OVERVIEW, {}),
      title: 'Overview',
    },
    {
      slug: buildPath(ROUTES.MANAGEMENT_INFORMATION_SYSTEM.VOLUME, {}),
      title: 'Volume',
    },
    operator?.role === 'internal' && {
      slug: buildPath(ROUTES.MANAGEMENT_INFORMATION_SYSTEM.ACCOUNTS, {}),
      title: 'Accounts',
    },
    {
      slug: buildPath(ROUTES.MANAGEMENT_INFORMATION_SYSTEM.COMPLIANCE, {}),
      title: 'Compliance',
    },
  ]);

  const [atTime, setAtTime] = useSearchParamsState(
    ROUTES.MANAGEMENT_INFORMATION_SYSTEM,
    'at_time'
  );

  const [period, setPeriod] = useSearchParamsState(
    ROUTES.MANAGEMENT_INFORMATION_SYSTEM,
    'lookback_period'
  );

  const location = useLocation();

  const segmentIndex =
    segments.findIndex((s) => location.pathname.includes(s.slug)) || 0;

  const cleanSegments = segments.map(({ title, slug }, i) => ({
    title,
    href: slug,
    selected: i === segmentIndex,
  }));

  return (
    <PageLayout
      headline={`Management Information System • ${segments[segmentIndex]?.title}`}
      segmentedControl={
        <Box flex>
          <SegmentedControl segments={cleanSegments} />
          <Box flex>
            <DateInput
              value={atTime}
              onChange={(e) => setAtTime(e.target.value)}
            />

            <Select<ManagementInformationSystemBalancesListPeriod>
              className="w-64"
              value={period}
              onUpdate={setPeriod}
              sections={[
                {
                  options,
                },
              ]}
            />
          </Box>
        </Box>
      }
    >
      <Outlet />
    </PageLayout>
  );
};
