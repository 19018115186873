import { PageLayout } from '../deprecated/PageLayout';
import { SegmentedControl } from 'shared/components/SegmentedControl';
import { Outlet } from 'react-router';

export const UnusualActivityReportsWrapperPage = () => {
  const segments = [
    {
      slug: '/unusual_activity_reports/platform',
      title: 'Platform',
    },
    {
      slug: '/unusual_activity_reports/direct',
      title: 'Direct',
    },
  ] as const;

  const segmentIndex = location.pathname.includes('platform') ? 0 : 1;
  const cleanSegments = segments.map(({ title, slug }, i) => ({
    title,
    href: slug,
    selected: i === segmentIndex,
  }));

  return (
    <PageLayout
      headline={'Unusual activity reports'}
      segmentedControl={<SegmentedControl segments={cleanSegments} />}
    >
      <Outlet />
    </PageLayout>
  );
};
