import React, { useState } from 'react';

import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { Button } from '../deprecated/Button';
import { CustomTooltip } from './custom-tooltip';
import { formatAmount } from 'shared/lib/formatting';
import { CombinedDataRow } from './helpers';
import { Select, SelectSection } from 'shared/components/Select';

type MetricType = 'Revenue' | 'Volume' | 'Balance';

const metricSections: SelectSection<MetricType>[] = [
  {
    options: [
      { label: 'Revenue', value: 'Revenue' },
      { label: 'Volume', value: 'Volume' },
      { label: 'Balance', value: 'Balance' },
    ],
  },
];

const colors = [
  '#FF5F33',
  '#34BCFF',
  '#31F2BF',
  '#E4FF33',
  '#954CF5',
  '#EF4AFB',
  '#1D2A36',
  '#15CB9A',
  '#2597E8',
  '#108765',
];

const getChartDataForProgram = (
  program: CombinedDataRow,
  selectedMetric: MetricType,
  currentPeriodDate: string,
  previousPeriodDate: string
) => {
  switch (selectedMetric) {
    case 'Revenue':
      return [
        {
          period: previousPeriodDate,
          value: program.previous_revenue,
          program: program.partner_name,
          type: selectedMetric,
        },
        {
          period: currentPeriodDate,
          value: program.revenue,
          program: program.partner_name,
          type: selectedMetric,
        },
      ];
    case 'Volume':
      return [
        {
          period: previousPeriodDate,
          value: program.lookback_total_volume,
          program: program.partner_name,
          type: selectedMetric,
        },
        {
          period: currentPeriodDate,
          value: program.total_volume,
          program: program.partner_name,
          type: selectedMetric,
        },
      ];
    case 'Balance':
      return [
        {
          period: previousPeriodDate,
          value: program.lookback_usd_balance,
          program: program.partner_name,
          type: selectedMetric,
        },
        {
          period: currentPeriodDate,
          value: program.usd_balance,
          program: program.partner_name,
          type: selectedMetric,
        },
      ];
    default:
      return [];
  }
};

export const ProgramSummaryChart = ({
  data,
  currentPeriodDate,
  previousPeriodDate,
}: {
  data: CombinedDataRow[];
  currentPeriodDate: string;
  previousPeriodDate: string;
}) => {
  const [isChartVisible, setIsChartVisible] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState<MetricType>('Revenue');

  return (
    <div>
      <div className="mb-2 flex justify-end">
        <Button
          className="rounded bg-info-strong px-4 py-2 text-inverse transition duration-300 hover:opacity-80"
          onClick={() => setIsChartVisible(!isChartVisible)}
        >
          {isChartVisible ? 'Hide Chart' : 'View Chart'}
        </Button>
      </div>

      {isChartVisible && (
        <>
          <div className="flex justify-end">
            <Select
              sections={metricSections}
              value={selectedMetric}
              onUpdate={(value) => setSelectedMetric(value)}
            />
          </div>
          <ResponsiveContainer width="100%" height={400}>
            <ScatterChart margin={{ top: 20, right: 0, left: 80, bottom: 20 }}>
              <CartesianGrid />
              <XAxis
                type="category"
                dataKey="period"
                allowDuplicatedCategory={false}
              />
              <YAxis
                dataKey="value"
                name={selectedMetric}
                type="number"
                tickFormatter={(value) => formatAmount(value, 'USD', true)}
              />
              <Tooltip
                content={<CustomTooltip />}
                animationDuration={50}
                wrapperStyle={{ outline: 'none' }}
                cursor={{ stroke: 'rgba(0,0,0,0.1)', strokeWidth: 1.5 }}
              />
              {data.map((program, index) => {
                const color = colors[index % colors.length];

                return (
                  <Scatter
                    key={`${program.program_id}-${selectedMetric}`}
                    name={`${program.partner_name} (${selectedMetric})`}
                    data={getChartDataForProgram(
                      program,
                      selectedMetric,
                      currentPeriodDate,
                      previousPeriodDate
                    )}
                    fill={color}
                    line={{ stroke: color, strokeWidth: 2 }}
                    shape="circle"
                  />
                );
              })}
              <Legend />
              <Label value={selectedMetric} offset={0} position="bottom" />
            </ScatterChart>
          </ResponsiveContainer>
        </>
      )}
    </div>
  );
};
