import React from 'react';
import { useOutboundACHFilesOverviewGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import classNames from 'classnames';

import { StyledLink } from './deprecated/StyledLink';
import { formatAmount } from 'shared/lib/formatting';
import { buildPath, ROUTES } from 'src/lib/routes';

const ObjectLink = ({ id }: { id: string }) => (
  <StyledLink to={buildPath(ROUTES.OBJECT_VIEWER, { objectID: id })}>
    {id}
  </StyledLink>
);

const TinyCard = ({
  children,
  className,
}: {
  children: React.ReactNode | React.ReactNode[];
  className?: string;
}) => (
  <div
    className={classNames(
      'm-0.5 rounded border border-main p-1 text-xs',
      className
    )}
  >
    {children}
  </div>
);

export const OutboundACHFiles = () => {
  const { data: outboundACHFilesOverview } = useOutboundACHFilesOverviewGet({});

  return (
    <PageLayout headline="Outbound ACH Files">
      <>
        <div>
          <h1>Outbound ACH Files</h1>
          <h5>In the last 7 days</h5>
        </div>

        {outboundACHFilesOverview ? (
          <div>
            {outboundACHFilesOverview.pending_canary_transfers.length > 0 ? (
              <table className="w-full table-auto text-sm">
                <thead>
                  <tr>
                    <th>Canary Transfer</th>
                    <th>ACH Transfer</th>
                    <th>Expected Complete By</th>
                  </tr>
                </thead>

                <tbody>
                  {outboundACHFilesOverview.pending_canary_transfers.map(
                    (transfer) => {
                      return (
                        <tr
                          key={transfer.id}
                          className={classNames(
                            transfer.alert_status === 'warn' && 'bg-warning',
                            transfer.alert_status === 'alert' && 'bg-danger'
                          )}
                        >
                          <td className="align-top">
                            <TinyCard>
                              <div>
                                <ObjectLink id={transfer.id} />
                              </div>
                              <div>{transfer.bank}</div>
                              <div>{transfer.created_at}</div>
                            </TinyCard>
                          </td>
                          <td className="align-top">
                            <TinyCard>
                              <div>
                                <ObjectLink id={transfer.ach_transfer.id} />
                              </div>
                              <div>{transfer.ach_transfer.status}</div>
                              {transfer.ach_transfer.submitted_at && (
                                <div>
                                  Submitted:{' '}
                                  {transfer.ach_transfer.submitted_at}
                                </div>
                              )}
                              {transfer.ach_transfer.submitted_file_id && (
                                <div>
                                  Submitted file:{' '}
                                  <ObjectLink
                                    id={transfer.ach_transfer.submitted_file_id}
                                  />
                                </div>
                              )}
                            </TinyCard>
                          </td>
                          <td className="align-top">
                            <TinyCard>{transfer.expected_complete_by}</TinyCard>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            ) : (
              <div>
                <em>None</em>
              </div>
            )}
          </div>
        ) : (
          <div>Loading</div>
        )}

        {outboundACHFilesOverview ? (
          <table className="w-full table-auto text-sm">
            <thead>
              <tr>
                <th>FedACH File</th>
                <th>Outbound File</th>
                <th>Details</th>
                <th>Acknowledgements</th>
              </tr>
            </thead>

            <tbody>
              {outboundACHFilesOverview.outbound_ach_files.map((file) => (
                <tr key={file.id}>
                  <td className="align-top">
                    <TinyCard
                      className={classNames(
                        file.status !== 'acknowledged' && 'bg-warning'
                      )}
                    >
                      <div>{file.file_type}</div>
                      <div>{file.bank || 'pending'}</div>
                      <div>
                        <ObjectLink id={file.id} />
                      </div>
                      <div>{file.created_at}</div>
                      <div>{file.status}</div>
                    </TinyCard>
                  </td>
                  <td className="align-top">
                    <TinyCard
                      className={classNames(
                        file.outbound_file_status !== 'complete' && 'bg-warning'
                      )}
                    >
                      <div>{file.transmission_name}</div>
                      <div>{file.outbound_file_status}</div>
                    </TinyCard>
                  </td>

                  <td className="align-top">
                    <TinyCard>
                      <div>
                        Batches:{' '}
                        {file.batch_count == null
                          ? 'pending'
                          : file.batch_count}
                      </div>
                      <div>
                        Debits:{' '}
                        {file.total_debit_entry_amount == null
                          ? 'pending'
                          : formatAmount(file.total_debit_entry_amount, 'USD')}
                      </div>
                      <div>
                        Credits:{' '}
                        {file.total_credit_entry_amount == null
                          ? 'pending'
                          : formatAmount(file.total_credit_entry_amount, 'USD')}
                      </div>
                    </TinyCard>
                  </td>

                  <td className="align-top">
                    {file.acknowledgements.map((ack) => {
                      const reject = ack.file_status?.search('REJECTED') !== -1;
                      return (
                        <TinyCard
                          key={ack.id}
                          className={classNames({
                            'bg-warning':
                              ack.error_message &&
                              ack.error_message.length > 0 &&
                              !reject,
                            'bg-danger': reject,
                          })}
                        >
                          <div>
                            <ObjectLink id={ack.id} />
                          </div>
                          <div>{ack.created_at}</div>
                          <div>{ack.file_status}</div>
                          <div>{ack.error_message}</div>
                          <div>File Processed: {ack.file_process_datetime}</div>
                        </TinyCard>
                      );
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>Loading</div>
        )}
      </>
    </PageLayout>
  );
};
