import React, { useState } from 'react';

import { useCheckItemAllocationQueueItemList } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { Button } from './deprecated/Button';
import { ItemList } from './deprecated/ItemList';

export const CheckItemsToAllocate = () => {
  const [after, setAfter] = useState<string | null>(null);
  const { data: queueItems } = useCheckItemAllocationQueueItemList({
    after: after ?? undefined,
  });
  if (!queueItems) {
    return <>Loading...</>;
  }
  const { data } = queueItems;
  const lastItem = data.length > 0 ? data[data.length - 1] : null;

  return (
    <PageLayout
      id="application.inboundCheckItemAllocationQueue"
      headline="Inbound Check Item Allocation Queue"
    >
      {lastItem && (
        <Button
          id={'application.inboundCheckItemAllocationQueue.nextPage'}
          onClick={() => setAfter(lastItem.created_at)}
        >
          Next page
        </Button>
      )}
      <div>
        <em>
          {data.length} Inbound Check {data.length === 1 ? 'Item' : 'Items'}
          {data.length === 0 && ' 🎉'}
        </em>
        <ItemList
          items={data.map((item) => ({
            id: item.id,
            text: item.id,
            detail: item.created_at,
            rightDetail: item.record_id || undefined,
            href: item.link || undefined,
            rightText: 'Allocate',
          }))}
        />
      </div>
    </PageLayout>
  );
};
