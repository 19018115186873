import React from 'react';
import { useEntityAddressVerificationList } from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { ROUTES } from 'src/lib/routes';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { EntityAddressVerificationTable } from '../tables/EntityAddressVerificationTable';

export const EntityAddressVerificationListPage = () => {
  const { addressableId } = useTypedParams(ROUTES.ENTITY_ADDRESS_VERIFICATION);

  const listResult = useEntityAddressVerificationList({
    addressable_id: addressableId,
  });

  return (
    <PageLayout headline={`Entity Address Verifications (${addressableId})`}>
      <TableStateWrapper
        table={EntityAddressVerificationTable}
        {...listResult}
        style="primary"
        emptyTitle="No Address Verifications"
        emptySubtitle="There are no address details to display"
        filters={[]}
      />
    </PageLayout>
  );
};
