import { useNavigate } from 'react-router-dom';
import { PageLayout } from './deprecated/PageLayout';
import { Button } from './deprecated/Button';

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <PageLayout headline="Page not found">
      <Button onClick={() => navigate('/')}>Go Home</Button>
    </PageLayout>
  );
};
