import React from 'react';
import { usePendingTransactionGet } from 'src/hooks/reactQuery';
import { OperatorNotes } from './operator-notes';
import { PageLayout } from './deprecated/PageLayout';
import { SideBySide } from 'shared/components/SideBySide';
import { PendingTransactionPropertyList } from './property-lists/PendingTransactionPropertyList';
import { Box } from 'shared/components/Box';
import { Icon } from 'shared/components/Icon';
import { Note } from 'shared/components/Note';
import { Body } from 'shared/components/Text';
import { formatAmount, humanize } from 'shared/lib/formatting';
import { PropertyList } from 'shared/components/PropertyList';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES } from 'src/lib/routes';

const SKIP_SOURCE_KEYS = [
  'amount',
  'currency',
  'class_name',
  'transaction_id',
  'type',
];

function extractSourceFields(
  object: Record<string, string>,
  prefix = ''
): { label: string; value: string }[] {
  return Object.entries(object)
    .filter(([key]) => !SKIP_SOURCE_KEYS.includes(key))
    .map(([key, value]) => {
      if (value && typeof value == 'object') {
        return extractSourceFields(value, prefix + key + '.');
      } else {
        return [{ label: humanize(`${prefix}${key}`), value }];
      }
    })
    .reduce((a, b) => a.concat(b), []);
}

export const PendingTransactionDetail = () => {
  const { pendingTransactionID } = useTypedParams(
    ROUTES.PENDING_TRANSACTION_DETAIL
  );

  const { data: pendingTransaction } =
    usePendingTransactionGet(pendingTransactionID);
  if (!pendingTransaction) {
    return <>Loading...</>;
  }
  const source = pendingTransaction.source as unknown as
    | Record<string, string>
    | undefined;

  return (
    <PageLayout
      id="application.transactionDetails"
      headline={`${pendingTransaction.source_type} ${formatAmount(
        pendingTransaction.amount,
        pendingTransaction.currency
      )}`}
    >
      <SideBySide
        right={
          <PendingTransactionPropertyList
            title="Basics"
            layoutHorizontallyWhenPossible={false}
            pendingTransaction={pendingTransaction}
          />
        }
        left={
          <>
            {pendingTransaction.source_documentation && (
              <Note>
                <Box gap="2">
                  <Box flex center>
                    <Icon name="info" />
                    <Body weight="bold">{pendingTransaction.source_type}</Body>
                  </Box>
                  <Body>{pendingTransaction.source_documentation}</Body>
                </Box>
              </Note>
            )}

            <PropertyList
              title="Details"
              items={[
                { label: 'Description', value: pendingTransaction.description },
              ].concat(extractSourceFields(source || {}))}
            />
          </>
        }
        mode="rightDrawer"
      />

      <br />

      <OperatorNotes modelId={pendingTransactionID} />
    </PageLayout>
  );
};
