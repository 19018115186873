import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { formatISO8601Timestamp, humanize } from 'shared/lib/formatting';
import { useMemo } from 'react';
import { ControlEvaluationListResponseDataItem } from 'src/build/operations';

type Props = PrestyledTableProps<ControlEvaluationListResponseDataItem>;

export const ControlEvaluationsTable = (props: Props) => {
  const { getRowProps, ...rest } = props;
  const columns = useMemo<
    TableColumns<ControlEvaluationListResponseDataItem>
  >(() => {
    return [
      {
        header: 'Status',
        contents: (evaluation) => ({
          text: humanize(evaluation.result),
          badgeColor: evaluation.result === 'success' ? 'green' : 'red',
        }),
      },
      {
        header: 'Failure reason',
        expand: 1,
        contents: (evaluation) => ({
          text: evaluation.failure_message || '-',
        }),
      },
      {
        header: 'Evaluated at',
        contents: (evaluation) => ({
          text: formatISO8601Timestamp(evaluation.created_at, 'full'),
        }),
      },
    ];
  }, []);

  return (
    <Table<ControlEvaluationListResponseDataItem>
      columns={columns}
      getRowProps={getRowProps}
      {...rest}
    />
  );
};
