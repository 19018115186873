import React, { useMemo } from 'react';
import { useCompletedTransactionListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { CompletedTransactionsTable } from '../tables/CompletedTransactionTable';
import {
  makeAccountFilter,
  makeAccountNumberFilter,
  makeAmountFilter,
  makeCardFilter,
  makeEnumFilter,
  makeGroupFilter,
  makeTimeRangeFilter,
} from 'src/lib/tableFilterHelpers';
import { compact } from 'lodash';
import { TableFilter } from 'shared/components/Table/filters';
import { DateTime } from 'luxon';
import { CompletedTransactionListResponseDataItemSourceType } from 'src/build/operations';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';
import { useTypedRangeParams } from 'src/hooks/timeSearchParameters';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';

const CompletedTransactionPaymentRails = {
  ACH: 'ACH',
  Card: 'Card',
  Check: 'Check',
  Wire: 'Wire',
  RealTimePayment: 'Real-Time Payment',
  Other: 'Other',
};
export type CompletedTransactionPaymentRail =
  keyof typeof CompletedTransactionPaymentRails;

const CompletedTransactionTypeGrouping: Record<
  CompletedTransactionListResponseDataItemSourceType,
  CompletedTransactionPaymentRail
> = {
  'API::ACHCheckConversion': 'Check',
  'API::ACHCheckConversionReturn': 'Check',
  'API::ACHTransferIntention': 'ACH',
  'API::ACHTransferRejection': 'ACH',
  'API::ACHTransferReturn': 'ACH',
  'API::AccountTransferIntention': 'Other',
  'API::CardDisputeAcceptance': 'Card',
  'API::CardDisputeLoss': 'Card',
  'API::CardRefund': 'Card',
  'API::CardRevenuePayment': 'Card',
  'API::CardSettlement': 'Card',
  'API::CashbackPayment': 'Other',
  'API::CheckDepositAcceptance': 'Check',
  'API::CheckDepositReturn': 'Check',
  'API::CheckTransferIntention': 'Check',
  'API::CheckTransferRejection': 'Check',
  'API::CheckTransferReturn': 'Check',
  'API::CheckTransferStopPaymentRequest': 'Check',
  'API::DeprecatedTransactionSource': 'Other',
  'API::DisputeResolution': 'Other',
  'API::EmpyrealCashDeposit': 'Other',
  'API::FeePayment': 'Other',
  'API::InboundACHTransferIntention': 'ACH',
  'API::InboundACHTransferReturnIntention': 'ACH',
  'API::InboundCheck': 'Check',
  'API::InboundCheckDepositReturnIntention': 'Check',
  'API::InboundCheckAdjustment': 'Check',
  'API::InboundRealTimePaymentsTransferConfirmation': 'RealTimePayment',
  'API::InboundWireReversal': 'Wire',
  'API::InboundWireTransferIntention': 'Wire',
  'API::InboundWireTransferReversalIntention': 'Wire',
  'API::InterestPayment': 'Other',
  'API::InternalGeneralLedgerTransaction': 'Other',
  'API::InternalSource': 'Other',
  'API::PrivacyCardRefund': 'Card',
  'API::PrivacyCardSettlement': 'Card',
  'API::RealTimePaymentsTransferAcknowledgement': 'RealTimePayment',
  'API::SampleFunds': 'Other',
  'API::WireDrawdownPaymentIntention': 'Wire',
  'API::WireTransferIntention': 'Wire',
  'API::WireTransferRejection': 'Wire',
  'API::ACHDecline': 'ACH',
  'API::CardDecline': 'Card',
  'API::CheckDecline': 'Check',
  'API::CheckDepositRejection': 'Check',
  'API::InboundRealTimePaymentsTransferDecline': 'RealTimePayment',
  'API::PrivacyCardDecline': 'Card',
  'API::WireDecline': 'Wire',
} as const;

export const CompletedTransactionListPage = () => {
  const [groupFilter, setGroupFilter] = useSearchParamsState(
    ROUTES.TRANSACTIONS_LIST,
    'group'
  );

  const [accountFilter, setAccountFilter] = useSearchParamsState(
    ROUTES.TRANSACTIONS_LIST,
    'account'
  );

  const [cardFilter, setCardFilter] = useSearchParamsState(
    ROUTES.TRANSACTIONS_LIST,
    'card'
  );
  const [accountNumberFilter, setAccountNumberFilter] = useSearchParamsState(
    ROUTES.TRANSACTIONS_LIST,
    'account_number'
  );

  const [sourceTypeFilter, setSourceTypeFilter] = useSearchParamsState(
    ROUTES.TRANSACTIONS_LIST,
    'source_type'
  );

  const [{ startAt, endAt }, setTimeRange] = useTypedRangeParams(
    ROUTES.TRANSACTIONS_LIST
  );

  const [amountOrGreater] = useSearchParamsState(
    ROUTES.TRANSACTIONS_LIST,
    'amount_or_greater'
  );

  const [amountOrLess] = useSearchParamsState(
    ROUTES.TRANSACTIONS_LIST,
    'amount_or_less'
  );

  const [exactAmount] = useSearchParamsState(
    ROUTES.TRANSACTIONS_LIST,
    'exact_amount'
  );

  const setManyParameters = useTypedSearchParams(ROUTES.TRANSACTIONS_LIST)[1];

  const startAtDateTime = startAt
    ? DateTime.fromISO(startAt, { zone: 'utc' })
    : undefined;
  const endAtDateTime = endAt
    ? DateTime.fromISO(endAt, { zone: 'utc' })
    : undefined;

  const filters: TableFilter[] = useMemo(
    () =>
      compact([
        makeGroupFilter(groupFilter, setGroupFilter),
        makeAccountFilter(accountFilter, setAccountFilter),
        makeCardFilter(cardFilter, setCardFilter),
        makeAccountNumberFilter(accountNumberFilter, setAccountNumberFilter),
        makeTimeRangeFilter({ startAt, endAt }, setTimeRange, 'Date'),
        makeAmountFilter(
          amountOrGreater ?? null,
          amountOrLess ?? null,
          exactAmount ?? null,
          ({ newAmountOrGreater, newAmountOrLess, newExactAmount }) =>
            setManyParameters({
              amount_or_greater: newAmountOrGreater ?? undefined,
              amount_or_less: newAmountOrLess ?? undefined,
              exact_amount: newExactAmount ?? undefined,
            })
        ),
        makeEnumFilter(
          sourceTypeFilter,
          setSourceTypeFilter,
          'Transfer Type',
          CompletedTransactionPaymentRails,
          'arrow_circle_out_right'
        ),
      ]),
    [
      accountFilter,
      accountNumberFilter,
      amountOrGreater,
      amountOrLess,
      cardFilter,
      endAt,
      exactAmount,
      groupFilter,
      setAccountFilter,
      setAccountNumberFilter,
      setCardFilter,
      setGroupFilter,
      setManyParameters,
      setSourceTypeFilter,
      setTimeRange,
      sourceTypeFilter,
      startAt,
    ]
  );

  const sourceTypesFilterValue = useMemo<
    CompletedTransactionListResponseDataItemSourceType[]
  >(
    () =>
      Object.keys(CompletedTransactionTypeGrouping).filter(
        (key): key is CompletedTransactionListResponseDataItemSourceType =>
          sourceTypeFilter.includes(
            CompletedTransactionTypeGrouping[
              key as CompletedTransactionListResponseDataItemSourceType
            ]
          )
      ),
    [sourceTypeFilter]
  );

  const listResult = useCompletedTransactionListInfinite({
    groups: groupFilter.length > 0 ? groupFilter : undefined,
    accounts: accountFilter.length > 0 ? accountFilter : undefined,
    date_at_or_after: startAtDateTime?.startOf('day').toISO() ?? undefined,
    date_at_or_before: endAtDateTime?.endOf('day').toISO() ?? undefined,
    source_types:
      sourceTypesFilterValue.length > 0 ? sourceTypesFilterValue : undefined,
    account_numbers:
      accountNumberFilter.length > 0 ? accountNumberFilter : undefined,
    cards: cardFilter.length > 0 ? cardFilter : undefined,
    exact_amount: exactAmount,
    amount_or_greater: amountOrGreater,
    amount_or_less: amountOrLess,
  });

  return (
    <TableStateWrapper
      table={CompletedTransactionsTable}
      style="primary"
      {...listResult}
      emptyTitle="No transactions"
      emptySubtitle="There are no transactions to display"
      filters={filters}
      showAccount={accountFilter.length !== 1}
      showGroup={groupFilter.length !== 1 && accountFilter.length !== 1}
      showRoute={accountFilter.length === 1}
    />
  );
};
