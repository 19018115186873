import React, { useMemo } from 'react';
import { PageLayout } from '../deprecated/PageLayout';
import { useControlRecordListInfinite } from 'src/hooks/reactQuery';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { makeEnumFilter } from 'src/lib/tableFilterHelpers';
import { compact } from 'lodash';
import { TableFilter } from 'shared/components/Table/filters';
import {
  ControlRecordGetResponseCategory,
  ControlRecordGetResponseResult,
} from 'src/build/operations';
import { ControlRecordsTable } from '../tables/ControlRecordsTable';
import { useSearchParamsState } from 'shared/hooks/useTypedSearchParamsState';
import { ROUTES } from 'src/lib/routes';

export const ControlRecordListPage = () => {
  const [categories, setCategories] = useSearchParamsState(
    ROUTES.CONTROL_RECORDS_LIST,
    'categories'
  );

  const [results, setResults] = useSearchParamsState(
    ROUTES.CONTROL_RECORDS_LIST,
    'results'
  );

  const [controlConfigurationIds] = useSearchParamsState(
    ROUTES.CONTROL_RECORDS_LIST,
    'control_configuration_ids'
  );

  const filters: TableFilter[] = useMemo(
    () =>
      compact([
        makeEnumFilter(
          results,
          setResults,
          'Result',
          ControlRecordGetResponseResult,
          'star'
        ),
        makeEnumFilter(
          categories,
          setCategories,
          'Category',
          ControlRecordGetResponseCategory,
          'star'
        ),
      ]),
    [results, setResults, categories, setCategories]
  );
  const listResult = useControlRecordListInfinite({
    results: results.length > 0 ? results : undefined,
    categories: categories.length > 0 ? categories : undefined,
    control_configuration_ids:
      controlConfigurationIds.length > 0 ? controlConfigurationIds : undefined,
  });

  return (
    <PageLayout headline={'Control records'}>
      <TableStateWrapper
        table={ControlRecordsTable}
        style="primary"
        {...listResult}
        emptyTitle="No control records"
        emptySubtitle="There are no control records to display. Change your filters"
        filters={filters}
      />
    </PageLayout>
  );
};
