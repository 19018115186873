import { PageLayout } from '../deprecated/PageLayout';
import { SegmentedControl } from 'shared/components/SegmentedControl';
import { Outlet } from 'react-router';

export const FedwireInboundWrapperPage = () => {
  const segments = [
    {
      slug: '/fedwire_inbound/service_messages',
      title: 'Service messages',
    },
    {
      slug: '/fedwire_inbound/request_for_reversals',
      title: 'Request for reversals',
    },
  ] as const;

  const segmentIndex = location.pathname.includes('service_messages') ? 0 : 1;

  const cleanSegments = segments.map(({ title, slug }, i) => ({
    title,
    href: slug,
    selected: i === segmentIndex,
  }));

  return (
    <PageLayout
      headline={'Fedwire messages'}
      segmentedControl={<SegmentedControl segments={cleanSegments} />}
    >
      <Outlet />
    </PageLayout>
  );
};
