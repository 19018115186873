import {
  TableColumns,
  Table,
  PrestyledTableProps,
} from 'shared/components/Table';
import { useMemo } from 'react';
import { OAuthConnectionListResponseDataItem } from 'src/build/operations';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import { buildPath, ROUTES } from 'src/lib/routes';

type Props = PrestyledTableProps<OAuthConnectionListResponseDataItem>;

export const OAuthConnectionsTable = (props: Props) => {
  const { ...rest } = props;
  const columns = useMemo<
    TableColumns<OAuthConnectionListResponseDataItem>
  >(() => {
    return [
      {
        header: 'Name',
        expand: 1,
        contents: (oauthConnection) => ({
          text: oauthConnection.name ?? 'Unnamed',
          textWeight: 'medium',
          textColor: 'emphasis',
        }),
      },
      {
        header: 'Group',
        expand: 1,
        contents: (oauthConnection) => ({
          text: oauthConnection.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: oauthConnection.group_id,
          }),
        }),
      },
      {
        header: 'Connected Group',
        expand: 1,
        contents: (oauthConnection) => ({
          text: oauthConnection.connected_group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: oauthConnection.connected_group_id,
          }),
        }),
      },
      {
        header: 'Created',
        align: 'right',
        contents: (oauthConnection) => ({
          text: formatISO8601Timestamp(oauthConnection.created_at, 'medium'),
        }),
      },
    ];
  }, []);

  return (
    <Table<OAuthConnectionListResponseDataItem> columns={columns} {...rest} />
  );
};
