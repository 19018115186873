import { useMemo } from 'react';

import { BadgeColor } from 'shared/components/Badge';
import {
  PrestyledTableProps,
  Table,
  TableColumns,
} from 'shared/components/Table';
import { formatAmount, formatISO8601Timestamp } from 'shared/lib/formatting';
import {
  CardDisputeListResponseDataItem,
  CardDisputeListResponseDataItemStatus,
} from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';

type CardDisputeTableProps =
  PrestyledTableProps<CardDisputeListResponseDataItem>;

const statusBadgeColor: Record<
  CardDisputeListResponseDataItemStatus,
  BadgeColor
> = {
  pending_reviewing: 'blue',
  accepted: 'purple',
  rejected: 'red',
  lost: 'yellow',
  won: 'green',
};

const statusText: Record<CardDisputeListResponseDataItemStatus, string> = {
  pending_reviewing: 'Pending Reviewing',
  accepted: 'Accepted',
  rejected: 'Rejected',
  lost: 'Lost',
  won: 'Won',
};

const defaultRowProps = (datum: CardDisputeListResponseDataItem) => ({
  href: buildPath(ROUTES.OBJECT_VIEWER, {
    objectID: datum.id,
  }),
  className: 'hover:bg-main-hover cursor-pointer transition-all group',
});

export const CardDisputesTable = (props: CardDisputeTableProps) => {
  const { getRowProps, ...rest } = props;
  const columns = useMemo<TableColumns<CardDisputeListResponseDataItem>>(() => {
    return [
      {
        header: 'Created',
        contents: (cardDispute) => ({
          text: formatISO8601Timestamp(cardDispute.created_at, 'medium'),
        }),
      },
      {
        header: 'Status',
        contents: (cardDispute) => ({
          text: statusText[cardDispute.status],
          badgeColor: statusBadgeColor[cardDispute.status],
        }),
      },
      {
        header: 'Group',
        contents: (cardDispute) => ({
          text: cardDispute.group_id,
          href: buildPath(ROUTES.GROUPS_DETAIL, {
            groupId: cardDispute.group_id,
          }),
        }),
      },
      {
        header: 'Card',
        contents: (cardDispute) => ({
          text: cardDispute.card_id,
          href: buildPath(ROUTES.CARD, {
            cardID: cardDispute.card_id,
          }),
        }),
      },
      {
        header: 'Entity',
        contents: (cardDispute) => ({
          text: cardDispute.entity_name ?? 'Unnamed Entity',
        }),
      },
      {
        header: 'Amount',
        contents: (cardDispute) => {
          return {
            text: formatAmount(cardDispute.transaction_amount, 'USD'),
            textWeight: 'bold',
          };
        },
      },
    ];
  }, []);

  return (
    <Table
      {...rest}
      getRowProps={getRowProps ?? defaultRowProps}
      columns={columns}
    />
  );
};
