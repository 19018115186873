import type { AxiosRequestConfig } from 'axios';
import { useFederalReserveGatewayLimitsList } from 'src/hooks/reactQuery';
import { useServices } from '../hooks/use-services';
import { PageLayout } from './deprecated/PageLayout';
import { StackedList } from 'shared/components/lists/StackedList';
import { ListRow } from 'shared/components/lists/ListRow';
import { ROUTES, buildPath } from 'src/lib/routes';

function useAxiosOptions(): AxiosRequestConfig {
  const { federalreservegateway: baseURL } = useServices();
  return { baseURL };
}

export const AllFederalReserveLimits = () => {
  const { data, error } = useFederalReserveGatewayLimitsList(useAxiosOptions());
  const loading = !error && !data;
  return (
    <PageLayout
      id="application.service.all-federal-reserve-limits"
      headline="Federal Reserve Limits"
    >
      {loading && <div>Loading...</div>}
      {error && (
        <div
          id="application.service.application.service.all-federal-reserve-limits.error"
          className="relative rounded border border-danger bg-danger px-4 py-3 text-danger"
        >
          Error: {error.message}
        </div>
      )}
      {data && (
        <StackedList>
          {data.limits.map((item) => (
            <ListRow
              key={item.id}
              icon="building"
              title={`Limits for routing number: ${item.routing_number}`}
              href={buildPath(ROUTES.FEDERAL_RESERVE_LIMIT_DETAILS, {
                limitsId: item.id,
              })}
            />
          ))}
        </StackedList>
      )}
    </PageLayout>
  );
};
