import { useMemo } from 'react';

import {
  PrestyledTableProps,
  Table,
  TableColumns,
} from 'shared/components/Table';
import { formatAmount, formatISO8601Timestamp } from 'shared/lib/formatting';
import { CompletedTransactionListResponseDataItem } from 'src/build/operations';
import { buildPath, ROUTES } from 'src/lib/routes';

export type CompletedTransactionsTableProps =
  PrestyledTableProps<CompletedTransactionListResponseDataItem> & {
    showAccount?: boolean;
    showGroup?: boolean;
    showRoute?: boolean;
  };

export const defaultRowProps = (
  datum: CompletedTransactionListResponseDataItem
) => ({
  href: datum.is_declined_transaction
    ? buildPath(ROUTES.DECLINED_TRANSACTION_DETAIL, {
        declinedTransactionID: datum.id,
      })
    : buildPath(ROUTES.TRANSACTION_DETAIL, {
        transactionID: datum.id,
      }),
  className: 'hover:bg-main-hover cursor-pointer transition-all group',
});

export const hrefForRoute = (
  txn: CompletedTransactionListResponseDataItem
): string | undefined => {
  if (!txn.route_id) {
    return undefined;
  }
  if (txn.route_id.startsWith('account_number_')) {
    return buildPath(ROUTES.ACCOUNT_NUMBERS_DETAILS, {
      accountNumberId: txn.route_id,
    });
  }
  if (txn.route_id.startsWith('card_')) {
    return buildPath(ROUTES.CARD, {
      cardID: txn.route_id,
    });
  }
  return undefined;
};

export const CompletedTransactionsTable = (
  props: CompletedTransactionsTableProps
) => {
  const { getRowProps } = props;
  const columns = useMemo<
    TableColumns<CompletedTransactionListResponseDataItem>
  >(() => {
    return [
      {
        header: 'Created',
        mobileHeader: 'Details',
        contents: (transaction) => ({
          text: formatISO8601Timestamp(
            transaction.date,
            'month-day-year-hour-minute-second',
            'UTC'
          ),
        }),
        mobileContents: (transaction) => ({
          text: transaction.description,
          textWeight: 'medium',
          textColor: 'emphasis',
          caption: transaction.source_type.replaceAll('API::', ''),
        }),
      },
      {
        header: 'Description',
        expand: 1,
        contents: (transaction) => ({
          text: transaction.description,
          textWeight: 'medium',
          textColor: 'emphasis',
        }),
      },
      {
        header: 'Category',
        expand: 1,
        contents: (transaction) => ({
          text: transaction.source_type.replaceAll('API::', ''),
        }),
      },
      props.showGroup && {
        header: 'Group',
        expand: 1,
        hideBelowTableWidth: 'md',
        contents: (txn) => {
          return {
            text: txn.group_id,
            href: buildPath(ROUTES.GROUPS_DETAIL, {
              groupId: txn.group_id,
            }),
          };
        },
      },
      props.showAccount && {
        header: 'Account',
        expand: 1,
        hideBelowTableWidth: 'lg',
        contents: (txn) => ({
          text: txn.account_id,
          href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
            accountId: txn.account_id,
          }),
        }),
      },
      props.showRoute && {
        header: 'Route',
        expand: 1,
        hideBelowTableWidth: 'xl',
        contents: (txn) => ({
          text: txn.route_id ?? '',
          href: hrefForRoute(txn),
        }),
      },
      {
        header: 'Amount',
        align: 'right',
        contents: (txn) => {
          return {
            text: formatAmount(txn.amount, 'USD'),
            textColor: txn.is_declined_transaction ? 'secondary' : 'emphasis',
            textWeight: 'bold',
            strikeThrough: txn.is_declined_transaction,
          };
        },
        mobileContents: (txn) => {
          return {
            text: formatAmount(txn.amount, 'USD'),
            textColor: txn.is_declined_transaction ? 'secondary' : 'emphasis',
            textWeight: 'bold',
            strikeThrough: txn.is_declined_transaction,
            caption: formatISO8601Timestamp(
              txn.date,
              'month-day-year-hour-minute-second',
              'UTC'
            ),
          };
        },
      },
    ];
  }, [props.showAccount, props.showGroup, props.showRoute]);

  return (
    <Table
      {...props}
      getRowProps={getRowProps ?? defaultRowProps}
      columns={columns}
    />
  );
};
