import React, { useState } from 'react';
import {
  useCheckItemAllocationInformationGet,
  useCheckItemAllocationPost,
  useCheckItemAllocationSearchGet,
  useCheckItemReturnDirectivePost,
} from 'src/hooks/reactQuery';
import { InternalOperatorOnly } from './internal-operator-only';
import { useServices } from 'src/hooks/use-services';
import { MultiImageTool } from './check-workflow/image-tool';
import { MICRResult, TranscribeMICR } from './check-workflow/transcribe-micr';
import { must } from 'src/lib/must';
import {
  CheckItemAllocationInformationGetResponse,
  CheckItemAllocationReturnDirectivePostParametersReason,
} from 'src/build/operations';
import { PageLayout } from './deprecated/PageLayout';
import { Button } from './deprecated/Button';
import { formatAmount } from 'shared/lib/formatting';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { ListItem, PropertyList } from 'shared/components/PropertyList';
import { compact } from 'lodash';
import { AlertList } from 'shared/components/AlertList';
import { Select } from 'shared/components/Select';

type DoAllocateButtonProps = {
  checkItemID: string;
  accountNumberID: string;
  checkTransferID?: string;
};

const DoAllocateButton = (props: DoAllocateButtonProps) => {
  const mutate = useCheckItemAllocationPost();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const doAllocate = async () => {
    setSubmitting(true);
    setError(null);
    try {
      await mutate.mutateAsync([
        props.checkItemID,
        {
          account_number_id: props.accountNumberID,
          check_transfer_id: props.checkTransferID,
        },
      ]);
    } catch (e) {
      setError('Something went wrong');
      setSubmitting(false);
    }
  };

  return (
    <div style={{ minWidth: 100 }}>
      <Button
        data-testid={props.checkItemID + '.button'}
        onClick={doAllocate}
        disabled={submitting}
      >
        Allocate
      </Button>
      {error && <div className="bg-danger p-1">error: {error}</div>}
    </div>
  );
};

const DoReturnForm = ({ checkItemID }: { checkItemID: string }) => {
  const mutate = useCheckItemReturnDirectivePost();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [reason, setReason] = useState<string | null>(null);

  const allowedReasons = [
    CheckItemAllocationReturnDirectivePostParametersReason.not_our_item,
    CheckItemAllocationReturnDirectivePostParametersReason.no_account_number_found,
  ];

  const doReturn = async () => {
    setSubmitting(true);
    setError(null);
    try {
      await mutate.mutateAsync([
        checkItemID,
        {
          reason:
            reason as CheckItemAllocationReturnDirectivePostParametersReason,
        },
      ]);
    } catch (e) {
      setError('Something went wrong');
      setSubmitting(false);
    }
  };

  return (
    <div
      style={{ minWidth: 100 }}
      className="flex shrink-0 items-stretch space-x-1"
    >
      <Select
        disabled={submitting}
        value={reason || ''}
        data-testid={checkItemID + '.return-reason'}
        onUpdate={(r) => setReason(r)}
        sections={[
          {
            options: [
              {
                label: '—',
                value: '',
              },
            ].concat(
              allowedReasons.map((r) => ({
                label: r,
                value: r,
              }))
            ),
          },
        ]}
      />
      <Button
        data-testid={checkItemID + '.return-button'}
        onClick={doReturn}
        disabled={submitting || !reason}
        className="bg-danger"
      >
        Return
      </Button>
      {error && <div className="bg-danger p-1">error: {error}</div>}
    </div>
  );
};

const DoNoCheckTransferFoundButton = ({
  checkItemID,
  accountNumberID,
}: {
  checkItemID: string;
  accountNumberID: string;
}) => {
  const mutate = useCheckItemReturnDirectivePost();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const doReturn = async () => {
    setSubmitting(true);
    setError(null);
    try {
      await mutate.mutateAsync([
        checkItemID,
        {
          account_number_id: accountNumberID,
          reason:
            CheckItemAllocationReturnDirectivePostParametersReason.altered_or_fictitious,
        },
      ]);
    } catch (e) {
      setError('Something went wrong');
      setSubmitting(false);
    }
  };

  return (
    <div
      style={{ minWidth: 100 }}
      className="flex shrink-0 items-stretch space-x-1"
    >
      <Button onClick={doReturn} disabled={submitting} className="bg-warning">
        Unable to find CheckTransfer: return as altered_or_fictitious
      </Button>
      {error && <div className="bg-danger p-1">error: {error}</div>}
    </div>
  );
};

type CheckTransferSearchProps = {
  micrResult: MICRResult;
  checkItemInformation: CheckItemAllocationInformationGetResponse;
};

const CheckTransferSearch = ({
  micrResult,
  checkItemInformation,
}: CheckTransferSearchProps) => {
  const {
    data: result,
    error,
    isLoading,
  } = useCheckItemAllocationSearchGet({
    account_number: micrResult.accountNumber,
    routing_number: micrResult.routingNumber,
    check_number: micrResult.auxiliaryOnUs || '',
  });

  return (
    <div>
      <ul>
        <li>
          Account number: <code>{micrResult.accountNumber}</code>
        </li>
        <li>
          Routing number: <code>{micrResult.routingNumber}</code>
        </li>
        <li>
          Check number: <code>{micrResult.auxiliaryOnUs}</code>
        </li>
        {result && (
          <>
            <li>
              API::AccountNumber:{' '}
              {result.account_number ? (
                <code>{result.account_number.id}</code>
              ) : (
                <em>not found</em>
              )}
            </li>
            {result.account_number && (
              <li>
                API::CheckTransfer:{' '}
                {result.check_transfer ? (
                  <span>
                    <code>{result.check_transfer.id}</code>
                    {result.check_transfer
                      .user_specified_source_account_number && ' 🆕'}{' '}
                    ({formatAmount(result.check_transfer.amount, 'USD')})
                  </span>
                ) : (
                  <em>not found</em>
                )}
              </li>
            )}
            {result.account_number && (
              <>
                <li>AccountNumber name: {result.account_number.name}</li>
                <li>Account name: {result.account_number.account_name}</li>
                <li>
                  Group: {result.account_number.group_nickname} (
                  {result.account_number.group_name})
                </li>
              </>
            )}
          </>
        )}
      </ul>

      <div>
        {isLoading && 'searching...'}
        {checkItemInformation.allocation_status ===
          'pending_operator_allocating' &&
          result &&
          result.account_number &&
          (result.has_inbound_checks || result.check_transfer) && (
            <DoAllocateButton
              checkItemID={checkItemInformation.id}
              accountNumberID={result.account_number.id}
              checkTransferID={result.check_transfer?.id}
            />
          )}
      </div>
      {error && (
        <div data-testid="searchError">
          <AlertList
            tasks={[
              {
                icon: 'info',
                title: error.message,
                body: error.response?.data?.message,
                key: '0',
                style: 'error',
              },
            ]}
          />
        </div>
      )}

      <div className="mt-4">
        {checkItemInformation.allocation_status ===
          'pending_operator_allocating' &&
          result &&
          result.account_number &&
          !result.check_transfer &&
          !result.has_inbound_checks && (
            <DoNoCheckTransferFoundButton
              checkItemID={checkItemInformation.id}
              accountNumberID={result.account_number.id}
            />
          )}
      </div>
    </div>
  );
};

export const CheckItemAllocating = () => {
  const { checkItemID } = useTypedParams(ROUTES.CHECK_ITEM_ALLOCATING);
  const { data: checkItemAllocationInformation, error } =
    useCheckItemAllocationInformationGet(checkItemID);
  const { operations } = useServices();

  const [step, setStep] = useState(0);
  const [maxStepReached, setMaxStepReached] = useState(0);
  const incrementStep = () => {
    setStep(step + 1);
    setMaxStepReached(Math.max(maxStepReached, step + 1));
  };
  const decrementStep = () => setStep(step - 1);

  const [showingImageIndex, setShowingImageIndex] = useState<number>(0);
  const incrementShowingImageIndex = () => {
    setShowingImageIndex(showingImageIndex + 1);
  };
  const decrementShowingImageIndex = () => {
    setShowingImageIndex(showingImageIndex - 1);
  };

  const [micrPixels, setMICRPixels] = useState<ImageData | null>(null);
  const [micrResult, setMICRResult] = useState<MICRResult | null>(null);

  if (error) {
    return <span data-testid="getInformationError">{error.message}</span>;
  }

  if (!checkItemAllocationInformation) {
    return <span>Loading...</span>;
  }

  const showingImageView =
    checkItemAllocationInformation.image_views[showingImageIndex];

  return (
    <PageLayout headline={`Allocate ${checkItemID} (step ${step + 1})`}>
      <InternalOperatorOnly additionalRoles={['check_reviewer']}>
        <PropertyList
          items={compact<ListItem>([
            {
              label: 'Current status',
              value: checkItemAllocationInformation.allocation_status,
            },
            checkItemAllocationInformation.operator_manual_queue_item && {
              label: 'Operator Manual Queue Item',
              value:
                checkItemAllocationInformation.operator_manual_queue_item.id,
            },
            checkItemAllocationInformation.operator_manual_queue_item && {
              label: 'Description',
              value:
                checkItemAllocationInformation.operator_manual_queue_item
                  .description,
            },
            {
              label: 'Amount',
              value: formatAmount(checkItemAllocationInformation.amount, 'USD'),
            },
            {
              label: 'On Us',
              value: checkItemAllocationInformation.on_us || '',
            },
            {
              label: 'Computed Account Number',
              value:
                checkItemAllocationInformation.computed_account_number || '',
            },
            {
              label: 'Payor Bank Routing Number',
              value:
                checkItemAllocationInformation.payor_bank_routing_number || '',
            },
            {
              label: 'Auxiliary On Us',
              value: checkItemAllocationInformation.auxiliary_on_us || '',
            },
          ])}
        />
        {step > 0 && (
          <button className="text-info hover:underline" onClick={decrementStep}>
            Previous Step
          </button>
        )}{' '}
        {maxStepReached > step && (
          <button className="text-info hover:underline" onClick={incrementStep}>
            Next Step
          </button>
        )}
        <div>
          {(() => {
            switch (step) {
              case 0:
                return (
                  <div>
                    <h4>Select MICR Line</h4>
                    <p>
                      Image {showingImageIndex + 1} of{' '}
                      {checkItemAllocationInformation.image_views.length} (
                      <span>
                        {showingImageView.view_side_indicator},{' '}
                        <span>{showingImageView.image_view_data_id}</span>
                      </span>
                      )
                      <span className="ml-1">
                        {showingImageIndex > 0 && (
                          <button
                            data-testid="previousImage"
                            onClick={decrementShowingImageIndex}
                          >
                            ⬅︎
                          </button>
                        )}
                        {showingImageIndex <
                          checkItemAllocationInformation.image_views.length -
                            1 && (
                          <button
                            data-testid="nextImage"
                            onClick={incrementShowingImageIndex}
                          >
                            ➡︎
                          </button>
                        )}
                      </span>
                    </p>

                    <MultiImageTool
                      selectionMode="area"
                      key={showingImageView.image_view_data_id}
                      url={`${operations}/check_item_image_view_data/${showingImageView.image_view_data_id}/view`}
                      onPixelsExtracted={(pixels) => {
                        setMICRPixels(pixels);
                        incrementStep();
                      }}
                    />
                  </div>
                );
              case 1:
                return (
                  <div>
                    <TranscribeMICR
                      micrPixels={must(micrPixels)}
                      micrResult={micrResult}
                      onUpdate={(newMicrResult) => {
                        setMICRResult(newMicrResult);
                      }}
                    />

                    {micrResult && (
                      <div>
                        <div className="mt-4">
                          <CheckTransferSearch
                            checkItemInformation={
                              checkItemAllocationInformation
                            }
                            micrResult={micrResult}
                          />
                        </div>

                        {checkItemAllocationInformation.allocation_status ===
                          'pending_operator_allocating' && (
                          <div className="mt-8">
                            <DoReturnForm checkItemID={checkItemID} />
                          </div>
                        )}
                      </div>
                    )}

                    {checkItemAllocationInformation.allocation_status !==
                      'pending_operator_allocating' && (
                      <div className="mt-4">
                        <div>
                          status:{' '}
                          <code>
                            {checkItemAllocationInformation.allocation_status}
                          </code>
                        </div>
                        <div>
                          <a
                            href={buildPath(
                              ROUTES.CHECK_ITEM_ALLOCATING_LIST,
                              {}
                            )}
                            className="cursor-pointer text-info"
                          >
                            Back to queue
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                );
            }
          })()}
        </div>
      </InternalOperatorOnly>
    </PageLayout>
  );
};
