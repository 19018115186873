import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { PageLayout } from './deprecated/PageLayout';
import { Button } from './deprecated/Button';
import {
  useAccountByAccountNumberGet,
  useEntitiesByName,
  useTransactionsByACHTraceNumberList,
  useOperatorGet,
  useTransfersByCheckNumberList,
} from 'src/hooks/reactQuery';
import { TextInput } from './deprecated/TextInput';
import { Section } from './deprecated/Section';
import { ItemList } from './deprecated/ItemList';
import { useCategoricals } from 'src/hooks/use-categoricals';
import { StyledLink } from './deprecated/StyledLink';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import {
  CompletedTransactionsTable,
  CompletedTransactionsTableProps,
} from './tables/CompletedTransactionTable';
import { CompletedTransactionListResponseDataItem } from 'src/build/operations';
import { InternalOperatorOnly } from './internal-operator-only';
import { buildPath, ROUTES } from 'src/lib/routes';
import { AlertList } from 'shared/components/AlertList';
import { humanize } from 'shared/lib/formatting';

const REDIRECT_MAPPING: Record<string, string> = {
  account_transfer: '/transfers',
  account: '/accounts',
  ach_transfer: '/transfers',
  card_decline: '/card_declines',
  card_payment: '/card_payments',
  card: '/cards',
  check_deposit: '/transfers',
  check_transfer: '/transfers',
  declined_transaction: '/declined_transactions',
  entity_setup_beneficial_owner_submission: '/beneficial_owners',
  entity: '/entities',
  group: '/groups',
  inbound_ach_transfer: '/transfers',
  inbound_wire_transfer: '/transfers',
  real_time_payments_transfer: '/transfers',
  transaction: '/transactions',
  wire_transfer: '/transfers',
};

export const getTypeFromObjectId = (raw: string): string => {
  const split = raw.split('_');
  return split.slice(0, split.length - 1).join('_');
};

const SearchById = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const prefix = getTypeFromObjectId(value);
        const next = REDIRECT_MAPPING[prefix];
        if (next) {
          navigate(`${next}/${value}`);
        } else {
          setError('Invalid prefix.');
        }
      }}
    >
      <div>
        <p>
          You can search for accounts, groups, entities, transactions, declined
          transactions, cards, card payments, card declines, or beneficial
          owners by their ID.
        </p>
        <label className="text-sm text-subtle">Object ID</label>
        <TextInput
          value={value}
          data-testid="searchInput"
          placeholder="thing_abcd1234"
          onChange={(s) => setValue(s)}
          className="mb-3"
        />
        {error && (
          <div data-testid="searchError">
            <AlertList
              tasks={[
                {
                  icon: 'info',
                  title: 'Error',
                  body: error,
                  key: '0',
                  style: 'error',
                },
              ]}
            />
          </div>
        )}
      </div>

      <Button type="submit" data-testid="searchIdButton">
        Submit
      </Button>
    </form>
  );
};

const SearchAnyId = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState('');

  return (
    <form
      onSubmit={() =>
        navigate(buildPath(ROUTES.OBJECT_VIEWER, { objectID: value }))
      }
    >
      <p>Search any id.</p>
      <TextInput
        value={value}
        data-testid="searchInput"
        onChange={(s) => setValue(s)}
        className="mb-3"
      />
      <Button type="submit">Submit</Button>
    </form>
  );
};

const EntitySearchResults = (props: { query: string }) => {
  const categoricals = useCategoricals();
  const { data } = useEntitiesByName({ name: props.query });

  if (!data || !categoricals) {
    return <></>;
  }

  return (
    <div>
      {data.data.length === 0 && <div>No results.</div>}
      {data.data.length > 0 && (
        <ItemList
          items={data.data.map((entity) => ({
            id: entity.id,
            text: `${entity.name} (${entity.structure})`,
            detail: `${humanize(entity.status)} - ${entity.id} - ${
              entity.group_id
            }`,
            href: buildPath(ROUTES.ENTITY, {
              entityId: entity.id,
            }),
          }))}
        />
      )}
    </div>
  );
};

const SearchByName = () => {
  const [value, setValue] = useState('');
  const [searchValue, setSearchValue] = useState('');

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSearchValue(value);
      }}
    >
      <div>
        <p>
          You can search for corporations, beneficial owners, natural people and
          trusts by their names.
        </p>
        <label className="text-sm text-subtle">Entity Name</label>
        <TextInput
          placeholder="Ian Crease"
          value={value}
          data-testid="nameSearchInput"
          onChange={(s) => setValue(s)}
          className="mb-3"
        />
      </div>
      <Button type="submit" data-testid="searchEntityButton">
        Submit
      </Button>
      {searchValue && (
        <>
          <EntitySearchResults
            data-testid="entityResults"
            query={searchValue}
          />
        </>
      )}
    </form>
  );
};

const SearchByACHTraceNumberResults = (props: { query: string }) => {
  const categoricals = useCategoricals();
  const { data, error } = useTransactionsByACHTraceNumberList({
    trace_number: props.query,
  });

  if (!data || !categoricals) {
    return <></>;
  }

  return (
    <TableStateWrapper<
      CompletedTransactionListResponseDataItem,
      CompletedTransactionsTableProps
    >
      table={CompletedTransactionsTable}
      data={data.data}
      error={error}
      style="detail"
      title="Search results"
      emptyTitle="Search results"
      emptySubtitle="No matches."
      showAccount={true}
      showGroup={true}
      showRoute={true}
      viewMoreHref=""
    />
  );
};

const SearchByACHTraceNumber = () => {
  const [value, setValue] = useState('');
  const [searchValue, setSearchValue] = useState('');

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSearchValue(value);
      }}
    >
      <div>
        <p>You can search by ACH trace number. This is a 15 digit number.</p>
        <label className="text-sm text-subtle">Trace Number</label>
        <TextInput
          placeholder="012345678901234"
          value={value}
          onChange={(s) => setValue(s)}
          className="mb-3"
        />
      </div>
      <Button type="submit">Submit</Button>
      {searchValue && <SearchByACHTraceNumberResults query={searchValue} />}
    </form>
  );
};

const AccountSearchResults = (props: { query: string }) => {
  const { data: account, error } = useAccountByAccountNumberGet({
    account_number: props.query,
  });

  if (error) {
    return <div>No account found for {props.query}</div>;
  }

  if (!account) {
    return <></>;
  }

  return (
    <div>
      <StyledLink
        to={buildPath(ROUTES.ACCOUNTS_DETAIL, {
          accountId: account.id,
        })}
      >
        {account.id}
      </StyledLink>
    </div>
  );
};

const SearchByAccountNumber = () => {
  const [value, setValue] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const { data: operator } = useOperatorGet({});

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSearchValue(value);
      }}
    >
      <div>
        <p>You can search for Increase accounts by their ten digit number.</p>
        <label className="text-sm text-subtle">
          {operator?.bank_routing_number
            ? `Account number on the ${operator.bank_routing_number} routing number.`
            : 'Account number'}
        </label>
        <TextInput
          placeholder="1234567890"
          value={value}
          onChange={(s) => setValue(s)}
          className="mb-3"
        />
      </div>
      <Button type="submit">Submit</Button>
      {searchValue && (
        <>
          <AccountSearchResults
            data-testid="accountResults"
            query={searchValue}
          />
        </>
      )}
    </form>
  );
};

const CheckTransferSearchResults = (props: { query: string }) => {
  const { data: transfers } = useTransfersByCheckNumberList({
    check_number: props.query,
  });

  if (!transfers) {
    return <></>;
  }

  if (transfers.data.length === 0) {
    return <div>No check transfers found for {props.query}</div>;
  }

  return (
    <div className="flex flex-col gap-1">
      {transfers.data.map((transfer) => (
        <StyledLink
          to={buildPath(ROUTES.TRANSFER_DETAIL, {
            transferID: transfer.id,
          })}
          key={transfer.id}
        >
          {transfer.id}
        </StyledLink>
      ))}
    </div>
  );
};

const SearchByCheckNumber = () => {
  const [value, setValue] = useState('');
  const [searchValue, setSearchValue] = useState('');

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setSearchValue(value);
      }}
    >
      <div>
        <p>You can search for check transfers by the check number.</p>
        <label className="text-sm text-subtle">Check number</label>
        <TextInput
          placeholder="123456789"
          value={value}
          onChange={(s) => setValue(s)}
          className="mb-3"
        />
      </div>
      <Button type="submit">Submit</Button>
      {searchValue && (
        <>
          <CheckTransferSearchResults
            data-testid="checkNumberResults"
            query={searchValue}
          />
        </>
      )}
    </form>
  );
};

export const Search = () => {
  return (
    <PageLayout id="Search" headline="Search">
      <InternalOperatorOnly>
        <Section header="Internal Object Search">
          <SearchAnyId />
        </Section>
      </InternalOperatorOnly>
      <Section header="General Search">
        <SearchById />
      </Section>
      <Section header="Search Entities">
        <SearchByName />
      </Section>
      <Section header="Search ACH/Wire Account Numbers">
        <SearchByAccountNumber />
      </Section>
      <Section header="Search ACH Trace Numbers">
        <SearchByACHTraceNumber />
      </Section>
      <Section header="Search Check Numbers">
        <SearchByCheckNumber />
      </Section>
    </PageLayout>
  );
};
