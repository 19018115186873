import React, { useMemo } from 'react';
import { useFedwireInboundServiceMessagesListInfinite } from 'src/hooks/reactQuery';
import { FedwireInboundServiceMessagesTable } from '../tables/FedwireInboundServiceMessagesTable';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { makeStatusFilter } from 'src/lib/tableFilterHelpers';
import { compact } from 'lodash';
import { TableFilter } from 'shared/components/Table/filters';
import { ROUTES } from 'src/lib/routes';
import { useSearchParamsState } from '../../../shared/hooks/useTypedSearchParamsState';

export const FedwireInboundServiceMessageListTab = () => {
  const [statuses, setStatuses] = useSearchParamsState(
    ROUTES.FEDWIRE_INBOUND.SERVICE_MESSAGES,
    'statuses'
  );

  const listResult = useFedwireInboundServiceMessagesListInfinite({
    statuses: statuses.length > 0 ? statuses : undefined,
  });

  const filters: TableFilter[] = useMemo(
    () =>
      compact([
        makeStatusFilter(statuses, setStatuses, {
          ignored: 'Ignored',
          pending_review: 'Pending review',
          flagged_by_operator: 'Flagged by operator',
          requires_attention: 'Requires attention',
          actioned: 'Actioned',
        }),
      ]),
    [statuses, setStatuses]
  );

  return (
    <TableStateWrapper
      table={FedwireInboundServiceMessagesTable}
      style="primary"
      {...listResult}
      emptyTitle="No Fedwire service messages"
      emptySubtitle="There are no Fedwire service messages to display"
      filters={filters}
    />
  );
};
