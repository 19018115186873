import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CheckDepositReview } from './check-deposit-review';
import { CheckDepositReviewList } from './check-deposit-review-list';

import { ACHReturnRate } from './ach-return-rate';
import { ACHReturnRates } from './ach-return-rates';
import { Entity } from './entity';
import { ObjectViewer } from './object-viewer';
import { Operations } from './operations';
import { GroupQueueItems } from './group-queue-items';
import { OperatorListPage } from './list_pages/OperatorListPage';
import { Results } from './results';
import { TransactionDetail } from './transaction-detail';
import { TransferReviewing } from './transfer-reviewing';
import { OutboundACHFiles } from './outbound-ach-files';
import { IdentityDocumentList } from './identity-document-list';
import { IdentityDocumentReview } from './identity-document-review';
import { ManualTaskList } from 'src/components/manual-task-list';
import { ManualTaskDetail } from 'src/components/manual-task-detail';
import { ManualTaskRunDetail } from 'src/components/manual-task-run-detail';
import { Unit21Object } from './unit21-object';
import { CheckItemAllocating } from './check-item-allocating';
import { Search } from './search';
import { NotFound } from './not-found';
import { Layout } from './layout';
import { EntitySetupBeneficialOwnerSubmissionPage } from './entity-setup-beneficial-owner-submission-page';
import { Groups } from './list_pages/GroupListPage';
import { UserSessions } from './user-sessions';
import { CheckItemsToAllocate } from './check-items-to-allocate';
import { PlatformComplaintListSubmission } from './platform-complaint-list-submission';
import { AllFederalReserveLimits } from './all-federal-reserve-limits';
import { FederalReserveLimits } from './federal-reserve-limits';
import { Gates } from './gates';
import { PlatformVendorListSubmission } from './platform-vendor-list-submission';
import { PlatformComplianceMetricsSubmission } from './platform-compliance-metrics-submission';
import { PlatformComplianceSubmissions } from './platform-compliance-submissions';
import { CardDetailPage } from './detail_pages/CardDetailPage';
import { CardProfileImages } from './card-profile-images';
import { PhysicalCardImages } from './physical-card-images';
import { ACHOriginationVolumeMonitoring } from './ach-origination-volume-monitoring';
import { LateReturnRequestListPage } from './list_pages/LateReturnRequestListPage';
import { LateReturnRequestCreate } from './late-return-request-create';
import { TransferLookup } from './transfer-lookup';
import { BookkeepingAccountDetailPage } from './detail_pages/BookkeepingAccountDetailPage';
import { DeclinedTransactionDetail } from './declined-transaction-detail';
import { RealTimePaymentsPrefundedPosition } from './real-time-payments-prefunded-position';
import { GroupFeePlansPage, ProgramFeePlansPage } from './fee-plans-list';
import { GroupFeeStatementsList } from './group-fee-statements-list';
import { RoutingNumberList } from './routing-number-list';
import { PendingTransactionDetail } from './pending-transaction-detail';
import { ObligationDocuments } from './obligation-documents';
import { ObligationDocumentDetail } from './obligation-documents-detail';
import { Attestations } from './attestations';
import { AttestationDetail } from './attestation-detail';
import { TransferDetailPage } from './detail_pages/TransferDetailPage';
import { GroupCardPaymentDetail } from './group-card-payment-detail';
import { GroupCardDeclineDetail } from './group-card-decline-detail';
import { Flags } from './flags';
import { UnusualActivityReports } from './unusual-activity-reports';
import { DirectUnusualActivityReport } from './unusual-activity-report';
import { DirectComplaintEntries } from './complaint-entries';
import { ComplianceDocuments } from './compliance-documents';
import { LateReturnRequestDetailPage } from './detail_pages/LateReturnRequestDetailPage';
import { PlatformComplaintListTab } from './list_tabs/PlatformComplaintListTab';
import { FedwireInboundServiceMessageListTab } from './list_tabs/FedwireInboundServiceMessageListTab';
import { PlatformUnusualActivityReportListTab } from './list_tabs/PlatformUnusualActivityReportListTab';
import { PlatformUnusualActivityReportDetailPage } from './detail_pages/PlatformUnusualActivityReportDetailPage';
import { CardListPage } from './list_pages/CardListPage';
import { CardDisputeListPage } from './list_pages/CardDisputeListPage';
import { CompletedTransactionListPage } from './list_tabs/CompletedTransactionListTab';
import { AccountNumberListPage } from './list_pages/AccountNumberListPage';
import { AccountNumberDetailPage } from './detail_pages/AccountNumberDetailPage';
import { AccountListPage } from './list_pages/AccountListPage';
import { AccountDetailPage } from './detail_pages/AccountDetailPage';
import { BankProgramDetailPage } from './detail_pages/BankProgramDetailPage';
import { BankProgramListPage } from './list_pages/BankProgramListPage';
import { EntityListPage } from './list_pages/EntityListPage';
import { CustomerIdentificationProgramTestingBatchListPage } from './list_pages/CustomerIdentificationProgramTestingBatchListPage';
import { CustomerIdentificationProgramTestingEntryListPage } from './list_pages/CustomerIdentificationProgramTestingEntryListPage';
import { CustomerIdentificationProgramTestingEntryDetailPage } from './detail_pages/CustomerIdentificationProgramTestingEntryDetailPage';
import { RoleListPage } from './list_pages/RoleListPage';
import { GroupDetailPage } from './detail_pages/GroupDetailPage';
import { BookkeepingAccountListPage } from './list_pages/BookkeepingAccountListPage';
import { PlatformComplaintDetailPage } from './detail_pages/PlatformComplaintDetailPage';
import { UnusualActivityReportsWrapperPage } from './wrapper_pages/UnusualActivityReportsWrapperPage';
import { ComplaintsWrapperPage } from './wrapper_pages/ComplaintsWrapperPage';
import { ResultListPage } from './list_pages/ResultListPage';
import { ResultDetailPage } from './detail_pages/ResultDetailPage';
import { ProgramListPage } from './list_pages/ProgramListPage';
import { DocumentRequestListPage } from './list_pages/DocumentRequestListPage';
import { ProgramDetailPage } from './detail_pages/ProgramDetailPage';
import { PendingTransactionListPage } from './list_tabs/PendingTransactionListTab';
import { TransactionsWrapperPage } from './wrapper_pages/TransactionsWrapperPage';
import { CashReconciliation } from './cash-reconciliation';
import { ControlConfigurationsListPage } from './list_pages/ControlConfigurationsListPage';
import { ControlConfigurationDetailPage } from './detail_pages/ControlConfigurationDetailPage';
import { RampBusinessAccountOnboardingSurveyDetailPage } from './detail_pages/RampBusinessAccountOnboardingSurveyDetailPage';
import { ControlRecordDetailPage } from './detail_pages/ControlRecordDetailPage';
import { ControlRecordListPage } from './list_pages/ControlRecordListPage';
import { RampBusinessAccountOnboardingSurveyListPage } from './list_pages/RampBusinessAccountOnboardingSurveyListPage';
import { UserListPage } from './list_pages/UserListPage';
import { UserDetailPage } from './detail_pages/UserDetailPage';
import { PlatformComplaintListSubmissionListPage } from './list_pages/PlatformComplaintListSubmissionListPage';
import { PlatformComplianceMetricsSubmissionListPage } from './list_pages/PlatformComplianceMetricsSubmissionListPage';
import { PlatformVendorListSubmissionListPage } from './list_pages/PlatformVendorListSubmissionListPage';
import { CustomerIdentificationProgramTestingBatchDetailPage } from './detail_pages/CustomerIdentificationProgramTestingBatchDetailPage';
import { ROUTES, buildPath } from 'src/lib/routes';
import { InboundMailItemList } from './inbound-mail-item-list';
import { BankFeeStatementDetailPage } from './detail_pages/BankFeeStatementDetailPage';
import { EntityClusterListPage } from './list_pages/EntityClusterListPage';
import { EntityClusterDetailPage } from './detail_pages/EntityClusterDetailPage';
import { OAuthApplicationDetailPage } from './detail_pages/OAuthApplicationDetailPage';
import { OAuthApplicationListPage } from './list_pages/OAuthApplicationListPage';
import { OAuthConnectionListPage } from './list_pages/OAuthConnectListPage';
import { VendorListPage } from './list_pages/PlatformVendorListPage';
import { CommericalOnboardingSurveyListPage } from './list_pages/CommericalOnboardingSurveyListPage';
import { ConsumerOnboardingSurveyListPage } from './list_pages/ConsumerOnboardingSurveyListPage';
import { ConsumerOnboardingSurveyDetailPage } from './detail_pages/ConsumerOnboardingSurveyDetailPage';
import { CommercialOnboardingSurveyDetailPage } from './detail_pages/CommercialOnboardingSurveyDetailPage';
import { OnboardingSurveysIndexPage } from './onboarding-survey';
import { OperatorManualQueueItems } from './operator-manual-queue-items';
import { ManagementInformationSystemWrapperPage } from './wrapper_pages/ManagementInformationSystemWrapperPage';
import { OverviewPage } from './management-information-system/OverviewPage';
import { CompliancePage } from './management-information-system/CompliancePage';
import { VolumesPage } from './management-information-system/VolumesPage';
import { AccountsPage } from './management-information-system/AccountsPage';
import { SanctionsScreeningTriggerWordsListPage } from './list_pages/SanctionsScreeningTriggerWordsListPage';
import { ConsolidatedScreeningListEntryPage } from './list_pages/ConsolidatedScreeningListEntryPage';
import { DocumentRequestDetailPage } from './detail_pages/DocumentRequestDetailPage';
import { SanctionsScreeningReviewDetailPage } from './queues/SanctionsScreeningReviewDetailPage';
import { SanctionsScreeningReviewsListPage } from './queues/SanctionsScreeningReviewsListPage';
import { DocumentRequestSchedulePage } from './detail_pages/DocumentRequestSchedulePage';
import { PlatformFinancialReportSubmission } from './platform-financial-report-submission';
import { FedwireInboundWrapperPage } from './wrapper_pages/FedwireInboundWrapperPage';
import { FedwireInboundServiceMessageDetailPage } from './detail_pages/FedwireInboundServiceMessageDetailPage';
import { FedwireInboundRequestForReversalDetailPage } from './detail_pages/FedwireInboundRequestForReversalDetailPage';
import { FedwireInboundRequestForReversalListTab } from './list_tabs/FedwireInboundRequestForReversalListTab';
import { TransactionAccountingEntriesForTrace } from './detail_pages/TransactionAccountingEntriesForTrace';
import { EntityAddressVerificationListPage } from './list_pages/EntityAddressVerificationListPage';

export const Application = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          index
          element={
            <Navigate to={buildPath(ROUTES.BANK_PROGRAMS_LIST, {})} replace />
          }
        />
        <Route path={ROUTES.OBJECT_VIEWER.path} element={<ObjectViewer />} />
        <Route
          path={ROUTES.UNIT21_OBJECT_VIEWER.path}
          element={<Unit21Object />}
        />
        <Route path={ROUTES.OPERATIONS.path} element={<Operations />} />
        <Route
          path={ROUTES.MANAGEMENT_INFORMATION_SYSTEM.path}
          element={<ManagementInformationSystemWrapperPage />}
        >
          <Route
            path={ROUTES.MANAGEMENT_INFORMATION_SYSTEM.OVERVIEW.path}
            element={<OverviewPage />}
          />
          <Route
            path={ROUTES.MANAGEMENT_INFORMATION_SYSTEM.VOLUME.path}
            element={<VolumesPage />}
          />
          <Route
            path={ROUTES.MANAGEMENT_INFORMATION_SYSTEM.ACCOUNTS.path}
            element={<AccountsPage />}
          />
          <Route
            path={ROUTES.MANAGEMENT_INFORMATION_SYSTEM.COMPLIANCE.path}
            element={<CompliancePage />}
          />
        </Route>
        <Route path={ROUTES.PARTNERS.path} element={<OperatorListPage />} />
        <Route path={ROUTES.SEARCH.path} element={<Search />} />
        <Route path={ROUTES.RESULTS.path} element={<Results />} />
        <Route
          path={ROUTES.RESULT_STATUS.path}
          element={<ResultDetailPage />}
        />
        <Route path={ROUTES.RESULTS_V2.path} element={<ResultListPage />} />
        <Route path={ROUTES.GROUPS_LIST.path} element={<Groups />} />
        <Route
          path={ROUTES.USER_SESSIONS_LIST.path}
          element={<UserSessions />}
        />
        <Route path={ROUTES.ENTITY.path} element={<Entity />} />
        <Route
          path={ROUTES.ENTITY_ADDRESS_VERIFICATION.path}
          element={<EntityAddressVerificationListPage />}
        />
        <Route
          path={ROUTES.ENTITY_CLUSTER_DETAIL.path}
          element={<EntityClusterDetailPage />}
        />
        <Route
          path={ROUTES.ADDRESS_REVIEWING.path}
          element={
            <Navigate
              to={buildPath(
                ROUTES.ENTITY_CLUSTERS_LIST,
                {},
                {
                  statuses: ['pending_reviewing'],
                  categories: ['address_match'],
                  count_or_greater: 10,
                }
              )}
              replace
            />
          }
        />
        <Route
          path={ROUTES.ENTITY_CLUSTERS_LIST.path}
          element={<EntityClusterListPage />}
        />
        <Route
          path={ROUTES.BENEFICIAL_OWNER.path}
          element={<EntitySetupBeneficialOwnerSubmissionPage />}
        />
        <Route path={ROUTES.GROUP_GATES.path} element={<Gates />} />
        <Route
          path={ROUTES.DOCUMENT_REQUESTS_LIST.path}
          element={<DocumentRequestListPage />}
        />
        <Route
          path={ROUTES.DOCUMENT_REQUEST_DETAIL.path}
          element={<DocumentRequestDetailPage />}
        />
        <Route
          path={ROUTES.DOCUMENT_REQUEST_SCHEDULE.path}
          element={<DocumentRequestSchedulePage />}
        />
        <Route
          path={ROUTES.GROUP_FEE_PLANS.path}
          element={<GroupFeePlansPage />}
        />
        <Route
          path={ROUTES.GROUP_PROGRAM_FEE_PLANS.path}
          element={<ProgramFeePlansPage />}
        />
        <Route
          path={ROUTES.GROUP_FEE_STATEMENTS.path}
          element={<GroupFeeStatementsList />}
        />
        <Route path={ROUTES.CARD.path} element={<CardDetailPage />} />
        <Route
          path={ROUTES.CARD_DECLINE.path}
          element={<GroupCardDeclineDetail />}
        />
        <Route
          path={ROUTES.CARD_PAYMENT.path}
          element={<GroupCardPaymentDetail />}
        />
        <Route
          path={ROUTES.MANUAL_TASKS_LIST.path}
          element={<ManualTaskList />}
        />
        <Route path={ROUTES.MANUAL_TASK.path} element={<ManualTaskDetail />} />
        <Route
          path={ROUTES.MANUAL_TASK_RUN.path}
          element={<ManualTaskRunDetail />}
        />
        <Route
          path={ROUTES.CARD_PROFILE_IMAGES.path}
          element={<CardProfileImages />}
        />
        <Route
          path={ROUTES.CARD_PRINTER_CARD_PROFILE_IMAGES.path}
          element={<PhysicalCardImages />}
        />
        <Route
          path={ROUTES.CHECK_DEPOSIT_REVIEW_LIST.path}
          element={<CheckDepositReviewList />}
        />
        <Route
          path={ROUTES.CHECK_DEPOSIT_REVIEW.path}
          element={<CheckDepositReview />}
        />
        <Route
          path={ROUTES.CHECK_ITEM_ALLOCATING_LIST.path}
          element={<CheckItemsToAllocate />}
        />
        <Route
          path={ROUTES.CHECK_ITEM_ALLOCATING.path}
          element={<CheckItemAllocating />}
        />
        <Route
          path={ROUTES.IDENTITY_DOCUMENTS_LIST.path}
          element={<IdentityDocumentList />}
        />
        <Route
          path={ROUTES.IDENTITY_DOCUMENT_REVIEW.path}
          element={<IdentityDocumentReview />}
        />
        <Route
          path={ROUTES.INBOUND_MAIL_ITEM_REVIEW_LIST.path}
          element={<InboundMailItemList />}
        />
        <Route
          path={ROUTES.QUEUES.path}
          element={<OperatorManualQueueItems />}
        />
        <Route path={ROUTES.GROUP_QUEUE.path} element={<GroupQueueItems />} />
        <Route
          path={ROUTES.TRANSACTION_DETAIL.path}
          element={<TransactionDetail />}
        />
        <Route
          path={ROUTES.TRANSFER_DETAIL.path}
          element={<TransferDetailPage />}
        />
        <Route path={ROUTES.FLAGS_LIST.path} element={<Flags />} />
        <Route
          path={ROUTES.DECLINED_TRANSACTION_DETAIL.path}
          element={<DeclinedTransactionDetail />}
        />
        <Route
          path={ROUTES.PENDING_TRANSACTION_DETAIL.path}
          element={<PendingTransactionDetail />}
        />
        <Route
          path={ROUTES.PLATFORM_COMPLAINT_LIST_SUBMISSIONS_LIST.path}
          element={<PlatformComplaintListSubmissionListPage />}
        />
        <Route
          path={ROUTES.PLATFORM_COMPLAINT_LIST_SUBMISSION_DETAIL.path}
          element={<PlatformComplaintListSubmission />}
        />
        <Route
          path={ROUTES.PLATFORM_VENDOR_LIST_SUBMISSIONS_LIST.path}
          element={<PlatformVendorListSubmissionListPage />}
        />
        <Route
          path={ROUTES.PLATFORM_VENDOR_LIST_SUBMISSIONS_DETAIL.path}
          element={<PlatformVendorListSubmission />}
        />
        <Route
          path={ROUTES.PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_LIST.path}
          element={<PlatformComplianceMetricsSubmissionListPage />}
        />
        <Route
          path={ROUTES.PLATFORM_COMPLIANCE_METRICS_SUBMISSIONS_DETAIL.path}
          element={<PlatformComplianceMetricsSubmission />}
        />
        <Route
          path={ROUTES.PLATFORM_FINANCIAL_REPORT_SUBMISSIONS_DETAIL.path}
          element={<PlatformFinancialReportSubmission />}
        />
        <Route
          path={ROUTES.UNUSUAL_ACTIVITY_REPORTS.path}
          element={<UnusualActivityReportsWrapperPage />}
        >
          <Route index element={<Navigate to="platform" replace />} />
          <Route
            path={ROUTES.UNUSUAL_ACTIVITY_REPORTS.PLATFORM.path}
            element={<PlatformUnusualActivityReportListTab />}
          />
          <Route
            path={ROUTES.UNUSUAL_ACTIVITY_REPORTS.DIRECT.path}
            element={<UnusualActivityReports />}
          />
        </Route>
        <Route
          path={ROUTES.UNUSUAL_ACTIVITY_REPORTS_FOR_PLATFORM_DETAIL.path}
          element={<PlatformUnusualActivityReportDetailPage />}
        />
        <Route
          path={ROUTES.UNUSUAL_ACTIVITY_REPORTS.DIRECT.DETAILS.path}
          element={<DirectUnusualActivityReport />}
        />
        <Route
          path={ROUTES.ACH_RETURN_RATES_DETAIL.path}
          element={<ACHReturnRate />}
        />
        <Route
          path={ROUTES.ACH_RETURN_RATES_LIST.path}
          element={<ACHReturnRates />}
        />
        <Route
          path={ROUTES.BOOKKEEPING_ACCOUNTS_LIST.path}
          element={<BookkeepingAccountListPage />}
        />
        <Route
          path={ROUTES.BOOKKEEPING_ACCOUNTS_DETAIL.path}
          element={<BookkeepingAccountDetailPage />}
        />
        <Route
          path={ROUTES.LATE_RETURN_REQUESTS_LIST.path}
          element={<LateReturnRequestListPage />}
        />
        <Route
          path={ROUTES.LATE_RETURN_REQUESTS_DETAIL.path}
          element={<LateReturnRequestDetailPage />}
        />
        <Route
          path={ROUTES.LATE_RETURN_REQUESTS_CREATE.path}
          element={<LateReturnRequestCreate />}
        />
        <Route
          path={ROUTES.TRANSFER_LOOKUP.path}
          element={<TransferLookup />}
        />
        <Route
          path={ROUTES.TRANSFER_REVIEWING.path}
          element={<TransferReviewing />}
        />
        <Route
          path={ROUTES.OUTBOUND_ACH_FILES_LIST.path}
          element={<OutboundACHFiles />}
        />
        <Route
          path={ROUTES.ACH_ORIGINATION_VOLUME_MONITORING.path}
          element={<ACHOriginationVolumeMonitoring />}
        />
        <Route
          path={ROUTES.REAL_TIME_PAYMENTS_PREFUNDED_POSITION.path}
          element={<RealTimePaymentsPrefundedPosition />}
        />
        <Route
          path={ROUTES.CASH_RECONCILIATION.path}
          element={<CashReconciliation />}
        />
        <Route
          path={ROUTES.TRANSACTION_ACCOUNTING_ENTRIES_FOR_TRACE.path}
          element={<TransactionAccountingEntriesForTrace />}
        />
        <Route
          path={ROUTES.FEDWIRE_INBOUND.path}
          element={<FedwireInboundWrapperPage />}
        >
          <Route
            index
            element={
              <Navigate
                to={buildPath(ROUTES.FEDWIRE_INBOUND.SERVICE_MESSAGES, {})}
                replace
              />
            }
          />
          <Route
            path={ROUTES.FEDWIRE_INBOUND.SERVICE_MESSAGES.path}
            element={<FedwireInboundServiceMessageListTab />}
          />
          <Route
            path={ROUTES.FEDWIRE_INBOUND.REQUEST_FOR_REVERSALS.path}
            element={<FedwireInboundRequestForReversalListTab />}
          />
        </Route>
        <Route
          path={ROUTES.FEDWIRE_INBOUND_SERVICE_MESSAGES_DETAIL.path}
          element={<FedwireInboundServiceMessageDetailPage />}
        />
        <Route
          path={ROUTES.FEDWIRE_INBOUND_REQUEST_FOR_REVERSALS_DETAIL.path}
          element={<FedwireInboundRequestForReversalDetailPage />}
        />
        <Route
          path={ROUTES.SANCTIONS_SCREENING_REVIEWS_LIST.path}
          element={<SanctionsScreeningReviewsListPage />}
        />
        <Route
          path={ROUTES.SANCTIONS_SCREENING_REVIEW_DETAIL.path}
          element={<SanctionsScreeningReviewDetailPage />}
        />
        <Route
          path={ROUTES.SANCTIONS_SCREENING_TRIGGER_WORDS_LIST.path}
          element={<SanctionsScreeningTriggerWordsListPage />}
        />
        <Route
          path={ROUTES.SANCTIONS_SCREENING_ENTRY_DETAILS.path}
          element={<ConsolidatedScreeningListEntryPage />}
        />
        <Route
          path={ROUTES.PLATFORM_COMPLIANCE_SUBMISSIONS.path}
          element={<PlatformComplianceSubmissions />}
        />
        <Route
          path={ROUTES.ROUTING_NUMBERS.path}
          element={<RoutingNumberList />}
        />
        <Route
          path={ROUTES.OBLIGATION_DOCUMENTS.path}
          element={<ObligationDocuments />}
        />
        <Route
          path={ROUTES.OBLIGATION_DOCUMENTS_DETAILS.path}
          element={<ObligationDocumentDetail />}
        />
        <Route
          path={ROUTES.ATTESTATIONS_LIST.path}
          element={<Attestations />}
        />
        <Route
          path={ROUTES.ATTESTATIONS_DETAIL.path}
          element={<AttestationDetail />}
        />
        <Route
          path={ROUTES.COMPLIANCE_DOCUMENTS.path}
          element={<ComplianceDocuments />}
        />
        <Route
          path={ROUTES.COMPLAINTS.path}
          element={<ComplaintsWrapperPage />}
        >
          <Route
            index
            element={
              <Navigate
                to={buildPath(ROUTES.COMPLAINTS.PLATFORM_COMPLAINTS, {})}
                replace
              />
            }
          />
          <Route
            path={ROUTES.COMPLAINTS.PLATFORM_COMPLAINTS.path}
            element={<PlatformComplaintListTab />}
          />
          <Route
            path={ROUTES.COMPLAINTS.DIRECT_COMPLAINTS.path}
            element={<DirectComplaintEntries />}
          />
        </Route>
        <Route
          path={ROUTES.PLATFORM_COMPLAINTS_DETAIL.path}
          element={<PlatformComplaintDetailPage />}
        />
        <Route
          path={ROUTES.PLATFORM_VENDORS.path}
          element={<VendorListPage />}
        />
        <Route path={ROUTES.CARDS_LIST.path} element={<CardListPage />} />
        <Route
          path={ROUTES.CARD_DISPUTES_LIST.path}
          element={<CardDisputeListPage />}
        />
        <Route element={<TransactionsWrapperPage />}>
          <Route
            path={ROUTES.TRANSACTIONS_LIST.path}
            element={<CompletedTransactionListPage />}
          />
          <Route
            path={ROUTES.PENDING_TRANSACTIONS_LIST.path}
            element={<PendingTransactionListPage />}
          />
        </Route>
        <Route
          path={ROUTES.ACCOUNT_NUMBERS_LIST.path}
          element={<AccountNumberListPage />}
        />
        <Route
          path={ROUTES.ACCOUNT_NUMBERS_DETAILS.path}
          element={<AccountNumberDetailPage />}
        />
        <Route path={ROUTES.ACCOUNTS_LIST.path} element={<AccountListPage />} />
        <Route
          path={ROUTES.ACCOUNTS_DETAIL.path}
          element={<AccountDetailPage />}
        />
        <Route path={ROUTES.PROGRAMS_LIST.path} element={<ProgramListPage />} />
        <Route
          path={ROUTES.PROGRAMS_DETAIL.path}
          element={<ProgramDetailPage />}
        />
        <Route
          path={ROUTES.BANK_PROGRAMS_LIST.path}
          element={<BankProgramListPage />}
        />
        <Route
          path={ROUTES.BANK_PROGRAMS_DETAIL.path}
          element={<BankProgramDetailPage />}
        />
        <Route
          path={ROUTES.BANK_FEE_STATEMENTS_DETAIL.path}
          element={<BankFeeStatementDetailPage />}
        />
        <Route
          path={ROUTES.ONBOARDING_SURVEYS_INDEX.path}
          element={<OnboardingSurveysIndexPage />}
        />
        <Route
          path={ROUTES.RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_LIST.path}
          element={<RampBusinessAccountOnboardingSurveyListPage />}
        />
        <Route
          path={ROUTES.RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_DETAILS.path}
          element={<RampBusinessAccountOnboardingSurveyDetailPage />}
        />
        <Route
          path={ROUTES.CONSUMER_ONBOARDING_SURVEYS_LIST.path}
          element={<ConsumerOnboardingSurveyListPage />}
        />
        <Route
          path={ROUTES.CONSUMER_ONBOARDING_SURVEYS_DETAILS.path}
          element={<ConsumerOnboardingSurveyDetailPage />}
        />
        <Route
          path={ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_LIST.path}
          element={<CommericalOnboardingSurveyListPage />}
        />
        <Route
          path={ROUTES.COMMERCIAL_ONBOARDING_SURVEYS_DETAILS.path}
          element={<CommercialOnboardingSurveyDetailPage />}
        />
        <Route
          path={ROUTES.CONTROLS_LIST.path}
          element={<ControlConfigurationsListPage />}
        />
        <Route
          path={ROUTES.CONTROLS_DETAIL.path}
          element={<ControlConfigurationDetailPage />}
        />
        <Route
          path={ROUTES.CONTROL_RECORDS_LIST.path}
          element={<ControlRecordListPage />}
        />
        <Route
          path={ROUTES.CONTROL_RECORDS_DETAILS.path}
          element={<ControlRecordDetailPage />}
        />
        <Route
          path={
            ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_BATCHES_LIST.path
          }
          element={<CustomerIdentificationProgramTestingBatchListPage />}
        />
        <Route
          path={
            ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_BATCHES_DETAILS.path
          }
          element={<CustomerIdentificationProgramTestingBatchDetailPage />}
        />
        <Route
          path={
            ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_LIST.path
          }
          element={<CustomerIdentificationProgramTestingEntryListPage />}
        />
        <Route
          path={
            ROUTES.CUSTOMER_IDENTIFICATION_PROGRAM_TESTING_ENTRIES_DETAILS.path
          }
          element={<CustomerIdentificationProgramTestingEntryDetailPage />}
        />
        <Route path={ROUTES.ENTITIES_LIST.path} element={<EntityListPage />} />
        <Route path={ROUTES.ROLES_LIST.path} element={<RoleListPage />} />
        <Route path={ROUTES.USERS_LIST.path} element={<UserListPage />} />
        <Route path={ROUTES.USERS_DETAIL.path} element={<UserDetailPage />} />
        <Route path={ROUTES.GROUPS_DETAIL.path} element={<GroupDetailPage />} />
        <Route
          path={ROUTES.FEDERAL_RESERVE_LIMITS_LIST.path}
          element={<AllFederalReserveLimits />}
        />
        <Route
          path={ROUTES.FEDERAL_RESERVE_LIMIT_DETAILS.path}
          element={<FederalReserveLimits />}
        />
        <Route
          path={ROUTES.FEDERAL_RESERVE_LIMIT_DETAILS.path}
          element={<FederalReserveLimits />}
        />
        <Route
          path={ROUTES.OAUTH_APPLICATION_DETAIL.path}
          element={<OAuthApplicationDetailPage />}
        />
        <Route
          path={ROUTES.OAUTH_APPLICATION_LIST.path}
          element={<OAuthApplicationListPage />}
        />
        <Route
          path={ROUTES.OAUTH_CONNECTION_LIST.path}
          element={<OAuthConnectionListPage />}
        />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
