import React from 'react';
import { useDeclinedTransactionGet } from 'src/hooks/reactQuery';
import { OperatorNotes } from './operator-notes';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { StyledLink } from './deprecated/StyledLink';
import { GroupLockedBadge } from './group-locked-badge';

import { formatAmount } from 'shared/lib/formatting';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { PropertyList } from 'shared/components/PropertyList';
import { Body } from 'shared/components/Text';

function flatObject(
  object: object,
  prefix = ''
): { key: string; value: unknown }[] {
  return Object.entries(object)
    .map(([key, value]) => {
      if (value && typeof value == 'object') {
        return flatObject(value, prefix + key + '.');
      } else {
        return [{ key: `${prefix}${key}`, value }];
      }
    })
    .reduce((a, b) => a.concat(b), []);
}

export const DeclinedTransactionDetail = () => {
  const { declinedTransactionID } = useTypedParams(
    ROUTES.DECLINED_TRANSACTION_DETAIL
  );

  const { data: declinedTransaction } = useDeclinedTransactionGet(
    declinedTransactionID
  );
  if (!declinedTransaction) {
    return <>Loading...</>;
  }
  const source = declinedTransaction.source as unknown as
    | Record<string, string>
    | undefined;

  return (
    <PageLayout
      id="application.transactionDetails"
      headline={declinedTransaction.id}
    >
      <GroupLockedBadge groupID={declinedTransaction.group_id} />
      <Section header="Declined Transaction">
        <PropertyList
          items={[
            {
              label: 'Group ID',
              value: declinedTransaction.group_id,
              href: buildPath(ROUTES.GROUPS_DETAIL, {
                groupId: declinedTransaction.group_id,
              }),
            },
            {
              label: 'Account ID',
              value: declinedTransaction.account_id,
              href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
                accountId: declinedTransaction.account_id,
              }),
            },
            {
              label: 'Amount ID',
              valueNode: (
                <Body color="primary" className="line-through">
                  {formatAmount(
                    declinedTransaction.amount,
                    declinedTransaction.currency
                  )}
                </Body>
              ),
            },
            { label: 'Description', value: declinedTransaction.description },
            {
              label: 'Declined Transaction Type (Source Type)',
              value: declinedTransaction.source_type,
            },
            { label: 'Date', value: declinedTransaction.date },
            { label: 'Created at', value: declinedTransaction.created_at },
          ]}
        ></PropertyList>
      </Section>

      {source ? (
        <Section header="Source">
          <p className="p-1 text-sm">
            These fields define the details of this declined transaction. The
            meaning of each field is documented here:{' '}
            <StyledLink to="https://increase.com/documentation/api#declined-transaction-object">
              Increase Documentation
            </StyledLink>
            . Click on "Child Attributes" on the "Source" section and then the
            entry matching the `class_name` here.
          </p>
          <PropertyList
            items={flatObject(declinedTransaction.source as object)
              .filter(({ key }) => !['amount', 'currency'].includes(key))
              .map(({ key, value }) => ({
                label: key,
                value: value as string,
              }))}
          />
        </Section>
      ) : null}

      <br />

      <OperatorNotes modelId={declinedTransactionID} />
    </PageLayout>
  );
};
