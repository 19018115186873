import { compact, uniq } from 'lodash';
import { useState } from 'react';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { Box } from 'shared/components/Box';
import { ListItem, PropertyList } from 'shared/components/PropertyList';
import { SideBySide } from 'shared/components/SideBySide';
import { Table } from 'shared/components/Table';
import { Heading } from 'shared/components/Text';
import { TextArea } from 'shared/components/TextArea';
import {
  humanize,
  formatInteger,
  formatISO8601Timestamp,
} from 'shared/lib/formatting';
import { EntityClusterGetResponse } from 'src/build/operations';
import {
  useEntityClusterReviewPost,
  useEntityClusterGet,
} from 'src/hooks/reactQuery';
import { ROUTES, buildPath } from 'src/lib/routes';
import { PageLayout } from '../deprecated/PageLayout';
import { OperatorNotes } from '../operator-notes';
import { Button } from 'shared/components/Button';

const ReviewCluster = (props: { entityCluster: EntityClusterGetResponse }) => {
  const submitReview = useEntityClusterReviewPost();
  const [message, setMessage] = useState('');

  return (
    <Box>
      <TextArea
        placeholder="Review comment"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Button
        text="Mark reviewed"
        onClick={() =>
          submitReview.mutateAsync([
            props.entityCluster.id,
            message.length > 0 ? { message } : {},
          ])
        }
      />
    </Box>
  );
};

export const EntityClusterDetailPage = () => {
  const { entityClusterID } = useTypedParams(ROUTES.ENTITY_CLUSTER_DETAIL);
  const { data } = useEntityClusterGet(entityClusterID);

  if (!data) {
    return <></>;
  }

  return (
    <PageLayout
      id="application.service.entity"
      headline={`Entity Cluster ${entityClusterID}`}
    >
      <SideBySide
        mode="rightDrawer"
        right={
          <Box>
            <Heading>Details</Heading>
            <PropertyList
              items={compact<ListItem>([
                { label: 'Status', value: humanize(data.status) },
                data.reviewed_by_email && {
                  label: 'Reviewed by',
                  value: data.reviewed_by_email,
                },
                { label: 'Reason', value: humanize(data.category) },
                {
                  label: 'Matching data',
                  value: data.matching_data,
                  copyable: data.matching_data,
                },
                {
                  label: 'Entity count',
                  value: formatInteger(data.entities.length),
                },
                {
                  label: 'Unique groups',
                  value: formatInteger(
                    uniq(data.entities.map((e) => e.group_id)).length
                  ),
                },
              ])}
            />
          </Box>
        }
        left={
          <Box>
            {data.status === 'pending_reviewing' && (
              <ReviewCluster entityCluster={data} />
            )}
            <OperatorNotes
              modelId={entityClusterID}
              readOnly={data.status === 'pending_reviewing'}
            />

            <Heading>Matching Entities</Heading>
            <Table
              data={data.entities}
              columns={[
                {
                  header: 'Name',
                  contents: (r) => ({
                    text: r.name,
                    href: buildPath(ROUTES.ENTITY, {
                      entityId: r.id,
                    }),
                  }),
                },
                {
                  header: 'Entity status',
                  contents: (r) => ({
                    text: humanize(r.status),
                    badgeColor: r.status === 'active' ? 'green' : 'gray',
                  }),
                },
                {
                  header: 'Entity created',
                  contents: (r) => ({
                    text: formatISO8601Timestamp(r.created_at, 'full'),
                  }),
                },
                {
                  header: 'Group',
                  contents: (r) => ({
                    text: r.group_id,
                    href: buildPath(ROUTES.GROUPS_DETAIL, {
                      groupId: r.group_id,
                    }),
                  }),
                },
                {
                  header: 'Group status',
                  contents: (r) => ({
                    text: humanize(r.group_status),
                    badgeColor: r.group_status === 'active' ? 'green' : 'red',
                  }),
                },
              ]}
            />
          </Box>
        }
      />
    </PageLayout>
  );
};
