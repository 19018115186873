import { compact } from 'lodash';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatBank } from 'src/lib/formatEnums';
import { formatISO8601Timestamp, humanize } from 'shared/lib/formatting';
import { BankProgramGetResponse } from 'src/build/operations';
import { bankProgramClassificationIconTypes } from '../tables/BankProgramsTable';

export type BankProgramPropertyListProps = {
  bankProgram: BankProgramGetResponse;
} & Omit<PropertyListProps, 'items'>;

export const BankProgramPropertyList = (
  props: BankProgramPropertyListProps
) => {
  const { bankProgram, ...rest } = props;

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'ID',
          value: bankProgram.id,
          copyable: bankProgram.id,
        },
        {
          label: 'Bank',
          value: formatBank(bankProgram.bank),
        },
        {
          label: 'Created at',
          value: formatISO8601Timestamp(bankProgram.created_at, 'medium'),
        },
        bankProgram.closed_at && {
          label: 'Closed at',
          value: formatISO8601Timestamp(bankProgram.closed_at, 'medium'),
        },
        {
          label: 'Name',
          value: bankProgram.partner_name,
        },
        bankProgram.classification && {
          label: 'Classification',
          value: humanize(bankProgram.classification),
          badgeColor: 'gray',
          badgeIcon:
            bankProgramClassificationIconTypes[bankProgram.classification],
        },
        bankProgram.marketing_risk_rating && {
          label: 'Marketing Risk Rating',
          value: bankProgram.marketing_risk_rating
            ? humanize(bankProgram.marketing_risk_rating)
            : 'Not applicable',
        },
      ])}
    />
  );
};
