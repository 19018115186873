import {
  useBankFeeStatementListInfinite,
  useBankProgramCurrentFeePlanGet,
  useBankProgramGet,
  useOperatorGet,
  useProgramListInfinite,
} from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from 'shared/components/SideBySide';
import { Loading } from 'shared/components/Loading';
import { BankProgramPropertyList } from '../property-lists/BankProgramPropertyList';
import { TableStateWrapper } from 'shared/components/TableStateWrapper';
import { ProgramsTable } from '../tables/ProgramsTable';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';
import { BankFeePlanPropertyList } from '../property-lists/BankFeePlanPropertyList';
import { BankFeeStatementsTable } from '../tables/BankFeeStatementsTable';

export const BankProgramDetailPage = () => {
  const { bankProgramId } = useTypedParams(ROUTES.BANK_PROGRAMS_DETAIL);
  const { data: bankProgram } = useBankProgramGet(bankProgramId);

  const { data: currentFeePlan } =
    useBankProgramCurrentFeePlanGet(bankProgramId);

  const programList = useProgramListInfinite({
    bank_programs: [bankProgramId],
    limit: 5,
  });

  const feeStatementList = useBankFeeStatementListInfinite({
    bank_program_id: bankProgramId,
  });

  const { data: operator } = useOperatorGet({});

  if (!bankProgram || !operator) {
    return <Loading />;
  }

  const operatorIsInternalOrGrasshopper =
    operator.role === 'internal' || operator.bank === 'grasshopper_bank';

  return (
    <PageLayout headline={bankProgram.partner_name}>
      <SideBySide
        mode="rightDrawer"
        right={
          <BankProgramPropertyList
            bankProgram={bankProgram}
            layoutHorizontallyWhenPossible={false}
            title="Summary"
          />
        }
        left={
          <>
            <TableStateWrapper
              table={ProgramsTable}
              {...programList}
              style="detail"
              title="Programs"
              emptyTitle="No Programs"
              emptySubtitle="There are no programs to display"
              viewMoreHref={buildPath(
                ROUTES.PROGRAMS_LIST,
                {},
                { bank_program: [bankProgramId] }
              )}
              showGroup
            />
            {operatorIsInternalOrGrasshopper && currentFeePlan && (
              <>
                <BankFeePlanPropertyList
                  layoutHorizontallyWhenPossible
                  bankFeePlan={currentFeePlan}
                  title="Current Fee Plan"
                />
                <TableStateWrapper
                  table={BankFeeStatementsTable}
                  {...feeStatementList}
                  style="detail"
                  title="Fee Statements"
                  emptyTitle="No Fee Statements"
                  emptySubtitle="There are no fee statements to display"
                  viewMoreHref={''}
                />
              </>
            )}
          </>
        }
      />
    </PageLayout>
  );
};
