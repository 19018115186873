import { compact } from 'lodash';
import { BadgeColor } from 'shared/components/Badge';
import {
  ListItem,
  PropertyList,
  PropertyListProps,
} from 'shared/components/PropertyList';
import { formatISO8601Timestamp } from 'shared/lib/formatting';
import {
  LateReturnRequestListResponseDataItemSubmissionsItem,
  LateReturnRequestListResponseDataItemSubmissionsItemStatus,
} from 'src/build/operations';

export type LateReturnRequestSubmissionPropertyListProps = {
  lateReturnRequestSubmission: LateReturnRequestListResponseDataItemSubmissionsItem;
} & Omit<PropertyListProps, 'items'>;

export const lateReturnRequestSubmissionBadgeColors: Record<
  LateReturnRequestListResponseDataItemSubmissionsItemStatus,
  BadgeColor
> = {
  pending_review: 'yellow',
  pending_sending: 'blue',
  canceled: 'gray',
  rejected: 'red',
  sent: 'green',
};

export const LateReturnRequestSubmissionPropertyList = (
  props: LateReturnRequestSubmissionPropertyListProps
) => {
  const { lateReturnRequestSubmission, ...rest } = props;

  return (
    <PropertyList
      {...rest}
      items={compact<ListItem>([
        {
          label: 'ID',
          value: lateReturnRequestSubmission.id,
          copyable: lateReturnRequestSubmission.id,
        },
        {
          label: 'Status',
          value: lateReturnRequestSubmission.status,
          badgeColor:
            lateReturnRequestSubmissionBadgeColors[
              lateReturnRequestSubmission.status
            ],
        },
        {
          label: 'Created',
          value: formatISO8601Timestamp(
            lateReturnRequestSubmission.created_at,
            'full'
          ),
        },
        {
          label: 'Updated',
          value: formatISO8601Timestamp(
            lateReturnRequestSubmission.updated_at,
            'full'
          ),
        },
        {
          label: 'Authorized At',
          value: formatISO8601Timestamp(
            lateReturnRequestSubmission.authorized_at,
            'full'
          ),
        },
        {
          label: 'Authorizer Name',
          value: lateReturnRequestSubmission.authorizer_name ?? '-',
        },
        {
          label: 'Authorizer Title',
          value: lateReturnRequestSubmission.authorizer_title ?? '-',
        },
        {
          label: 'Authorizer Email',
          value: lateReturnRequestSubmission.authorizer_email ?? '-',
        },
        {
          label: 'Authorizer Company',
          value: lateReturnRequestSubmission.authorizer_company ?? '-',
        },
        {
          label: 'Authorizer IP Address',
          value: lateReturnRequestSubmission.authorizer_ip_address ?? '-',
        },
        {
          label: 'Authorization Terms',
          value: lateReturnRequestSubmission.authorization_terms,
        },
      ])}
    />
  );
};
