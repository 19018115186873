import {
  useEntityGet,
  useRampBusinessAccountOnboardingSurveyGet,
} from 'src/hooks/reactQuery';
import { PageLayout } from '../deprecated/PageLayout';
import { SideBySide } from 'shared/components/SideBySide';
import { Loading } from 'shared/components/Loading';
import { ListItem, PropertyList } from 'shared/components/PropertyList';
import { RampBusinessAccountOnboardingSurveyGetResponseMonthlyAchOriginationAmount } from 'src/build/operations';
import { formatAddress, formatISO8601Timestamp } from 'shared/lib/formatting';
import { useCategoricals } from 'src/hooks/use-categoricals';
import { compact } from 'lodash';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';

const booleanQuestion = (label: string, value: boolean): ListItem =>
  value
    ? { label, value: 'Yes', badgeColor: 'yellow' }
    : {
        label,
        value: 'No',
      };

const formatRange = (
  range: RampBusinessAccountOnboardingSurveyGetResponseMonthlyAchOriginationAmount
): string =>
  ({
    zero: '$0',
    zero_to_ten_thousand_dollars: '$0 - $10,000',
    ten_thousand_to_fifty_thousand_dollars: '$10,000 - $50,000',
    fifty_thousand_to_one_hundred_thousand_dollars: '$50,000 - $100,000',
    more_than_one_hundred_thousand_dollars: 'More than $100,000',
  })[range];

export const RampBusinessAccountOnboardingSurveyDetailPage = () => {
  const { id: rampBusinessAccountOnboardingSurveyId } = useTypedParams(
    ROUTES.RAMP_BUSINESS_ACCOUNT_ONBOARDING_SURVEYS_DETAILS
  );

  const { data: survey } = useRampBusinessAccountOnboardingSurveyGet(
    rampBusinessAccountOnboardingSurveyId
  );
  const categoricals = useCategoricals();

  const { data: entity } = useEntityGet(survey?.entity_id || '', {
    enabled: !!survey?.entity_id,
  });

  if (!survey) {
    return <Loading />;
  }

  const address =
    entity?.setup_submission_structure.corporation?.submitted_address;

  const industryCode =
    entity?.setup_submission_structure?.corporation?.industry_code;

  const industryCodeName = categoricals?.industry_code?.find(
    (c) => c.value === industryCode
  )?.name;

  return (
    <PageLayout headline={`Onboarding Survey - ${survey.entity_name}`}>
      <SideBySide
        mode="rightDrawer"
        right={
          <PropertyList
            title="Details"
            items={compact<ListItem>([
              {
                label: 'ID',
                value: survey.id,
              },
              survey.archived_at && {
                label: 'Status',
                value: 'Archived',
                badgeColor: 'red',
              },
              {
                label: 'Account',
                value: survey.account_id,
                href: buildPath(ROUTES.ACCOUNTS_DETAIL, {
                  accountId: survey.account_id,
                }),
              },
              {
                label: 'Entity',
                value: survey.entity_name || '',
                href: survey.entity_id
                  ? buildPath(ROUTES.ENTITY, {
                      entityId: survey.entity_id,
                    })
                  : '',
              },
              industryCodeName && {
                label: 'Business Industry',
                value: industryCodeName,
              },
              industryCode && {
                label: 'NAICS code',
                value: industryCode,
              },
              {
                label: 'Business location',
                value: address ? formatAddress(address) : '-',
              },
              {
                label: 'Created At',
                value: formatISO8601Timestamp(survey.created_at, 'medium'),
              },
              survey.archived_at && {
                label: 'Archived At',
                value: formatISO8601Timestamp(survey.archived_at, 'medium'),
              },
            ])}
            layoutHorizontallyWhenPossible={false}
          />
        }
        left={
          <>
            <PropertyList
              title="Expected activity"
              items={[
                {
                  label: 'Monthly domestic wire amount',
                  value: formatRange(survey.monthly_domestic_wire_amount),
                },
                {
                  label: 'Monthly international wire amount',
                  value: formatRange(survey.monthly_international_wire_amount),
                },
                {
                  label: 'Monthly ACH origination amount',
                  value: formatRange(survey.monthly_ach_origination_amount),
                },
                {
                  label: 'Monthly deposit amount',
                  value: formatRange(survey.monthly_deposit_amount),
                },
              ]}
              layoutHorizontallyWhenPossible={false}
            />
            <PropertyList
              title="High-risk business types & high risk transaction activity"
              items={[
                booleanQuestion(
                  'Does this business cash checks?',
                  survey.is_check_casher
                ),
                booleanQuestion(
                  'Does this business provide financial services?',
                  survey.is_financial_services_provider
                ),
                booleanQuestion(
                  'Does this business engage in making or accepting payments related to internet gambling?',
                  survey.is_internet_gambling_related
                ),
                booleanQuestion(
                  'Does the business conduct business in the marijuana industry?',
                  survey.is_marijuana_related
                ),
                booleanQuestion(
                  'Does this business issue stored value cards?',
                  survey.issues_stored_value_cards
                ),
                booleanQuestion(
                  "Does this business issue negotiable instruments (i.e. money orders, cashier's checks, travelers checks, etc.)?",
                  survey.issues_negotiable_instruments
                ),
                booleanQuestion(
                  'Does this business currently receive or expect to receive payments from credit/debit or store valued cards?',
                  survey.receives_credit_debit_or_stored_value_card_payments
                ),
              ]}
              layoutHorizontallyWhenPossible={false}
            />
            <PropertyList
              title="Risk rating inputs"
              items={[
                {
                  label: 'Does the business engage in remote deposit capture?',
                  value: 'No. The Ramp Business Account does not support RDC.',
                },
                {
                  label:
                    'Does the business engage in internation wire transfers?',
                  value:
                    'No. The Ramp Business Account does not support international wire transfers.',
                },
                {
                  label: 'Does the business engage in domestic wire transfers?',
                  value:
                    survey.monthly_domestic_wire_amount !== 'zero'
                      ? `Yes. (The estimated monthly domestic wire transfers range is ${formatRange(
                          survey.monthly_domestic_wire_amount
                        ).toLowerCase()}.)`
                      : 'No.',
                },
                {
                  label: 'Does the business originate ACH?',
                  value:
                    survey.monthly_ach_origination_amount !== 'zero'
                      ? `Yes. (The estimated monthly ACH origination range is ${formatRange(
                          survey.monthly_ach_origination_amount
                        ).toLowerCase()}.)`
                      : 'No.',
                },
                booleanQuestion(
                  'Does the business engage in loans secured by cash collateral and marketable securities?',
                  survey.engages_in_loans_secured_by_cash_collateral_and_marketable_securities
                ),
                booleanQuestion(
                  'Is the business characterized as a cash-intensive business?',
                  survey.is_cash_intensive_business
                ),
                booleanQuestion(
                  'Is the business characterized as a title insurance operations and real estate closing business?',
                  survey.is_title_insurance_operations_and_real_estate_closing
                ),
                booleanQuestion(
                  'Is the business characterized as a deposit broker?',
                  survey.is_deposit_broker
                ),
                booleanQuestion(
                  'Is the business characterized as a professional service provider such as a doctor, attorney, accountant, or real estate  broker?',
                  survey.is_professional_service_provider
                ),
                booleanQuestion(
                  'Is the business characterized as a pawn broker?',
                  survey.is_pawn_broker
                ),
                booleanQuestion(
                  'Is the business characterized as a purchaser or seller of motor vehicles?',
                  survey.is_purchaser_or_seller_of_vehicles
                ),
                booleanQuestion(
                  'Is the business characterized as auctioneering?',
                  survey.is_auctioneer
                ),
                booleanQuestion(
                  'Is the business characterized as chartering or operation of ships, buses, or aircraft?',
                  survey.is_chartering_or_operation_of_ships_buses_or_aircraft
                ),
                booleanQuestion(
                  'Is the business characterized as gaming of any kind?',
                  survey.is_gaming
                ),
                booleanQuestion(
                  'Is the business characterized as a trade union?',
                  survey.is_trade_union
                ),
                booleanQuestion(
                  'Is the business characterized as a non-profit organization or charity?',
                  survey.is_non_profit_organization_or_charity
                ),
                booleanQuestion(
                  'Is the business characterized as a non-bank financial institution including money service businesses, casinos, and card clubs?',
                  survey.is_non_bank_financial_institution
                ),
                booleanQuestion(
                  'Is the business characterized as a broker/dealer in securities?',
                  survey.is_broker_or_dealer_in_securities
                ),
                booleanQuestion(
                  'Is the business characterized as a dealer in precious metals, stones, and jewels?',
                  survey.is_dealer_in_precious_metals_stones_and_jewels
                ),
                booleanQuestion(
                  'Does the business leadership include senior foreign political figures, their immediate families, or close associates (PEPs)?',
                  survey.is_senior_foreign_political_figure_or_associate
                ),
                booleanQuestion(
                  'Is the business characterized as a casino?',
                  survey.is_casino
                ),
                booleanQuestion(
                  'Is the business characterized as a dealer or broker in virtual currency?',
                  survey.is_dealer_or_broker_in_virtual_currency
                ),
                booleanQuestion(
                  'Is the business characterized as a dealer in antiquities?',
                  survey.is_dealer_in_antiquities
                ),
              ]}
              layoutHorizontallyWhenPossible={false}
            />
          </>
        }
      />
    </PageLayout>
  );
};
