import React, { useMemo } from 'react';
import { useFlagsGet } from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { Section } from './deprecated/Section';
import { InternalOperatorOnly } from './internal-operator-only';
import { Table, TableColumns } from 'shared/components/Table';
import { FlagsGetResponseFlagsItem } from 'src/build/operations';

export const Flags = () => {
  const { data } = useFlagsGet({});
  const columns = useMemo<
    TableColumns<FlagsGetResponseFlagsItem & { id: string }>
  >(
    () => [
      { header: 'Name', contents: (t) => ({ text: t.name }) },
      {
        header: 'Production',
        contents: (t) => ({
          text: t.production ? 'Enabled' : 'Disabled',
          badgeColor: t.production ? 'green' : 'yellow',
        }),
      },
      {
        header: 'Sandbox',
        contents: (t) => ({
          text: t.sandbox ? 'Enabled' : 'Disabled',
          badgeColor: t.sandbox ? 'green' : 'yellow',
        }),
      },
      {
        header: 'Edit',
        contents: (t) => ({
          text: 'Edit',
          href: `/manual_tasks/Operations::ManualTask::SetFlag?name=${t.name}`,
        }),
      },
    ],
    []
  );

  if (!data) {
    return <>Loading...</>;
  }

  const rows = data.flags.map((flag) => ({ ...flag, id: flag.name }));

  return (
    <PageLayout headline={'Flags 🏴‍☠️'}>
      <InternalOperatorOnly>
        <Section header="Flags">
          <Table columns={columns} data={rows}></Table>
        </Section>
      </InternalOperatorOnly>
    </PageLayout>
  );
};
