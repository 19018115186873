import React from 'react';
import {
  useCheckDepositGet,
  useInboundCheckGet,
  useTransactionGet,
} from 'src/hooks/reactQuery';
import { PageLayout } from './deprecated/PageLayout';
import { useServices } from 'src/hooks/use-services';
import { SideBySide } from 'shared/components/SideBySide';
import { TransactionPropertyList } from './property-lists/TransactionPropertyList';
import { formatAmount, humanize } from 'shared/lib/formatting';
import { Note } from 'shared/components/Note';
import { Body } from 'shared/components/Text';
import { Icon } from 'shared/components/Icon';
import { Box } from 'shared/components/Box';
import { ListItem, PropertyList } from 'shared/components/PropertyList';
import { OperatorNotes } from './operator-notes';
import { Loading } from 'shared/components/Loading';
import { compact } from 'lodash';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { ROUTES, buildPath } from 'src/lib/routes';

const SKIP_SOURCE_KEYS = [
  'amount',
  'currency',
  'class_name',
  'type',
  'description',
  'transfer_id',
];

const TRANSFER_LINK_KEYS = [
  'account_transfer_id',
  'ach_transfer_id',
  'check_deposit_id',
  'check_transfer_id',
  'inbound_ach_transfer_id',
  'inbound_wire_transfer_id',
  'wire_transfer_id',
  'real_time_payments_transfer_id',
];

function extractSourceFields(object: object, prefix = ''): ListItem[] {
  return Object.entries(object)
    .filter(([key]) => !SKIP_SOURCE_KEYS.includes(key))
    .map(([key, value]) => {
      if (value && typeof value == 'object') {
        return extractSourceFields(value, prefix + key + '.');
      } else if (
        value &&
        typeof value == 'string' &&
        TRANSFER_LINK_KEYS.includes(key)
      ) {
        return [
          {
            label: humanize(`${prefix}${key}`),
            value,
            href: buildPath(ROUTES.TRANSFER_DETAIL, { transferID: value }),
          },
        ];
      } else {
        return [{ label: humanize(`${prefix}${key}`), value: value as string }];
      }
    })
    .reduce((a, b) => a.concat(b), []);
}

type CheckDepositProps = { checkDepositID: string };

const CheckDeposit = ({ checkDepositID }: CheckDepositProps) => {
  const { data } = useCheckDepositGet(checkDepositID);
  const { operations } = useServices();

  if (!data) {
    return <Loading />;
  }

  const { front_image_file_id: frontImage, back_image_file_id: backImage } =
    data.check_deposit_instruction;

  return data ? (
    <PropertyList
      title="Check Deposit details"
      items={compact<ListItem>([
        {
          label: 'Front Image',
          value: frontImage,
          href: operations + '/api_files/' + frontImage + '/view',
        },
        backImage && {
          label: 'Back Image',
          value: backImage,
          href: operations + '/api_files/' + backImage + '/view',
        },
      ])}
    />
  ) : (
    <div />
  );
};

type InboundCheckDetailsProps = { inboundCheckID: string };

const InboundCheckDetails = ({ inboundCheckID }: InboundCheckDetailsProps) => {
  const { data } = useInboundCheckGet(inboundCheckID);
  const { operations } = useServices();

  return data ? (
    <PropertyList
      title="Inbound Check details"
      items={data.images.map(({ api_file_id, view_side }) => ({
        label: `${humanize(view_side)} image`,
        value: api_file_id,
        href: operations + '/api_files/' + api_file_id + '/view',
      }))}
    />
  ) : (
    <div />
  );
};

export const TransactionDetail = () => {
  const { transactionID } = useTypedParams(ROUTES.TRANSACTION_DETAIL);
  const { data: transaction } = useTransactionGet(transactionID);
  if (!transaction) {
    return <>Loading...</>;
  }

  const source = transaction.source as unknown as
    | Record<string, string>
    | undefined;

  return (
    <PageLayout
      id="application.transactionDetails"
      headline={`${transaction.source_type} • ${formatAmount(
        transaction.amount,
        transaction.currency
      )}`}
    >
      <SideBySide
        right={
          <TransactionPropertyList
            title="Basics"
            transaction={transaction}
            layoutHorizontallyWhenPossible={false}
          />
        }
        left={
          <>
            {transaction.source_documentation && (
              <Note>
                <Box gap="2">
                  <Box flex center>
                    <Icon name="info" />
                    <Body weight="bold">{transaction.source_type}</Body>
                  </Box>

                  <Body>{transaction.source_documentation}</Body>
                </Box>
              </Note>
            )}

            {source && (
              <PropertyList
                title="Details"
                items={compact<ListItem>([
                  { label: 'Description', value: transaction.description },
                  transaction.transfer_id && {
                    label: 'Transfer ID',
                    value: transaction.transfer_id,
                    href: buildPath(ROUTES.TRANSFER_DETAIL, {
                      transferID: transaction.transfer_id,
                    }),
                  },
                ]).concat(extractSourceFields(source))}
              />
            )}

            {transaction.source_type === 'API::CheckDepositAcceptance' &&
              source && (
                <CheckDeposit checkDepositID={source.check_deposit_id} />
              )}
            {transaction.source_type === 'API::InboundCheck' && (
              <InboundCheckDetails inboundCheckID={transaction.source_id} />
            )}

            <OperatorNotes modelId={transactionID} />
          </>
        }
        mode="rightDrawer"
      />
    </PageLayout>
  );
};
